import {
	deleteRequest,
	getRequest,
	patchRequest,
	postRequest,
	postRequestFD,
	putRequestFD,
	UnexpectedResponse,
} from '.';
import {
	AcceptStudentForPaperCommand,
	AcceptStudentForPaperResponse,
	AgreeForStudentPaperCommand,
	AgreeForStudentPaperResponse,
	AssignStudentToPaperCommand,
	AssignStudentToPaperResponse,
	AssignSupervisorToPaperCommand,
	AssignSupervisorToPaperResponse,
	BaseResponse,
	ChangePaperStatusToWaitingForApprovalCommand,
	ChangePaperStatusToWaitingForApprovalResponse,
	CreatePaperByStudentResponse,
	CreatePaperBySupervisorResponse,
	DeletePaperByStudentResponse,
	DeletePaperBySupervisorResponse,
	GetPaperDetailsResponse,
	RejectStudentFromPaperResponse,
	RollbackPaperToDraftCommand,
	RollbackPaperToDraftResponse,
	UpdatePaperByStudentResponse,
	UpdatePaperBySupervisorResponse,
} from '../models';
import { putRequest } from './base';

export async function getPaperDetails(paperId: number): Promise<GetPaperDetailsResponse> {
	const response = await getRequest<GetPaperDetailsResponse>('/core/paper', { paperId });
	return response.data;
}

export async function assignStudentToPaper(paperId: number): Promise<AssignStudentToPaperResponse> {
	const response = await postRequest<AssignStudentToPaperResponse, AssignStudentToPaperCommand>(
		'/core/student/paper/assign/student',
		{ paperId }
	);
	return response.data;
}

export async function assignSupervisorToPaper(
	paperId: number
): Promise<AssignSupervisorToPaperResponse> {
	const response = await patchRequest<
		AssignSupervisorToPaperResponse,
		AssignSupervisorToPaperCommand
	>('/core/supervisor/paper/assign', { paperId });
	return response.data;
}

export async function createPaper<S extends boolean>(
	isStudent: S,
	values: FormData
): Promise<number> {
	const response = await postRequestFD<
		S extends true ? CreatePaperByStudentResponse : CreatePaperBySupervisorResponse
	>(`/core/${isStudent ? 'student' : 'supervisor'}/paper`, values);
	const paperId = response.data.createdPaper?.paperId;
	if (typeof paperId !== 'number') throw new UnexpectedResponse();
	return paperId;
}

export async function updatePaper<S extends boolean>(
	isStudent: S,
	values: FormData,
	paperId?: number | null
): Promise<number> {
	if (paperId) values.set('PaperId', paperId.toString());
	const response = await putRequestFD<
		S extends true ? UpdatePaperByStudentResponse : UpdatePaperBySupervisorResponse
	>(`/core/${isStudent ? 'student' : 'supervisor'}/paper`, values);
	const rcvPaperId = response.data.updatedPaper?.paperId;
	if (typeof rcvPaperId !== 'number') throw new UnexpectedResponse();
	return rcvPaperId;
}

export async function setPaperToWaiting(paperId: number): Promise<BaseResponse> {
	const response = await putRequest<
		ChangePaperStatusToWaitingForApprovalResponse,
		ChangePaperStatusToWaitingForApprovalCommand
	>('/core/paper/status/waiting', { paperId });
	return response.data;
}

export async function rollbackToCreated(paperId: number): Promise<BaseResponse> {
	const response = await putRequest<RollbackPaperToDraftResponse, RollbackPaperToDraftCommand>(
		'/core/paper/status/created',
		{ paperId }
	);
	return response.data;
}

export async function acceptStudentForPaper(
	paperId: number,
	studentId: number
): Promise<BaseResponse> {
	const response = await patchRequest<
		AcceptStudentForPaperResponse,
		AcceptStudentForPaperCommand
	>('/core/supervisor/paper/accept', { paperId, studentId });
	return response.data;
}

export async function agreeForStudentPaper(
	isAgreed: boolean,
	paperId: number
): Promise<BaseResponse> {
	const response = await patchRequest<AgreeForStudentPaperResponse, AgreeForStudentPaperCommand>(
		'/core/supervisor/paper/agree',
		{ isAgreed, paperId }
	);
	return response.data;
}

export async function deleteStudentPaper(paperId: number): Promise<BaseResponse> {
	const response = await deleteRequest<DeletePaperByStudentResponse>('/core/student/paper', {
		paperId,
	});
	return response.data;
}

export async function deleteSupervisorPaper(paperId: number): Promise<BaseResponse> {
	const response = await deleteRequest<DeletePaperBySupervisorResponse>(
		'/core/supervisor/paper',
		{
			paperId,
		}
	);
	return response.data;
}

export async function rejectStudentFromPaper(paperId: number, studentId: number) {
	const response = await deleteRequest<RejectStudentFromPaperResponse>(
		'/core/supervisor/paper/reject',
		{ paperId, studentId }
	);
	return response.data;
}
