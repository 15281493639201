import { ThemeProps } from '../../utils/userTheme';
import Examples from '../Examples/Examples';
import Header from '../Header/Header';
import MainContent from '../MainContent/MainContent';
import Navbar from '../Navbar/Navbar';

export default function LoggedContent(props: ThemeProps): JSX.Element {
	return (
		<div className="flex">
			<Navbar />
			<div className="c-12 bg-whitesmoke">
				<Header {...props} />
				<MainContent />
				{process.env.NODE_ENV === 'development' && <Examples />}
			</div>
		</div>
	);
}
