import { AxiosResponse } from 'axios';
import {
	deleteRequest,
	getRequestNoAuth,
	patchRequestNoAuth,
	postRequestNoAuth,
	putRequest,
	saveNewToken,
	UnexpectedResponse,
} from '.';
import {
	RegenerateTokenWithOneStudentCommand,
	RegenerateTokenWithOneStudentResponse,
	ResetPasswordCommand,
	ResetPasswordResponse,
	SendResetPasswordTokenCommand,
	SendResetPasswordTokenResponse,
	SignInWithCasProviderCommand,
	SignInWithCasProviderResponse,
	SignInWithPasswordCommand,
	SignInWithPasswordResponse,
	SignOutUserResponse,
	VerifyResetPasswordTokenResponse,
} from '../models';

export async function login(
	emailAddress: string,
	password: string
): Promise<SignInWithPasswordResponse> {
	const user = {
		emailAddress,
		password,
	};
	const response = await postRequestNoAuth<SignInWithPasswordResponse, SignInWithPasswordCommand>(
		'/Auth/signIn',
		user
	);
	if (
		typeof response.data.refreshAuthToken !== 'string' ||
		typeof response.data.jwtToken !== 'string' ||
		response.data.user === undefined
	)
		throw new UnexpectedResponse();
	localStorage.setItem('refreshToken', response.data.refreshAuthToken);
	saveNewToken(response.data.jwtToken);
	return response.data;
}

export async function loginCAS(ticket: string): Promise<SignInWithCasProviderResponse> {
	const response = await postRequestNoAuth<
		SignInWithCasProviderResponse,
		SignInWithCasProviderCommand
	>('/auth/external/cas/signIn', { ticket });
	if (
		typeof response.data.refreshAuthToken !== 'string' ||
		typeof response.data.jwtToken !== 'string' ||
		response.data.user === undefined
	)
		throw new UnexpectedResponse();
	localStorage.setItem('refreshToken', response.data.refreshAuthToken);
	saveNewToken(response.data.jwtToken);
	return response.data;
}

export async function logout(): Promise<SignOutUserResponse> {
	const response = await deleteRequest<SignOutUserResponse>('/Auth/signOut', {
		refreshAuthToken: localStorage.getItem('refreshToken'),
	});
	logoutCredentialCleanup();
	return response.data;
}

export function logoutCredentialCleanup() {
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('token');
	localStorage.removeItem('user');
	document.cookie =
		'AuthToken=; path=/api/v1/files; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax; Secure';
}

export function sendResetPasswordToken(
	emailAddress: string
): Promise<AxiosResponse<SendResetPasswordTokenResponse, SendResetPasswordTokenCommand>> {
	return postRequestNoAuth<SendResetPasswordTokenResponse, SendResetPasswordTokenCommand>(
		'/Auth/resetPassword/send',
		{ emailAddress }
	);
}

export async function verifyResetPassword(
	userId: string | number,
	tokenCode: string
): Promise<VerifyResetPasswordTokenResponse> {
	const response = await getRequestNoAuth<VerifyResetPasswordTokenResponse>(
		'/Auth/resetPassword/verify',
		{
			userId: typeof userId === 'number' ? userId : Number.parseInt(userId),
			tokenCode: tokenCode,
		}
	);
	return response.data;
}

export async function regenerateToken(
	studentId: number
): Promise<RegenerateTokenWithOneStudentResponse> {
	const response = await putRequest<
		RegenerateTokenWithOneStudentResponse,
		RegenerateTokenWithOneStudentCommand
	>('/Auth/regenerate/jwtToken', {
		jwtToken: localStorage.getItem('token'),
		studentId,
	});
	const { jwtToken } = response.data;
	if (typeof jwtToken !== 'string')
		throw new UnexpectedResponse('No token received on regenerate');
	saveNewToken(jwtToken);
	return response.data;
}

export async function resetPassword(
	newPassword: string,
	userId: string | number,
	tokenCode: string
): Promise<ResetPasswordResponse> {
	const response = await patchRequestNoAuth<ResetPasswordResponse, ResetPasswordCommand>(
		'/Auth/resetPassword',
		{
			newPassword,
			userId: typeof userId === 'number' ? userId : Number.parseInt(userId),
			tokenCode,
		}
	);
	if (typeof response.data.refreshAuthToken !== 'string')
		throw new UnexpectedResponse('Reset password did not generate a new token');
	localStorage.setItem('refreshToken', response.data.refreshAuthToken);
	return response.data;
}
