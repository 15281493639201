import axios, { AxiosHeaders, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { decodeToken } from '../../utils/token';
import { ErrorCode, ModelError, RefreshAuthenticationTokensResponse } from '../models';

export const DEPARTMENTS_TO_AUTHORIZE = 'Departments-To-Authorize';
const AUTHORIZATION = 'Authorization';
const CONTENT_TYPE = 'Content-Type';
let awaitingRefresh: Promise<void> | null = null;
const initialToken = localStorage.getItem('token');
export const BASE_URL =
	process.env.NODE_ENV === 'production' ? '/api/v1' : 'https://127.0.0.1/api/v1';

const axiosBaseConfig = Object.freeze({
	baseURL: BASE_URL,
	paramsSerializer: (params: unknown) => qs.stringify(params, { arrayFormat: 'indices' }),
});
const unauthenticatedClient = axios.create(axiosBaseConfig);
unauthenticatedClient.interceptors.response.use(undefined, async (error) => {
	console.error(error);
	if (error.response && axios.isAxiosError(error)) {
		// error returned by server
		if (error.response.data?.error) {
			throw new ServerError(error.response);
		}
		throw error;
	} else if (error.request) {
		// could not connect or sth similar
		throw new NetworkError(error);
	} else {
		// Something happened in setting up the request that triggered an Error
		// I don't know what could it be
		throw error;
	}
});
export const client = axios.create(axiosBaseConfig);
if (initialToken) client.defaults.headers.common['Authorization'] = 'Bearer ' + initialToken;
client.interceptors.response.use(undefined, async (error) => {
	console.error(error);
	if (error.response && axios.isAxiosError(error)) {
		// status code is outside 2xx
		if (error.response.status === 401) {
			if (error.response.headers && error.response.headers['token-expired'] === 'true') {
				// refresh token
				await refreshToken();
				error.response.config.headers = new AxiosHeaders({
					...error.response.config.headers,
					[AUTHORIZATION]: client.defaults.headers.common['Authorization'] ?? '',
				});
				return client(error.response.config);
			} else {
				// 401 without token-expired means that the user is not logged in
				throw new LoginRequired();
			}
		}
		// another error returned by server
		if (error.response.data?.error) {
			throw new ServerError(error.response);
		}
		throw error;
	} else if (error.request) {
		// could not connect or sth similar
		throw new NetworkError(error);
	} else {
		// Something happened in setting up the request that triggered an Error
		// I don't know what could it be
		throw error;
	}
});

export function saveNewToken(jwtToken: string): void {
	document.cookie = `AuthToken=${jwtToken}; path=/api/v1/files; max-age=3600; SameSite=Lax; Secure`;
	localStorage.setItem('token', jwtToken);
	client.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
}

export async function saveSpoofedToken(
	spoofedToken: string,
	userRefreshToken: string
): Promise<void> {
	if (awaitingRefresh instanceof Promise) await awaitingRefresh.catch(suppressExceptions);
	const originalToken = localStorage.getItem('token');
	const originalRefreshToken = localStorage.getItem('refreshToken');
	if (!(originalToken && originalRefreshToken)) throw new Error('User is not logged in');
	localStorage.setItem('adminToken', originalToken);
	localStorage.setItem('adminRefreshToken', originalRefreshToken);
	saveNewToken(spoofedToken);
	localStorage.setItem('refreshToken', userRefreshToken);
}

export async function restoreAdminToken(): Promise<void> {
	if (awaitingRefresh instanceof Promise) await awaitingRefresh.catch(suppressExceptions);
	const originalToken = localStorage.getItem('adminToken');
	const originalRefreshToken = localStorage.getItem('adminRefreshToken');
	if (!(originalToken && originalRefreshToken)) throw new Error('Original admin token not found');
	localStorage.setItem('refreshToken', originalRefreshToken);
	saveNewToken(originalToken);
	localStorage.removeItem('adminToken');
	localStorage.removeItem('adminRefreshToken');
}

/**
 * Refresh the token if it expires in 10 seconds
 */
export async function refreshTokenIfOld(): Promise<void> {
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
		return;
	}
	const token = localStorage.getItem('token');
	if (!token) throw new Error('User is not logged in');
	const tokenData = decodeToken(token);
	const now = new Date();
	if (tokenData.exp - now.getTime() / 1000 < 10) await refreshToken();
}

async function refreshToken(): Promise<void> {
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
		return;
	}
	const refresh = async () => {
		const jwtToken = localStorage.getItem('token');
		const refreshAuthToken = localStorage.getItem('refreshToken');
		if (!(jwtToken && refreshAuthToken)) throw new LoginRequired();

		try {
			const response = await axios.put<RefreshAuthenticationTokensResponse>(
				`${BASE_URL}/Auth/refreshAuthentication`,
				{ jwtToken, refreshAuthToken }
			);
			if (
				!(
					response.status === 200 &&
					response.data.isSucceeded &&
					response.data.jwtToken &&
					response.data.refreshAuthToken
				)
			)
				throw new Error('Unexpected response');

			localStorage.setItem('refreshToken', response.data.refreshAuthToken);
			saveNewToken(response.data.jwtToken);
		} catch (error) {
			if (!axios.isAxiosError(error)) throw error;
			if (error.response) {
				const serverError = new ServerError(error.response);
				if (
					serverError.errorCode === ErrorCode.INSUFFICIENT_PERMISSIONS_EXCEPTION ||
					serverError.errorCode === ErrorCode.ENTITY_NOT_FOUND
				) {
					// Session has expired or the user has been deleted
					throw new SessionExpired();
				} else {
					throw serverError;
				}
			} else if (error.request) {
				throw new NetworkError(error);
			} else {
				throw error;
			}
		}
	};
	awaitingRefresh = refresh();
	// Finally does not catch the exception, so it can be propagated up
	await awaitingRefresh.finally(() => {
		awaitingRefresh = null;
	});
}

export async function getRequest<T>(path: string): Promise<AxiosResponse<T, never>>;
export async function getRequest<T>(
	path: string,
	departments: number[] | boolean
): Promise<AxiosResponse<T, never>>;
export async function getRequest<T>(
	path: string,
	// Workaround https://github.com/microsoft/TypeScript/issues/15300 (`never` can't be used)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params: Record<string, any> | undefined | FormData
): Promise<AxiosResponse<T, never>>;
export async function getRequest<T>(
	path: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params: Record<string, any> | undefined | FormData,
	departments: number[] | boolean
): Promise<AxiosResponse<T, never>>;
export async function getRequest<T>(
	path: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params?: Record<string, any> | undefined | FormData | boolean,
	departments?: number[] | boolean
): Promise<AxiosResponse<T, never>> {
	if (Array.isArray(params) || typeof params === 'boolean') {
		departments = params;
		params = undefined;
	}
	const headers: Record<string, string> = {};
	if (departments !== true && departments)
		headers[DEPARTMENTS_TO_AUTHORIZE] = JSON.stringify({ departmentsIds: departments });
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
	}
	return client.get<T, AxiosResponse<T, never>, never>(path, { params, headers });
}

export async function postRequest<T, D>(
	path: string,
	payload: D,
	departments?: number[] | boolean
): Promise<AxiosResponse<T, D>> {
	const headers: Record<string, string> = {};
	if (departments !== true && departments)
		headers[DEPARTMENTS_TO_AUTHORIZE] = JSON.stringify({ departmentsIds: departments });
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
	}
	return client.post<T, AxiosResponse<T, D>, D>(path, payload, { headers });
}

export async function postRequestFD<T, D = FormData>(
	path: string,
	payload: D,
	departments?: number[] | boolean
): Promise<AxiosResponse<T, D>> {
	const config: AxiosRequestConfig<D> & Required<Pick<AxiosRequestConfig<D>, 'headers'>> = {
		headers: { [CONTENT_TYPE]: 'multipart/form-data' },
	};
	if (departments !== true && departments)
		config.headers[DEPARTMENTS_TO_AUTHORIZE] = JSON.stringify({ departmentsIds: departments });
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
	}
	return client.post<T, AxiosResponse<T, D>, D>(path, payload, config);
}

export async function putRequest<T, D>(
	path: string,
	payload: D,
	departments?: number[] | boolean
): Promise<AxiosResponse<T, D>> {
	const headers: Record<string, string> = {};
	if (departments !== true && departments)
		headers[DEPARTMENTS_TO_AUTHORIZE] = JSON.stringify({ departmentsIds: departments });
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
	}
	return client.put<T, AxiosResponse<T, D>, D>(path, payload, { headers });
}

export async function putRequestFD<T, D = FormData>(
	path: string,
	payload: D,
	departments?: number[] | boolean
): Promise<AxiosResponse<T, D>> {
	const config: AxiosRequestConfig<D> & Required<Pick<AxiosRequestConfig<D>, 'headers'>> = {
		headers: { [CONTENT_TYPE]: 'multipart/form-data' },
	};
	if (departments !== true && departments)
		config.headers[DEPARTMENTS_TO_AUTHORIZE] = JSON.stringify({ departmentsIds: departments });
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
	}
	return client.put<T, AxiosResponse<T, D>, D>(path, payload, config);
}

export async function patchRequest<T, D>(
	path: string,
	payload: D,
	departments?: number[] | boolean
): Promise<AxiosResponse<T, D>> {
	const headers: Record<string, string> = {};
	if (departments !== true && departments)
		headers[DEPARTMENTS_TO_AUTHORIZE] = JSON.stringify({ departmentsIds: departments });
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
	}
	return client.patch<T, AxiosResponse<T, D>, D>(path, payload);
}

export async function deleteRequest<T>(path: string): Promise<AxiosResponse<T, never>>;
export async function deleteRequest<T>(
	path: string,
	departments: number[] | boolean
): Promise<AxiosResponse<T, never>>;
export async function deleteRequest<T>(
	path: string,
	// Workaround https://github.com/microsoft/TypeScript/issues/15300 (`never` can't be used)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params: Record<string, any> | undefined | FormData
): Promise<AxiosResponse<T, never>>;
export async function deleteRequest<T>(
	path: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params: Record<string, any> | undefined | FormData,
	departments: number[] | boolean
): Promise<AxiosResponse<T, never>>;
export async function deleteRequest<T>(
	path: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params?: Record<string, any> | undefined | FormData | boolean,
	departments?: number[] | boolean
): Promise<AxiosResponse<T, never>> {
	if (Array.isArray(params) || typeof params === 'boolean') {
		departments = params;
		params = undefined;
	}
	const headers: Record<string, string> = {};
	if (departments !== true && departments)
		headers[DEPARTMENTS_TO_AUTHORIZE] = JSON.stringify({ departmentsIds: departments });
	if (awaitingRefresh instanceof Promise) {
		await awaitingRefresh.catch(suppressExceptions);
	}
	return client.delete<T, AxiosResponse<T, never>, never>(path, { params, headers });
}

export function getRequestNoAuth<T>(
	path: string,
	params?: unknown
): Promise<AxiosResponse<T, never>> {
	return unauthenticatedClient.get<T, AxiosResponse<T, never>, never>(path, { params });
}

export function postRequestNoAuth<T, D>(path: string, payload: D): Promise<AxiosResponse<T, D>> {
	return unauthenticatedClient.post<T, AxiosResponse<T, D>, D>(path, payload);
}

export function patchRequestNoAuth<T, D>(path: string, payload: D): Promise<AxiosResponse<T, D>> {
	return unauthenticatedClient.patch<T, AxiosResponse<T, D>, D>(path, payload);
}

export function deleteRequestNoAuth<T, D>(path: string): Promise<AxiosResponse<T, D>> {
	return unauthenticatedClient.delete<T, AxiosResponse<T, D>, D>(path);
}

export class ServerError extends Error implements ModelError {
	statusCode: number;
	errorCode?: ErrorCode | null;
	validations?: { [key: string]: Array<string> } | null;
	constructor(response: AxiosResponse) {
		const error = response.data.error;
		super(error?.message ?? '');
		this.statusCode = response.status;
		this.errorCode = error.errorCode;
		this.validations = error.validations;
	}
}

export class LoginRequired extends Error {
	constructor() {
		super('Log in to proceed');
	}
}

export class SessionExpired extends Error {
	constructor() {
		super('The session has expired');
	}
}

export class QuietError extends Error {
	constructor(message: string) {
		super(message);
	}
}

export class NetworkError extends Error {
	constructor(cause: Error) {
		super(`A network error ${cause.name} occurred: ${cause.message}`);
	}
}

/**
 * Converts an error into {@link QuietError} so that a single error on token/session refresh
 * is not shown multiple times and will be ignored by all, but the first failed request.
 */
function suppressExceptions(error: unknown) {
	let message = 'Unknown error';
	if (error instanceof Error) message = error.message;
	if (typeof error === 'string') message = error;
	throw new QuietError(message);
}
