import { getRequest, putRequest } from '..';
import {
	AddOrUpdateLastAnnouncementCommand,
	AddOrUpdateLastAnnouncementResponse,
	GetLastAnnouncementResponse,
} from '../../models';

export async function GetLastAnnouncement(): Promise<GetLastAnnouncementResponse> {
	const response = await getRequest<GetLastAnnouncementResponse>(`/core/announcement/last/read`);
	return response.data;
}

export async function AddOrUpdateLastAnnouncement(
	polishText: string,
	englishText: string,
	departments: number[] | boolean
): Promise<AddOrUpdateLastAnnouncementResponse> {
	const response = await putRequest<
		AddOrUpdateLastAnnouncementResponse,
		AddOrUpdateLastAnnouncementCommand
	>('/management/announcement/last', { polishText, englishText }, departments);
	return response.data;
}
