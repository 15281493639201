/**
 * Decodes a token, but does not validate it.
 * Decoding may fail and the signature is not checked.
 *
 * Copied from https://stackoverflow.com/a/38552302
 * @param token JTW token
 * @returns decoded token, unknown shape
 */
export function decodeToken(token: string) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);
	return JSON.parse(jsonPayload);
}
