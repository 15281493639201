import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import { DepartmentDto } from '../../services/models';
import { getDepartmentsFromUserPermissions, useErrorHandler } from '../../services/network';

export default function DepartmentSelect() {
	const [selectedDepartmentId, setSelectedDepartmentId] = useState('');
	const [userDepartments, setUserDepartments] = useState<Required<DepartmentDto>[] | null>(null);
	const networkErrorHandler = useErrorHandler();
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		getDepartmentsFromUserPermissions()
			.then((response) => {
				const departments = response.departments?.filter(
					(dept): dept is Required<DepartmentDto> =>
						typeof dept.departmentId === 'number' && typeof dept.name === 'string'
				);
				if (!Array.isArray(departments) || departments.length === 0) {
					toast.warn(t('no_permissions_to_review'));
					return navigate('/');
				}
				if (departments.length === 1) {
					return navigate(departments[0].departmentId.toString());
				}
				setUserDepartments(departments);
			})
			.catch(networkErrorHandler);
	}, [networkErrorHandler, setUserDepartments, navigate, t]);

	return (
		<div className="content-wrapper pt-10">
			<div
				className="small-content bg-white pt-5 flex flex-column flex-middle main-shadow rounded-box"
				style={{ maxHeight: '30rem' }}
			>
				<label className="mb-2 text-center h1" htmlFor="department-select">
					{t('select_department')}
				</label>
				<select
					id="department-select"
					value={selectedDepartmentId}
					onChange={(e) => setSelectedDepartmentId(e.target.value)}
					className="c-7 mb-5 select-1"
				>
					{selectedDepartmentId === '' && (
						<option>
							{t(userDepartments === null ? 'loading' : 'select_from_list')}
						</option>
					)}
					{userDepartments &&
						userDepartments.map((department) => (
							<option key={department.departmentId} value={department.departmentId}>
								{department.name}
							</option>
						))}
				</select>

				{selectedDepartmentId && (
					<button onClick={() => navigate(selectedDepartmentId)} className="c-6 button-1">
						ok
					</button>
				)}
			</div>
		</div>
	);
}
