import { FieldInputProps, FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import { ReactComponent as FileIcon } from '../../Icons/file-earmark-plus.svg';
import './FileInput.scss';
import { errorToMessage, renameDuplicates, sizeToString } from './FileInputUtils';

/**
 * A file input component with drag & drop, manual selection and file removal. Configurable max number of files and max file size.
 *
 * Use `setFilesInputValue` to update fromik field with the current list of files.
 */
export default function FileInput(props: FileInputProps): JSX.Element {
	const { maxSize, maxFiles, formikData } = props;
	const formikOnChange = formikData?.onChange;
	const formikOnError = formikData?.onError;
	const { t } = useTranslation();

	// State showing whether something is dragged over the FileInput
	const [draggedOver, setDraggedOver] = useState(false);
	const draggedOverClass = draggedOver ? ' dragged-over' : '';

	// Files state and adding files (callback used in useDropzone)
	const [filesState, setFiles] = useState([] as File[]);
	const files = formikData?.value ?? filesState; // Set files to use state if formikData?.value is null or undefined

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			acceptedFiles = renameDuplicates(files, acceptedFiles);
			const newFiles = [...files, ...acceptedFiles];
			// If configured as Formik field, call the handler, otherwise modify state
			if (formikOnChange) {
				formikOnChange(newFiles, { newFiles: acceptedFiles });
			} else {
				setFiles(newFiles);
			}
		},
		[files, formikOnChange]
	);

	// Initialize the dropzone hook
	const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
		maxFiles,
		maxSize,
		multiple: true,
		noClick: true,
		noKeyboard: true,
		onDrop,
	});

	// Detect rejected files and show error
	useEffect(() => {
		if (fileRejections.length) {
			const rejections = fileRejections.map((rejection) => {
				const { errors, file } = rejection;
				const sizeStr = sizeToString(file.size, t);
				const errorsStr = errors
					.map(({ code }) => {
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						return errorToMessage(code, maxSize!, maxFiles!, t);
					})
					.join(', ');
				return `- ${file.name} (${sizeStr}) - ${errorsStr}`;
			});
			const message = `${t('rejected_files')}:\n${rejections.join('\n')}`;
			const onError = formikOnError ?? toast.error;
			onError(message);
		}
	}, [fileRejections, maxFiles, maxSize, formikOnError, t]); // limits should be constant, so it fires only on `fileRejections` change

	// Remove file function - click handler for "DELETE" button
	const removeFile = (file: File) => {
		const newFiles = [...files];
		newFiles.splice(newFiles.indexOf(file), 1);
		if (formikOnChange) {
			formikOnChange(newFiles, { removedFile: file });
		} else {
			setFiles(newFiles);
		}
	};

	// Prepare list of added files
	const fileList = files.map((file) => (
		<li key={file.name}>
			{file.name} - {sizeToString(file.size, t)}
			{!formikData?.hideRemove && (
				<button type="button" className="button-4 ml-1" onClick={() => removeFile(file)}>
					{t('remove')}
				</button>
			)}
		</li>
	));

	const filesSection =
		fileList.length && !formikData?.hideFiles ? (
			<aside>
				<ul className="file-list">{fileList}</ul>
			</aside>
		) : null;

	return (
		<section
			className={'file-input-main' + draggedOverClass}
			onDragOver={() => setDraggedOver(true)}
			onDragLeave={() => setDraggedOver(false)}
			onDrop={() => setDraggedOver(false)}
		>
			<div
				{...getRootProps({
					className: 'zone-container col-whitesmoke p-1',
				})}
			>
				<div className="mobile-hidden">
					<input id="file-input-input" {...getInputProps()} />
					<FileIcon className="file-icon" />
					<label className="file-input-label" htmlFor="file-input-input">
						<Trans i18nKey="drag_to_add">
							Drag a file
							<br />
							to add
						</Trans>
					</label>
				</div>
				<button type="button" className="mt-1 mt-0-s button-2" onClick={open}>
					{t('select_file')}
				</button>
			</div>
			{filesSection}
		</section>
	);
}

export interface FormikFileInputProps {
	form: FormikProps<unknown>;
	maxSize?: number;
	maxFiles?: number;
	hideFiles?: boolean;
	hideRemove?: boolean;
	field: FieldInputProps<File[]>;
}

interface FileInputProps {
	maxSize?: number;
	maxFiles?: number;
	formikData?: FormikData;
}

interface FormikData {
	/** the field value passed by the parent, new files are added to it */
	value: File[];
	/** what should happen if the value is modified */
	onChange: (files: File[], change: FileRemovedChange | FilesAddedChange) => void;
	/** what should happen if some files are rejected */
	onError?: (message: string) => void;
	/** whether the list of files should be hidden */
	hideFiles?: boolean;
	/** whether the REMOVE button should be shown next to files */
	hideRemove?: boolean;
}

interface FilesAddedChange {
	newFiles: File[];
}

interface FileRemovedChange {
	removedFile: File;
}
