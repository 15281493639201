export * from './academicDocument';
export * from './auth';
export * from './base';
export * from './browser';
export * from './department';
export * from './dictionaryData';
export * from './errorHandler';
export * from './paperManager';
export * from './papers';
export * from './profile';
export * from './publicFiles';
export * from './supervisor';
export * from './tags';
export * from './userPreferences';

export class UnexpectedResponse extends Error {
	constructor(message: string | undefined = 'Received an unexpected response from server') {
		super(message);
	}
}
