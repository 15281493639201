/**
 * Checks if value is not `null` or `undefined`.
 * Useful for Array.filter on arrays of Array<T | undefinde | null>
 *
 * @see https://stackoverflow.com/a/46700791
 */
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined;
}

/**
 * Useful tool for checking exhaustiveness of eg. switch blocks
 *
 * While this certainly can help, unfortunately, the compiler won't tell us what exatly
 * is missing unlike [Rust](https://doc.rust-lang.org/book/ch06-02-match.html#matches-are-exhaustive).
 *
 * @example
 * enum E {
 * 	A,
 * 	B,
 * 	C
 * }
 *
 * function a(e: E): string {
 * 	let message = 'abc';
 * 	switch (e) {
 * 		case E.A:
 * 			message += ' A';
 * 			break;
 *
 * 		// forgot about E.B
 *
 * 		case E.C:
 * 			message += ' C';
 * 			break;
 *
 * 		default:
 * 			assertUnreachable(e);
 * 			// Results in the following error:
 * 			// Argument of type E is not assignable to parameter of type 'never'.
 * 			break;
 * 	}
 * 	return message;
 * }
 *
 * @see https://stackoverflow.com/a/39419171
 */
export function assertUnreachable(_: never): never {
	throw new Error("That's impossible" + _);
}

/**
 * Mark all properties in `T` required and `NonNullable` (neither `null` nor `undefined`)
 */
export type RequiredNonNull<T> = { [P in keyof T]-?: NonNullable<T[P]> };
