import { useTranslation } from 'react-i18next';
import { PaperDto } from '../../../services/models';
import { BASE_URL, refreshTokenIfOld } from '../../../services/network';
import { getFileName, renderSupervisor } from '../../../utils/nameFormatting';
import PaperStatusDisplay from '../../PaperStatusDisplay/PaperStatusDisplay';
import './PaperDetails.scss';

export default function PaperDetails(props: PaperDetailsProps): JSX.Element {
	const { paper, hideStatus, reservedMessage = ReservedMessageKind.GENERAL } = props;
	const { t } = useTranslation();

	return (
		<div>
			<div className="content-header bg-white flex flex-middle flex-center-s pl-8 pl-0-s">
				<h1 className="h2-s">{t('paper_details')}:</h1>
			</div>
			<div className="mt-3 ml-5 ml-3-m ml-1-s mb-3  flex flex-wrap">
				<div className="c-6 c-12-m">
					<div className="c-12">
						<h3 className="h4-s">
							<b>{t('title')}:</b> {paper.paperDetails?.polishTitle}
						</h3>
						<h3 className="h4-s">
							<b>{t('title_en')}:</b> {paper.paperDetails?.englishTitle}
						</h3>
					</div>
					<div className="c-12">
						<h4 className="mt-2">
							<b>{t('supervisor')}:&nbsp;</b>
							{paper.supervisor ? renderSupervisor(paper.supervisor) : null}
						</h4>
						<h4>
							<b>{t('supervisor_email')}:&nbsp;</b> {paper.supervisor?.emailAddress}
						</h4>
						<h4>
							<b>{t('department')}:&nbsp;</b> {paper.supervisor?.department?.name}
						</h4>
						{paper.isReserved && reservedMessage !== ReservedMessageKind.HIDDEN && (
							<p className="h4 mt-1 col-danger">
								{t(
									reservedMessage === ReservedMessageKind.WARNING
										? 'reserved_topic_warning'
										: 'reserved_topic'
								)}
							</p>
						)}
						<h4 className="mt-2">
							<b>{t('tags')}:</b>
						</h4>
						<p>{paper.tags?.map((el) => el.content).join(', ')}</p>
						<h4 className="mt-2">
							<b>{t('programme')}:</b>
						</h4>
						<p>{paper.majorPapers?.map((el) => el.majorName).join(', ')}</p>
						<h4 className="mt-2">
							<b>{t('description')}:</b>
						</h4>
						<h4 className="paper-description">{paper.paperDetails?.description}</h4>
						<p className="mt-1">
							<b>{t('students_number')}:&nbsp;</b>
							{paper.paperDetails?.maxStudents}
						</p>
						{!hideStatus && (
							<p className="mt-1">
								<b>{t('paper_status')}:&nbsp;</b>
								<PaperStatusDisplay paper={paper} />
							</p>
						)}
					</div>
				</div>
				<div className="c-6 flex flex-end flex-start-m">
					<div className="mr-8">
						<p>{t('creation_date')}: </p>
						<p>{new Date(paper.paperDetails?.createdOn ?? 0).toLocaleString()}</p>
						<h4 className="mt-2">
							<b>{t('attached_files')}:</b>
						</h4>
						{paper.paperFiles?.map(
							(f) =>
								f?.filePath &&
								typeof f?.paperFileId === 'number' &&
								typeof f?.paperId === 'number' && (
									<a
										key={f.paperFileId}
										href={`${BASE_URL}/files/${f.paperId}/${
											f.paperFileId
										}/${getFileName(f.filePath)}`}
										onClick={async (e) => {
											const { href } = e.currentTarget;
											e.preventDefault();
											await refreshTokenIfOld();
											const link = document.createElement('a');
											link.href = href;
											document.body.appendChild(link);
											link.click();
											document.body.removeChild(link);
										}}
									>
										{getFileName(f.filePath)}
										<br />
									</a>
								)
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export enum ReservedMessageKind {
	GENERAL,
	WARNING,
	HIDDEN,
}

export interface PaperDetailsProps {
	paper: PaperDto;
	hideStatus?: boolean;
	reservedMessage?: ReservedMessageKind;
}
