import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';

export default function Login(): JSX.Element {
	const [showLogin, setShowLogin] = useState(false);
	const { t } = useTranslation();

	return (
		<div className="flex flex-center flex-middle bg-whitesmoke full-height">
			<div className="c-2 c-3-l c-6-m c-12-s bg-white p-5 p-3-s m-1-s main-shadow  rounded-box">
				{showLogin ? (
					<LoginForm>
						<a
							href=""
							className="mt-1 col-black"
							onClick={(e) => {
								e.preventDefault();
								setShowLogin(false);
							}}
						>
							{t('return')}
						</a>
					</LoginForm>
				) : (
					<>
						<h2>{t('login_CAS_header')}</h2>
						<p className="mt-1">{t('sign_in_CAS_info')}</p>
						<div className="c-12 flex flex-column flex-middle mt-3">
							<div>
								<a
									href="https://login.polsl.pl/login?service=https%3A%2F%2Fcyt.aei.polsl.pl%2Fcas"
									className="button-1 mt-1 text-none"
								>
									{t('sign_in')}
								</a>
							</div>
							<div className="mt-3 text-center">
								<a
									href=""
									className="col-black"
									onClick={(e) => {
										e.preventDefault();
										setShowLogin(true);
									}}
								>
									{t('login_external_employee')}
								</a>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
