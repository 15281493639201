import { Trans, useTranslation } from 'react-i18next';
import { useUser } from '../../services/hooks';
import { PaperDto, PaperStatus } from '../../services/models';

// https://github.com/i18next/react-i18next/issues/1483#issuecomment-1268455602
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TI = any;

export interface PaperStatusDisplayProps {
	paper: PaperDto;
}

export default function PaperStatusDisplay(props: PaperStatusDisplayProps) {
	const user = useUser();
	const { t } = useTranslation();
	const { paper } = props;
	const isStudent = typeof user?.studentId === 'number';

	const statusMessage = () => {
		switch (paper.status) {
			case PaperStatus.CREATED: {
				return t('draft');
			}

			case PaperStatus.WAITING_FOR_APPROVAL: {
				if (typeof paper.studentId !== 'number') return t('waiting_for_reviewer');

				if (isStudent) return t('waiting_for_supervisor');

				return t('waiting_for_approval');
			}

			case PaperStatus.APPROVED: {
				if (!isStudent) {
					if (paper.paperDetails === undefined)
						return "Paper details are undefined - can't access the maximum number of students";
					const maxStudents = paper.paperDetails?.maxStudents ?? 1;
					const totalStudentPapers = paper.studentPapers?.length ?? 0;
					const studentsWaiting =
						paper.studentPapers?.filter((sp) => !sp.isStudentAccepted).length ?? 0;
					const acceptedStudents = totalStudentPapers - studentsWaiting;
					const waitingFor = maxStudents - acceptedStudents;
					const StudentsInterested = () => (
						<Trans i18nKey="students_interested" count={studentsWaiting}>
							, <strong>{{ count: studentsWaiting } as TI} students waiting</strong>
						</Trans>
					);
					if (maxStudents === 1)
						return (
							<Trans i18nKey="approved_students_waiting_single">
								Accepted, waiting for a student
								<StudentsInterested />
							</Trans>
						);
					return (
						<Trans i18nKey="approved_students_waiting_multiple" count={waitingFor}>
							Accepted, waiting for {{ count: waitingFor }} more students
							<StudentsInterested />
						</Trans>
					);
				}

				const studentPaper = paper.studentPapers?.find(
					(sp) => sp.studentId === user.studentId
				);
				if (!studentPaper) return null;

				if (studentPaper.isStudentAccepted) return t('waiting_for_more_students');

				return t('waiting_for_supervisor');
			}

			case PaperStatus.ASSIGNED: {
				return t('approved_w_student');
			}

			default:
				throw new Error(`Unexpected status: ${paper.status}`);
		}
	};
	return <>{statusMessage()}</>;
}
