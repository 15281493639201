export type ThemeName = 'dark' | 'contrast' | 'default';

export function getUserTheme(): ThemeName {
	const savedTheme = localStorage.getItem('theme');
	if (savedTheme === 'default' || savedTheme === 'dark' || savedTheme === 'contrast') {
		return savedTheme;
	}
	let theme: ThemeName = 'default';
	if (window.matchMedia('(prefers-color-scheme: dark)').matches) theme = 'dark';
	if (window.matchMedia('(prefers-contrast: more)').matches) theme = 'contrast';
	localStorage.setItem('theme', theme);
	return theme;
}

export function setupThemeChangeHandler(onThemeChange: (newTheme: ThemeName) => void): () => void {
	const darkMedia = window.matchMedia('(prefers-color-scheme: dark)');
	const contrastMedia = window.matchMedia('(prefers-contrast: more)');
	const notOutdatedSafari = typeof darkMedia.addEventListener === 'function';
	const darkMediaListener = (e: MediaQueryListEvent) => {
		if (window.matchMedia('(prefers-contrast: more)').matches) return; // don't override high contrast
		onThemeChange(e.matches ? 'dark' : 'default');
	};
	const contrastMediaListener = (e: MediaQueryListEvent) => {
		if (e.matches) return onThemeChange('contrast');
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) return onThemeChange('dark');
		return onThemeChange('default');
	};
	if (notOutdatedSafari) {
		darkMedia.addEventListener('change', darkMediaListener);
		contrastMedia.addEventListener('change', contrastMediaListener);
		return () => {
			darkMedia.removeEventListener('change', darkMediaListener);
			contrastMedia.removeEventListener('change', contrastMediaListener);
		};
	} else {
		// Safari < 14
		darkMedia.addListener(darkMediaListener);
		contrastMedia.addListener(contrastMediaListener);
		return () => {
			darkMedia.removeListener(darkMediaListener);
			contrastMedia.removeListener(contrastMediaListener);
		};
	}
}

export interface ThemeProps {
	theme: ThemeName;
	setTheme: (theme: ThemeName) => void;
}
