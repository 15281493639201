import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import User from '../utils/localStorageClasses/User';

export function updateUser(
	setUser: React.Dispatch<React.SetStateAction<User | undefined>>,
	user: User
) {
	localStorage.setItem('user', JSON.stringify(user));
	setUser(user);
}

export function saveUserAndLogin(
	setUser: React.Dispatch<React.SetStateAction<User | undefined>>,
	navigate: NavigateFunction,
	user: User
) {
	updateUser(setUser, user);
	navigate('/', { replace: true }); // don't leave login pages in history
}

export function backupAdmin() {
	const user = localStorage.getItem('user');
	localStorage.setItem('adminUser', user ?? '');
}

/**
 * Replaces the localStorage user with the backed up admin user and removes the backup.
 *
 * **Warning:** The user stored in React state is not replaced. Use the return value to replace it.
 *
 * @returns a {@link User} object of the administrator or `null` on failure
 */
export function replaceWithRestoredAdmin(): User | undefined {
	const adminBackup = localStorage.getItem('adminUser') ?? '';
	localStorage.removeItem('adminUser');
	localStorage.setItem('user', adminBackup);
	const user = User.fromLocalStorage();
	return user;
}
