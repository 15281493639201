import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toast';
import '../../../Styles/Pagination.scss';
import config from '../../../config';
import { usePrevious } from '../../../services/hooks';
import {
	BrowsePapersSortType,
	MajorDegreeType,
	PaperListDto,
	ReviewPaperListDto,
	UnassignedPaperListDto,
} from '../../../services/models';
import {
	getAllTags,
	getMajors,
	getPapersToReview,
	getStudentPapers,
	getSupervisorPapers,
	getSupervisors,
	reviewPaper,
	reviewPapersList,
	useErrorHandler,
} from '../../../services/network';
import { renderSupervisor } from '../../../utils/nameFormatting';
import { notEmpty } from '../../../utils/typeGuards';
import Loading from '../../Loading/Loading';
import './UnifiedBrowser.scss';

export enum BrowserType {
	STUDENT = 'student',
	SUPERVISOR = 'supervisor',
	REVIEW = 'review',
}

const typeToGetPapers = {
	student: getStudentPapers,
	supervisor: getSupervisorPapers,
	review: getPapersToReview,
} as const;

export interface UnifiedBrowserProps {
	browserType: BrowserType;
	showHeader?: boolean;
}

const smallScreenRule = '(max-width: 540px)';

export default function UnifiedBrowser(props: UnifiedBrowserProps): JSX.Element {
	const { browserType, showHeader = true } = props;
	const showSupervisor = browserType != BrowserType.SUPERVISOR;
	const getPapers = typeToGetPapers[browserType];
	const detailsParams = browserType === BrowserType.REVIEW ? '?review=true' : '';
	const { departmentId } = useParams();
	const previousDepartmentId = usePrevious(departmentId);
	const [searchParams, setSearchParams] = useSearchParams();
	const previousSearchParams = usePrevious(searchParams);

	const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null);
	const [papers, setPapers] = useState<
		PaperListDto[] | UnassignedPaperListDto[] | ReviewPaperListDto[] | null
	>();
	const [cacheState, setCacheState] = useState(0);
	const previousCacheState = usePrevious(cacheState);
	const [pageCount, setPageCount] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);

	const [supervisorFilter, setSupervisorFilter] = useState<
		{ value: number; label: string }[] | null
	>(null);
	const [tagsFilter, setTagsFilter] = useState<{ value: string; label: string }[] | null>(null);
	const [majorDegreeType, setMajorDegreeType] = useState<MajorDegreeType | undefined>(undefined);
	const [supervisorIdChosen, setSupervisorIdChosen] = useState<number | undefined>(undefined);
	const [titleChosen, setTitleChosen] = useState<string>('');
	const [tagsIdChosen, setTagsIdChosen] = useState<string[]>([]);
	const [majorList, setMajorList] = useState<{ value: string; label: string }[]>([]);
	const [majorId, setMajorIds] = useState<number | undefined>();
	const [studentSurname, setStudentSurname] = useState<string>('');
	const [sortType, setSortType] = useState<BrowsePapersSortType>(BrowsePapersSortType.DATE_ASC);
	const [showReserved, setShowReserved] = useState(false);
	const [smallScreen, setSmallScreen] = useState(window.matchMedia(smallScreenRule).matches);

	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	const pageSize = config.defaultPageSize;
	const sortTypeOptions = [
		{ value: BrowsePapersSortType.DATE_ASC, label: t('sort_date_asc') },
		{ value: BrowsePapersSortType.DATE_DESC, label: t('sort_date_desc') },
		{ value: BrowsePapersSortType.TITLE_ASC, label: t('sort_title_asc') },
		{ value: BrowsePapersSortType.TITLE_DESC, label: t('sort_title_desc') },
		{ value: BrowsePapersSortType.SUPERVISOR_NAME_ASC, label: t('sort_supervisor_name_asc') },
		{ value: BrowsePapersSortType.SUPERVISOR_NAME_DESC, label: t('sort_supervisor_name_desc') },
	].filter(
		({ value }) =>
			!showSupervisor || (showSupervisor && value < BrowsePapersSortType.SUPERVISOR_NAME_ASC)
	);

	useEffect(() => {
		const sortStr = searchParams.get('sort');
		const sort = sortStr ? Number.parseInt(sortStr) : BrowsePapersSortType.DATE_ASC;
		const showReserved = searchParams.has('showReserved');
		const pageNumberStr = searchParams.get('pageNumber');
		const pageNumber = pageNumberStr ? Number.parseInt(pageNumberStr) : 1;
		const supervisorIdStr = searchParams.get('supervisorId');
		const supervisorId = supervisorIdStr ? Number.parseInt(supervisorIdStr) : undefined;
		const content = searchParams.get('content') ?? '';
		const tags = searchParams.get('tags')?.split(',') ?? [];
		const degreeTypeStr = searchParams.get('degreeType');
		const degreeType = degreeTypeStr ? Number.parseInt(degreeTypeStr) : undefined;
		const majorIdStr = searchParams.get('majorId');
		const majorId = majorIdStr ? Number.parseInt(majorIdStr) : undefined;
		const student = searchParams.get('student') ?? '';
		setSortType(sort);
		setShowReserved(showReserved);
		setPageNumber(pageNumber);
		setSupervisorIdChosen(supervisorId);
		setTitleChosen(content);
		setTagsIdChosen(tags);
		setMajorDegreeType(degreeType);
		setMajorIds(majorId);
		setStudentSurname(student);
		const departmentOptions =
			typeof departmentId === 'string' ? { departmentId: Number.parseInt(departmentId) } : {};
		const supervisorBrowserFilters =
			browserType === BrowserType.SUPERVISOR ? { majorId, studentLastName: student } : {};
		if (
			cacheState !== previousCacheState ||
			sortStr !== previousSearchParams?.get('sort') ||
			showReserved !== previousSearchParams.has('showReserved') ||
			pageNumberStr !== previousSearchParams.get('pageNumber') ||
			supervisorIdStr !== previousSearchParams.get('supervisorId') ||
			content !== (previousSearchParams.get('content') ?? '') ||
			searchParams.get('tags') !== previousSearchParams.get('tags') ||
			degreeTypeStr !== previousSearchParams.get('degreeType') ||
			majorIdStr !== previousSearchParams.get('majorId') ||
			student !== (previousSearchParams.get('student') ?? '') ||
			departmentId !== previousDepartmentId
		)
			getPapers({
				pageNumber,
				pageSize,
				supervisorId,
				content,
				tagsIds: tags,
				sortType: sort,
				onlyNotReserved: !showReserved,
				majorDegreeType: degreeType,
				...departmentOptions,
				...supervisorBrowserFilters,
			})
				.then(
					(
						response: Awaited<
							ReturnType<
								| typeof getStudentPapers
								| typeof getSupervisorPapers
								| typeof getPapersToReview
							>
						>
					) => {
						setPageCount(response.pagination.totalPages);
						setPapers(response.responseData.papers ?? []);
					}
				)
				.catch(networkErrorHandler)
				.catch((e) => {
					if (e instanceof Error) {
						setErrorMessage(e.message);
					} else if (typeof e === 'string') {
						setErrorMessage(e);
					}
				});
	}, [
		browserType,
		cacheState,
		departmentId,
		getPapers,
		networkErrorHandler,
		pageSize,
		previousCacheState,
		previousDepartmentId,
		previousSearchParams,
		searchParams,
	]);

	useEffect(() => {
		if (showSupervisor) {
			getSupervisors()
				.then((supervisors) => {
					const supervisorsMap =
						supervisors
							?.map((supervisor) => ({
								value: supervisor.supervisorId,
								label: renderSupervisor(supervisor),
							}))
							.filter(
								(v): v is typeof v & { value: number } =>
									typeof v.value === 'number'
							) ?? [];
					setSupervisorFilter(supervisorsMap);
				})
				.catch(networkErrorHandler);
		}
		getAllTags()
			.then((tags) => {
				const tagsMap = tags.map(({ id, content }) => ({ value: id, label: content }));
				setTagsFilter(tagsMap);
			})
			.catch(networkErrorHandler);
		if (browserType === BrowserType.SUPERVISOR)
			getMajors()
				.then((r) =>
					setMajorList(
						r.majors
							?.filter(
								(m): m is typeof m & { name: string; majorId: number } =>
									typeof m.name === 'string' && typeof m.majorId === 'number'
							)
							.map((m) => ({ label: m.name, value: m.majorId.toString() })) ?? []
					)
				)
				.catch(networkErrorHandler);
	}, [browserType, networkErrorHandler, showSupervisor]);

	useEffect(() => {
		const matchMedia = window.matchMedia(smallScreenRule);
		const eventHandler = (e: MediaQueryListEvent) => setSmallScreen(e.matches);
		try {
			matchMedia.addEventListener('change', eventHandler);
		} catch (e) {
			if (e instanceof TypeError) {
				matchMedia.addListener(eventHandler);
			}
		}
		return () => {
			try {
				matchMedia.removeEventListener('change', eventHandler);
			} catch (e) {
				if (e instanceof TypeError) {
					matchMedia.removeListener(eventHandler);
				}
			}
		};
	}, [setSmallScreen]);

	const navigate = useNavigate();

	const reviewButtons =
		browserType === BrowserType.REVIEW
			? (item: PaperListDto) => (
					<>
						<button
							className="button-browser review-button"
							onClick={() => {
								reviewPaper(item.paperId, true)
									.then(() => {
										toast.success(t('accepted'));
										setCacheState((prev) => prev + 1);
									})
									.catch(networkErrorHandler);
							}}
						>
							{t('accept')}
						</button>
						<button
							className="button-browser-2 review-button"
							onClick={() => {
								reviewPaper(item.paperId, false)
									.then(() => {
										toast.success(t('rejected'));
										setCacheState((prev) => prev + 1);
									})
									.catch(networkErrorHandler);
							}}
						>
							{t('reject')}
						</button>
					</>
			  )
			: (): null => null;
	const displayPapers = papers?.map((item) => {
		return (
			<tr key={item.paperId}>
				<td className={smallScreen ? 'c-12' : undefined}>
					<p>
						{item.paperDetails?.polishTitle} /<br />
						{item.paperDetails?.englishTitle}
					</p>
				</td>
				{!smallScreen && (
					<>
						{showSupervisor && (
							<td>
								<p>
									{'supervisor' in item && item.supervisor
										? renderSupervisor(item.supervisor)
										: null}
								</p>
							</td>
						)}
						{browserType !== BrowserType.STUDENT && (
							<td>
								<p>
									{(item as UnassignedPaperListDto | ReviewPaperListDto).students
										?.map((st) => `${st.lastName} ${st.firstName}`)
										.join(', ')}
								</p>
							</td>
						)}
						{browserType === BrowserType.SUPERVISOR && (
							<td>
								<p>
									{(item as UnassignedPaperListDto).majorPapers
										?.map((major) => major.majorName)
										.join(', ')}
								</p>
							</td>
						)}
						{browserType === BrowserType.REVIEW && (
							<td>
								<p>
									{(item as ReviewPaperListDto).majorPapers
										?.map((major) => major.majorShortName)
										.join(', ')}
								</p>
							</td>
						)}
						<td>
							<p>{item.paperDetails?.maxStudents}</p>
						</td>
						{browserType !== BrowserType.REVIEW && (
							<td>
								<p>{item.tags?.map((tag) => tag.content).join(', ')}</p>
							</td>
						)}
						<td>
							<p>{new Date(item.createdOn ?? 0).toLocaleString()}</p>
						</td>
					</>
				)}
				<td className={smallScreen ? 'c-12 flex flex-column boxcell' : 'flex flex-column'}>
					<button
						className={`${
							browserType === BrowserType.REVIEW ? 'button-browser' : 'button-1'
						}`}
						onClick={() => navigate(`/paper/${item.paperId}${detailsParams}`)}
					>
						{t('open')}
					</button>
					{reviewButtons(item)}
				</td>
			</tr>
		);
	});

	const changePage = ({ selected }: { selected: number }) => {
		const selectedPage = selected + 1;
		setSearchParams({
			...Object.fromEntries(searchParams.entries()),
			pageNumber: selectedPage.toString(),
		});
	};

	const filter = () => {
		const prevParams = Object.fromEntries(searchParams.entries());
		const updatedParams: Record<string, string> = { ...prevParams, sort: sortType.toString() };
		delete updatedParams.pageNumber;
		if (typeof supervisorIdChosen === 'number') {
			updatedParams.supervisorId = supervisorIdChosen.toString();
		} else {
			delete updatedParams.supervisorId;
		}
		if (titleChosen.length) {
			updatedParams.content = titleChosen;
		} else {
			delete updatedParams.content;
		}
		if (tagsIdChosen.length) {
			updatedParams.tags = tagsIdChosen.join(',');
		} else {
			delete updatedParams.tags;
		}
		if (typeof majorDegreeType === 'number') {
			updatedParams.degreeType = majorDegreeType.toString();
		} else {
			delete updatedParams.degreeType;
		}
		if (typeof majorId === 'number' && browserType === BrowserType.SUPERVISOR) {
			updatedParams.majorId = majorId.toString();
		} else {
			delete updatedParams.majorId;
		}
		if (studentSurname?.length && browserType === BrowserType.SUPERVISOR) {
			updatedParams.student = studentSurname;
		} else {
			delete updatedParams.student;
		}
		if (showReserved) {
			updatedParams.showReserved = '1';
		} else {
			delete updatedParams.showReserved;
		}
		setSearchParams(updatedParams);
	};

	const filterOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) =>
		e.key === 'Enter' && filter();

	const acceptAll = () => {
		const papersToAccept = papers?.map(({ paperId }) => paperId).filter(notEmpty);
		reviewPapersList(papersToAccept, true)
			.then(() => {
				toast.success(t('accepted'));
				setCacheState((prev) => prev + 1);
			})
			.catch(networkErrorHandler);
	};

	const majorTypeOptions = [
		{ label: t('bachelor'), value: MajorDegreeType.BACHELOR },
		{ label: t('master'), value: MajorDegreeType.MASTER },
	] as const;

	return (
		<>
			{!papers && (
				<div className="c-12 flex flex-center">
					<Loading disableStyles={true} />
				</div>
			)}
			<div className="content-wrapper">
				<div className={`scroll-content bg-white ${showHeader ? 'main-shadow' : ''}`}>
					{showHeader && (
						<div className="content-header bg-white flex flex-middle flex-center-s pl-8 pl-0-s">
							<h1 className="h2-s text-center">{t('browser_header')}</h1>
						</div>
					)}
					<div className="c-12 flex flex-wrap">
						<div className="c-12 flex flex-wrap-s">
							<input
								placeholder={t('search_title')}
								className="c-12 m-1 input-1"
								type="text"
								onChange={(e) => setTitleChosen(e.target.value)}
								value={titleChosen}
								onKeyPress={filterOnEnter}
							/>
							{browserType === BrowserType.SUPERVISOR && (
								<input
									placeholder={t('student_surname')}
									className="c-12 m-1 input-1"
									type="text"
									onChange={(e) => setStudentSurname(e.target.value)}
									value={studentSurname}
									onKeyPress={filterOnEnter}
								/>
							)}
						</div>
						<div className="c-12 flex flex-wrap-s">
							{showSupervisor && (
								<Select
									className="react-select-container c-12 m-1"
									classNamePrefix="react-select"
									options={supervisorFilter ?? []}
									placeholder={t('select_supervisor')}
									onChange={(v) => {
										setSupervisorIdChosen(v?.value);
									}}
									value={supervisorFilter?.find(
										(s) => s.value === supervisorIdChosen
									)}
									isClearable={true}
									isLoading={supervisorFilter === null}
								/>
							)}
							<Select
								className="react-select-container c-12 m-1"
								classNamePrefix="react-select"
								options={tagsFilter ?? []}
								isMulti={true}
								placeholder={t('select_tags')}
								onChange={(v) => {
									setTagsIdChosen(v.map(({ value }) => value).filter(notEmpty));
								}}
								value={tagsFilter?.filter((t) => tagsIdChosen.includes(t.value))}
								isLoading={tagsFilter === null}
							/>
							{browserType !== BrowserType.STUDENT && (
								<Select
									className="react-select-container c-12 m-1"
									classNamePrefix="react-select"
									options={majorTypeOptions}
									placeholder={t('select_degree')}
									onChange={(v) => {
										setMajorDegreeType(v?.value);
									}}
									value={majorTypeOptions.find(
										(mt) => mt.value === majorDegreeType
									)}
									isClearable={true}
								/>
							)}
							{browserType === BrowserType.SUPERVISOR && (
								<Select
									className="react-select-container c-12 m-1"
									classNamePrefix="react-select"
									options={majorList ?? []}
									isMulti={false}
									isClearable
									placeholder={t('select_major')}
									onChange={(v) =>
										setMajorIds(v ? Number.parseInt(v.value) : undefined)
									}
									value={majorList.find(
										(m) => Number.parseInt(m.value) === majorId
									)}
									isLoading={tagsFilter === null}
								/>
							)}
							<Select
								className="react-select-container c-12 m-1"
								classNamePrefix="react-select"
								options={sortTypeOptions}
								placeholder={t('sort_by')}
								onChange={(v) => {
									setSortType(v?.value ?? BrowsePapersSortType.DATE_ASC);
								}}
								value={sortTypeOptions.find((st) => st.value === sortType)}
							/>
							{browserType === BrowserType.STUDENT && (
								<label className="c-12 m-1 p flex flex-middle">
									<input
										type="checkbox"
										className="checkbox-1"
										onChange={(e) => setShowReserved(e.target.checked)}
										checked={showReserved}
									/>
									<p className="ml-1">{t('show_reserved')}</p>
								</label>
							)}
						</div>
						<div className="c-12 m-1 flex flex-between">
							<button className="button-1 c-2 c-12-s" onClick={filter}>
								{t('filter')}
							</button>
							{browserType === BrowserType.REVIEW && (papers?.length ?? 0) > 0 && (
								<button className="button-1 c-2 c-12-s" onClick={acceptAll}>
									{t('accept_all')}
								</button>
							)}
						</div>
					</div>

					<div className="c-12 flex flex-center flex-wrap mt-2">
						<div>{errorMessage && <div>{errorMessage}</div>}</div>

						{papers && (
							<>
								{papers?.length ? (
									<>
										<div className="c-12 flex flex-middle flex-column overflow-x-auto">
											<div className="c-10 flex flex-middle mb-1">
												<table className="c-12 browser-table">
													<thead>
														<tr>
															<th>
																<p>
																	{t('title')} / {t('title_en')}:
																</p>
															</th>
															{!smallScreen && (
																<>
																	{showSupervisor && (
																		<th>
																			<p>{t('supervisor')}</p>
																		</th>
																	)}
																	{browserType !==
																		BrowserType.STUDENT && (
																		<th>
																			<p>{t('students')}</p>
																		</th>
																	)}
																	{browserType !==
																		BrowserType.STUDENT && (
																		<th>
																			<p>{t('programme')}</p>
																		</th>
																	)}
																	<th>
																		<p>
																			{t('students_number')}
																		</p>
																	</th>
																	{browserType !==
																		BrowserType.REVIEW && (
																		<th>
																			<p>{t('tags')}</p>
																		</th>
																	)}
																	<th>
																		<p>{t('creation_date')}</p>
																	</th>
																</>
															)}
															<th></th>
														</tr>
													</thead>
													<tbody>{displayPapers}</tbody>
												</table>
											</div>
											<span className="c-12 mt-2 flex flex-center">
												<ReactPaginate
													previousLabel={t('pagination_previous')}
													nextLabel={t('pagination_next')}
													breakLabel="..."
													pageCount={pageCount}
													forcePage={pageNumber - 1}
													onPageChange={changePage}
													marginPagesDisplayed={1}
													pageRangeDisplayed={2}
													containerClassName="paginationBttns"
													previousLinkClassName="previousBttn"
													nextLinkClassName="nextBttn"
													disabledClassName="PaginationDisabled"
													activeClassName="paginationActive"
												/>
											</span>
										</div>
									</>
								) : (
									<div className="c-12 flex flex-center mt-3">
										<h3 className="text-center">{t('no_papers_to_display')}</h3>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
