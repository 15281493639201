import { AxiosResponse } from 'axios';
import { client } from '.';
import {
	GenerateThesisAcceptationDocumentCommand,
	GenerateThesisAcceptationDocumentResponse,
} from '../models';

type GenerateThesisAcceptationDocumentRealResponse =
	| (GenerateThesisAcceptationDocumentResponse & { isSucceeded: false } & Required<
				Pick<GenerateThesisAcceptationDocumentResponse, 'error'>
			>)
	| Blob;
export async function generateThesisAcceptationDocument(): Promise<
	AxiosResponse<GenerateThesisAcceptationDocumentRealResponse>
> {
	const response = await client.post<
		GenerateThesisAcceptationDocumentRealResponse,
		AxiosResponse<GenerateThesisAcceptationDocumentRealResponse>,
		GenerateThesisAcceptationDocumentCommand
	>('/core/academicDocument/thesisAcceptation/generate', {}, { responseType: 'blob' });
	return response;
}
