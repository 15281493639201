import { getRequest, patchRequest, putRequest } from '.';
import {
	BrowsePapersByStudentQuery,
	BrowsePapersByStudentResponse,
	BrowsePapersToReviewQuery,
	BrowsePapersToReviewResponse,
	BrowseUnassignedPapersBySupervisorQuery,
	BrowseUnassignedPapersBySupervisorResponse,
	ReviewPaperCommand,
	ReviewPaperResponse,
	ReviewPapersCommand,
	ReviewPapersResponse,
} from '../models';

function getPagination(paginationHeader: string | undefined): Pagination {
	return JSON.parse(
		paginationHeader ?? '{"currentPage":1,"pageSize":10,"totalCount":1,"totalPages":1}'
	);
}

export async function getStudentPapers(studentBrowserParams: BrowsePapersByStudentQuery): Promise<{
	responseData: BrowsePapersByStudentResponse;
	pagination: Pagination;
}> {
	const response = await getRequest<BrowsePapersByStudentResponse>(
		'/core/browser/paper/student',
		studentBrowserParams
	);
	const responseData = response.data;
	const pagination = getPagination(response.headers.pagination);
	return { responseData, pagination };
}

export async function getPapersToReview(papersToReviewParams: BrowsePapersToReviewQuery): Promise<{
	responseData: BrowsePapersToReviewResponse;
	pagination: Pagination;
}> {
	const response = await getRequest<BrowsePapersToReviewResponse>(
		'/core/browser/paper/review',
		papersToReviewParams
	);
	const responseData = response.data;
	const pagination = getPagination(response.headers.pagination);
	return { responseData, pagination };
}

export async function getSupervisorPapers(
	supervisorBrowserParams: BrowseUnassignedPapersBySupervisorQuery
): Promise<{
	responseData: BrowseUnassignedPapersBySupervisorResponse;
	pagination: Pagination;
}> {
	const response = await getRequest<BrowseUnassignedPapersBySupervisorResponse>(
		'/core/browser/paper/supervisor',
		supervisorBrowserParams
	);
	const responseData = response.data;
	const pagination = getPagination(response.headers.pagination);
	return { responseData, pagination };
}

export async function reviewPaper(
	paperId?: number,
	isAccepted?: boolean
): Promise<ReviewPaperResponse> {
	const paperReview = {
		paperId,
		isAccepted,
	};

	const response = await patchRequest<ReviewPaperResponse, ReviewPaperCommand>(
		'/core/paper/review',
		paperReview
	);
	return response.data;
}

export async function reviewPapersList(
	paperIdsToReview?: number[] | undefined,
	isAccepted?: boolean
): Promise<ReviewPapersResponse> {
	const papersReview = {
		paperIdsToReview,
		isAccepted,
	};
	const response = await putRequest<ReviewPapersResponse, ReviewPapersCommand>(
		'/core/paper/review/list',
		papersReview
	);
	return response.data;
}

interface Pagination {
	currentPage: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
}
