/**
 * Converts a date to `YYYY-MM-DD HH:mm` string using local timezone
 * @returns `YYYY-MM-DD HH:mm` string
 */
export function customDateTimeFormat(date: Date): string {
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	const hour = date.getHours();
	const minute = date.getMinutes();
	const monthS = month < 10 ? `0${month}` : month;
	const dayS = day < 10 ? `0${day}` : day;
	const hourS = hour < 10 ? `0${hour}` : hour;
	const minuteS = minute < 10 ? `0${minute}` : minute;
	return `${year}-${monthS}-${dayS} ${hourS}-${minuteS}`;
}
