import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import { useUser } from '../../services/hooks';
import { PermissionType, YearConfigurationType } from '../../services/models';
import { useErrorHandler } from '../../services/network';
import { getYearConfiguration } from '../../services/network/AdminPanel/yearConfiguration';
import Loading from '../Loading/Loading';

export interface PhaseRestrictedProps {
	/** The *phase restricted* element */
	children: React.ReactNode;
	/** Whether the element should be restricted for supervisors in the supervisor phase (`false` by default) */
	supervisorRestricted?: boolean;
	/** Should the element (and loading if not cached) be hidden (`true`) or an error page be returned (default) */
	hide?: boolean;
	/** Cached current phase value to avoid excessive API usage, `null` blocks loading */
	cachedPhase?: YearConfigurationType | null;
	/** An alternative *error element* to render if `hide` is not `true` */
	altError?: React.ReactNode;
}

/**
 * Conditionally renders the children if current user has access to the current year phase.
 *
 * Use the `hide` prop to hide the error page (shown by default). Setting `hide` also hides loading.\
 * `cachedPhase` should be used if there are multiple `<PhaseRestricted>` elements.\
 * Use `altError` to customize the element shown if the access is restricted.
 */
export default function PhaseRestricted(props: PhaseRestrictedProps): JSX.Element {
	const { children, hide = false, cachedPhase, altError, supervisorRestricted = false } = props;
	const user = useUser();
	const [currentPhase, setCurrentPhase] = useState<YearConfigurationType | undefined | null>(
		cachedPhase
	);
	const { t } = useTranslation();
	const networkErrorHandler = useErrorHandler();

	if (process.env.NODE_ENV !== 'production' && hide && altError)
		console.error('Both hide and altError are specified. altError will be ignored.');

	useEffect(() => {
		if (cachedPhase === undefined) {
			getYearConfiguration()
				.then((r) => setCurrentPhase(r.phaseType))
				.catch(networkErrorHandler)
				.catch((e) => {
					toast.error(t('error_occurred_refresh'));
					console.error(e);
				});
		} else {
			setCurrentPhase(cachedPhase);
		}
	}, [networkErrorHandler, t, cachedPhase]);

	if (!user) return <></>;
	if (currentPhase === undefined || currentPhase === null) return hide ? <></> : <Loading />;

	const hasAdminAccess = user.userPermissions.some(
		(p) => p.permissionType === PermissionType.MANAGE_ACADEMIC_YEAR
	);
	const hasSupervisorAccess = typeof user.supervisorId === 'number' || hasAdminAccess;
	const accessPermitted =
		currentPhase === YearConfigurationType.STUDENT_PHASE ||
		(currentPhase === YearConfigurationType.SUPERVISOR_PHASE &&
			!supervisorRestricted &&
			hasSupervisorAccess) ||
		(currentPhase === YearConfigurationType.SUBMISSION_PHASE && hasSupervisorAccess) ||
		(currentPhase === YearConfigurationType.ADMIN_PHASE && hasAdminAccess);

	if (accessPermitted) return <>{children}</>;
	if (hide) return <></>;
	if (altError) return <>{altError}</>;
	return (
		<div className="content-wrapper">
			<div className="scroll-content bg-white">
				<h2>{t('system_is_locked')}</h2>
			</div>
		</div>
	);
}
