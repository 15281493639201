import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
	BrowsePapersSortType,
	PaperDto,
	PaperStatus,
	YearConfigurationType,
} from '../../services/models';
import { getAllTags, getMajors, useErrorHandler } from '../../services/network';
import { assertUnreachable, notEmpty } from '../../utils/typeGuards';
import PaperStatusDisplay from '../PaperStatusDisplay/PaperStatusDisplay';
import PhaseRestricted from '../PhaseRestricted/PhaseRestricted';

export type LocallyFilteredPapersProps = {
	papers: PaperDto[] | undefined;
	currentPhase: YearConfigurationType | undefined | null;
	fallback?: React.ReactChild;
};

const SMALL_SCREEN_RIULE = '(max-width: 540px)';

export default function LocallyFilteredPapers(props: LocallyFilteredPapersProps): JSX.Element {
	const { papers, fallback, currentPhase } = props;
	const [smallScreen, setSmallScreen] = useState(window.matchMedia(SMALL_SCREEN_RIULE).matches);
	const [tagList, setTagList] = useState<{ value: string; label: string }[] | null>(null);
	const [majorList, setMajorList] = useState<{ value: string; label: string }[]>([]);
	const [filteredPapers, setFilteredPapers] = useState<PaperDto[]>([]);
	const [titleFilter, setTitleFilter] = useState('');
	const [studentFilter, setStudentFilter] = useState('');
	const [tagIdFilter, setTagIdFilter] = useState<string[]>([]);
	const [majorIdFilter, setMajorIdFilter] = useState<number | undefined>();
	const [sortType, setSortType] = useState<BrowsePapersSortType>(BrowsePapersSortType.DATE_ASC);

	const { t } = useTranslation();
	const navigate = useNavigate();
	const networkErrorHandler = useErrorHandler();

	const sortTypeOptions = [
		{ value: BrowsePapersSortType.DATE_ASC, label: t('sort_date_asc') },
		{ value: BrowsePapersSortType.DATE_DESC, label: t('sort_date_desc') },
		{ value: BrowsePapersSortType.TITLE_ASC, label: t('sort_title_asc') },
		{ value: BrowsePapersSortType.TITLE_DESC, label: t('sort_title_desc') },
	];

	useEffect(() => {
		let sortFunction: (a: PaperDto, b: PaperDto) => number;
		switch (sortType) {
			case BrowsePapersSortType.DATE_ASC:
				sortFunction = (a: PaperDto, b: PaperDto) => {
					const dateA = new Date(a.createdOn as string);
					const dateB = new Date(b.createdOn as string);
					return dateA.getTime() - dateB.getTime();
				};
				break;

			case BrowsePapersSortType.DATE_DESC:
				sortFunction = (a: PaperDto, b: PaperDto) => {
					const dateA = new Date(a.createdOn as string);
					const dateB = new Date(b.createdOn as string);
					return dateB.getTime() - dateA.getTime();
				};
				break;

			case BrowsePapersSortType.TITLE_ASC:
				sortFunction = (a: PaperDto, b: PaperDto) => {
					return (
						a.paperDetails?.polishTitle?.localeCompare(
							b.paperDetails?.polishTitle ?? '',
							'pl',
							{ sensitivity: 'accent' }
						) ?? 0
					);
				};
				break;

			case BrowsePapersSortType.TITLE_DESC:
				sortFunction = (a: PaperDto, b: PaperDto) => {
					return (
						b.paperDetails?.polishTitle?.localeCompare(
							a.paperDetails?.polishTitle ?? '',
							'pl',
							{ sensitivity: 'accent' }
						) ?? 0
					);
				};
				break;

			case BrowsePapersSortType.SUPERVISOR_NAME_ASC:
			case BrowsePapersSortType.SUPERVISOR_NAME_DESC:
				throw new Error('Filtering by supervisor should not be an option');

			default:
				assertUnreachable(sortType);
				break;
		}
		const filtered = papers
			?.filter(
				(paper) =>
					(!titleFilter ||
						paper.paperDetails?.englishTitle
							?.toLowerCase()
							.includes(titleFilter.toLowerCase()) ||
						paper.paperDetails?.polishTitle
							?.toLowerCase()
							.includes(titleFilter.toLowerCase())) &&
					(!studentFilter ||
						paper.studentPapers?.some(
							(sp) =>
								sp.firstName?.toLowerCase().includes(studentFilter.toLowerCase()) ||
								sp.lastName?.toLowerCase().includes(studentFilter.toLowerCase())
						)) &&
					(!tagIdFilter.length ||
						paper.tags?.some((tag) => tag.id && tagIdFilter.includes(tag.id))) &&
					(typeof majorIdFilter !== 'number' ||
						paper.majorPapers?.some((mp) => mp.majorId === majorIdFilter))
			)
			.sort(sortFunction);
		setFilteredPapers(filtered ?? []);
	}, [majorIdFilter, papers, sortType, studentFilter, tagIdFilter, titleFilter]);

	useEffect(() => {
		getAllTags()
			.then((tags) => {
				const tagsMap = tags.map(({ id, content }) => ({ value: id, label: content }));
				setTagList(tagsMap);
			})
			.catch(networkErrorHandler);
		getMajors()
			.then((r) =>
				setMajorList(
					r.majors
						?.filter(
							(m): m is typeof m & { name: string; majorId: number } =>
								typeof m.name === 'string' && typeof m.majorId === 'number'
						)
						.map((m) => ({ label: m.name, value: m.majorId.toString() })) ?? []
				)
			)
			.catch(networkErrorHandler);
	}, [networkErrorHandler]);

	useEffect(() => {
		const matchMedia = window.matchMedia(SMALL_SCREEN_RIULE);
		const eventHandler = (e: MediaQueryListEvent) => setSmallScreen(e.matches);
		try {
			matchMedia.addEventListener('change', eventHandler);
		} catch (e) {
			if (e instanceof TypeError) {
				matchMedia.addListener(eventHandler);
			}
		}
		return () => {
			try {
				matchMedia.removeEventListener('change', eventHandler);
			} catch (e) {
				if (e instanceof TypeError) {
					matchMedia.removeListener(eventHandler);
				}
			}
		};
	}, [setSmallScreen]);

	if (!papers?.length) {
		return (
			<>
				{fallback ?? (
					<div className="c-12 flex flex-center mt-3">
						<h3 className="text-center">{t('no_papers_created')}</h3>
					</div>
				)}
			</>
		);
	}

	const displayCreatedPapers = filteredPapers.map((item) => (
		<tr
			key={item.paperId}
			className={
				item.status === PaperStatus.APPROVED &&
				item.studentPapers?.filter((sp) => !sp.isStudentAccepted).length
					? 'special-row'
					: ''
			}
		>
			<td className={smallScreen ? 'c-12' : undefined}>
				<p>
					{item.paperDetails?.polishTitle} /<br />
					{item.paperDetails?.englishTitle}
				</p>
			</td>
			{!smallScreen && (
				<>
					<td>
						<p>
							<PaperStatusDisplay paper={item} />
						</p>
					</td>
					<td>
						<p className="c-1">{item.paperDetails?.maxStudents}</p>
					</td>
					<td>
						<p>
							{item.studentPapers
								?.filter((st) => st.isStudentAccepted)
								.map((st) => `${st.firstName} ${st.lastName}`)
								.join(', ')}
						</p>
					</td>
					<td>
						<p>{item.majorPapers?.map((m) => m.majorName).join(', ')}</p>
					</td>
					<td>
						<p>{item.tags?.map((el) => el.content).join(', ')}</p>
					</td>
					<td>
						<p>{new Date(item.createdOn ?? 0).toLocaleString()}</p>
					</td>
				</>
			)}
			<PhaseRestricted cachedPhase={currentPhase} hide>
				<td className={smallScreen ? 'c-12 flex flex-column boxcell' : ''}>
					<button className="button-1" onClick={() => navigate(`/paper/${item.paperId}`)}>
						{t('open')}
					</button>
				</td>
			</PhaseRestricted>
		</tr>
	));

	return (
		<div className="content-wrapper">
			<div className={`scroll-content bg-white}`}>
				<div className="c-12 flex flex-wrap">
					<div className="c-12 flex flex-wrap-s">
						<input
							placeholder={t('search_title')}
							className="c-12 m-1 input-1"
							type="text"
							onChange={(e) => setTitleFilter(e.target.value)}
							value={titleFilter}
						/>
						<input
							placeholder={t('search_student')}
							className="c-12 m-1 input-1"
							type="text"
							onChange={(e) => setStudentFilter(e.target.value)}
							value={studentFilter}
						/>
					</div>
					<div className="c-12 flex flex-wrap-s">
						<Select
							className="react-select-container c-12 m-1"
							classNamePrefix="react-select"
							options={tagList ?? []}
							isMulti={true}
							placeholder={t('select_tags')}
							onChange={(v) => {
								setTagIdFilter(v.map(({ value }) => value).filter(notEmpty));
							}}
							value={tagList?.filter((t) => tagIdFilter.includes(t.value))}
							isLoading={tagList === null}
						/>
						<Select
							className="react-select-container c-12 m-1"
							classNamePrefix="react-select"
							options={majorList ?? []}
							isMulti={false}
							isClearable
							placeholder={t('select_major')}
							onChange={(v) =>
								setMajorIdFilter(v ? Number.parseInt(v.value) : undefined)
							}
							value={majorList.find(
								(m) => Number.parseInt(m.value) === majorIdFilter
							)}
							isLoading={majorList === null}
						/>
						<Select
							className="react-select-container c-12 m-1"
							classNamePrefix="react-select"
							options={sortTypeOptions}
							placeholder={t('sort_by')}
							onChange={(v) => {
								setSortType(v?.value ?? BrowsePapersSortType.DATE_ASC);
							}}
							value={sortTypeOptions.find((st) => st.value === sortType)}
						/>
					</div>
				</div>

				<div className="c-12 flex flex-center flex-wrap mt-2">
					{papers && (
						<>
							{papers?.length ? (
								<>
									<div className="c-12 flex flex-middle flex-column overflow-x-auto">
										<div className="c-10 flex flex-middle mb-1">
											<table className="c-12 browser-table">
												<thead>
													<tr>
														<th>
															<p>
																{t('title')} / {t('title_en')}:
															</p>
														</th>
														{!smallScreen && (
															<>
																<th>
																	<p>{t('paper_status')}:</p>
																</th>
																<th>
																	<p>{t('students_number')}:</p>
																</th>
																<th>
																	<p>{t('students')}:</p>
																</th>
																<th>
																	<p>{t('programme')}:</p>
																</th>
																<th>
																	<p>{t('tags')}:</p>
																</th>
																<th>
																	<p>{t('creation_date')}</p>
																</th>
															</>
														)}
													</tr>
												</thead>
												<tbody>{displayCreatedPapers}</tbody>
											</table>
										</div>
									</div>
								</>
							) : (
								<div className="c-12 flex flex-center mt-3">
									<h3 className="text-center">{t('no_papers_to_display')}</h3>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
