import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toast';
import { usePrevious } from '../../../../services/hooks';
import {
	DepartmentDto,
	ManagementBrowsePapersSortType,
	ManagementPaperDto,
	PaperStatus,
} from '../../../../services/models';
import { UnexpectedResponse, useErrorHandler } from '../../../../services/network';
import { getPapers } from '../../../../services/network/AdminPanel/adminBrowser';
import { getDepartmentList } from '../../../../services/network/AdminPanel/departmentManager';
import { getMajorList } from '../../../../services/network/AdminPanel/majorManager';
import { formatDepartment } from '../../../../utils/nameFormatting';

interface BrowserFiltersProps {
	pageNumber?: number | undefined;
	pageSize?: number | undefined;
	allowedDepartmentsIds: number[] | boolean;
	setPapers: (r?: ManagementPaperDto[] | null) => void;
	setPageCount: (r: number) => void;
}

interface SelectOption {
	value: number;
	label: string;
}

export default function PaperBrowserFilters(props: BrowserFiltersProps) {
	const [majorsIds, setMajorsIds] = useState<number[]>();
	const [status, setStatus] = useState<PaperStatus>();
	const [supervisorLastName, setSupervisorLastName] = useState<string>();
	const [studentLastName, setStudentLastName] = useState<string>();
	const [sortType, setSortType] = useState<ManagementBrowsePapersSortType>(
		ManagementBrowsePapersSortType.SUPERVISOR_LAST_NAME_ASC
	);
	const [majorsFilter, setMajorsFilter] = useState<SelectOption[] | null>(null);
	const [departmentsList, setDepartmentsList] = useState<SelectOption[] | null>(null);
	const [departmentsIds, setDepartmentsIds] = useState<number[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const previousSearchParams = usePrevious(searchParams);
	const previousPage = usePrevious(props.pageNumber);
	const { t } = useTranslation();
	const networkErrorHandler = useErrorHandler();

	useEffect(() => {
		const majorsIds =
			searchParams
				.get('majorsIds')
				?.split(',')
				.map((id) => Number.parseInt(id)) ?? undefined;
		const statusStr = searchParams.get('status');
		const status = statusStr ? Number.parseInt(statusStr) : undefined;
		const supervisorLastName = searchParams.get('supervisorLastName') ?? undefined;
		const studentLastName = searchParams.get('studentLastName') ?? undefined;
		const sortTypeStr = searchParams.get('sortType');
		const sortType = sortTypeStr
			? Number.parseInt(sortTypeStr)
			: ManagementBrowsePapersSortType.SUPERVISOR_LAST_NAME_ASC;
		const departmentsIds =
			searchParams
				.get('departmentsIds')
				?.split(',')
				.map((id) => Number.parseInt(id)) ?? [];
		setMajorsIds(majorsIds);
		setStatus(status);
		setSupervisorLastName(supervisorLastName);
		setStudentLastName(studentLastName);
		setSortType(sortType);
		setDepartmentsIds(departmentsIds);
		const { pageNumber, pageSize } = props;
		if (
			searchParams.get('majorsIds') !== previousSearchParams?.get('majorsIds') ||
			statusStr !== previousSearchParams.get('status') ||
			supervisorLastName !== (previousSearchParams.get('supervisorLastName') ?? undefined) ||
			studentLastName !== (previousSearchParams.get('studentLastName') ?? undefined) ||
			sortTypeStr !== previousSearchParams.get('sortType') ||
			searchParams.get('departmentsIds') !== previousSearchParams.get('departmentsIds') ||
			pageNumber !== previousPage
		)
			getPapers(
				{
					majorsIds,
					departmentsIds,
					status,
					supervisorLastName,
					studentLastName,
					sortType,
					pageNumber,
					pageSize,
				},
				props.allowedDepartmentsIds
			)
				.then((response) => {
					props.setPageCount(response.pagination.totalPages);
					props.setPapers(response?.responseData?.papers);
				})
				.catch(networkErrorHandler)
				.catch(() => toast.error(t('something_went_wrong')));
	}, [networkErrorHandler, previousPage, previousSearchParams, props, searchParams, t]);

	useEffect(() => {
		getDepartmentList()
			.then((r) => {
				const departments = r.departments;
				if (!departments) throw new UnexpectedResponse('Departments should be present');
				const departmentsmap = departments
					.filter(
						(dept): dept is Required<DepartmentDto> =>
							typeof dept.departmentId === 'number' &&
							typeof dept.name === 'string' &&
							typeof dept.shortName === 'string'
					)
					.map((dep) => ({
						value: dep.departmentId,
						label: formatDepartment(dep),
					}));
				setDepartmentsList(departmentsmap);
			})
			.catch(networkErrorHandler);

		getMajorList()
			.then((r) => {
				const majors = r.majors;
				if (majors) {
					const majorsMap = majors
						.filter(
							(major): major is typeof major & { majorId: number; name: string } =>
								typeof major.majorId === 'number' && typeof major.name === 'string'
						)
						.map(({ majorId, name }) => ({
							value: majorId,
							label: name,
						}));
					setMajorsFilter(majorsMap);
				}
			})
			.catch(networkErrorHandler);
	}, [networkErrorHandler]);

	const getPapersHandler = () => {
		const prevParams = Object.fromEntries(searchParams.entries());
		const updatedParams: Record<string, string> = {
			...prevParams,
			supervisorLastName: supervisorLastName ?? '',
			studentLastName: studentLastName ?? '',
		};
		if (majorsIds?.length) {
			updatedParams.majorsIds = majorsIds.join(',');
		} else {
			delete updatedParams.majorsIds;
		}
		if (departmentsIds?.length) {
			updatedParams.departmentsIds = departmentsIds.join(',');
		} else {
			delete updatedParams.departmentsIds;
		}
		if (typeof status === 'number') {
			updatedParams.status = status.toString();
		} else {
			delete updatedParams.status;
		}
		if (typeof sortType === 'number') {
			updatedParams.sortType = sortType.toString();
		} else {
			delete updatedParams.sortType;
		}
		delete updatedParams.pageNumber; // reset page on filter
		setSearchParams(updatedParams);
	};

	const browsePapersSortTypeOptions = [
		{
			value: ManagementBrowsePapersSortType.SUPERVISOR_LAST_NAME_ASC,
			label: t('supervisor_last_name_asc'),
		},
		{
			value: ManagementBrowsePapersSortType.SUPERVISOR_LAST_NAME_DESC,
			label: t('supervisor_lat_name_desc'),
		},
	];

	const statusOptions = [
		{ value: PaperStatus.CREATED, label: t('draft') },
		{ value: PaperStatus.WAITING_FOR_APPROVAL, label: t('waiting_for_approval') },
		{ value: PaperStatus.APPROVED, label: t('approved_paper') },
		{ value: PaperStatus.ASSIGNED, label: t('assigned_student') },
	];

	const filterOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) =>
		e.key === 'Enter' && getPapersHandler();

	return (
		<div>
			<div className="c-12 flex flex-wrap">
				<div className="c-12 flex">
					<input
						placeholder={t('supervisor_last_name')}
						className="c-12 m-1 input-1"
						type="text"
						onChange={(e) => setSupervisorLastName(e.target.value)}
						onKeyPress={filterOnEnter}
						value={supervisorLastName}
					/>
					<input
						placeholder={t('student_last_name')}
						className="c-12 m-1 input-1"
						type="text"
						onChange={(e) => setStudentLastName(e.target.value)}
						onKeyPress={filterOnEnter}
						value={studentLastName}
					/>
				</div>
				<div className="c-12 flex">
					<Select
						className="react-select-container c-12 m-1"
						classNamePrefix="react-select"
						options={majorsFilter ?? []}
						isMulti={true}
						placeholder={t('select_major')}
						onChange={(v) => {
							setMajorsIds(v.map(({ value }) => value));
						}}
						isLoading={majorsFilter === null}
						value={
							majorsFilter?.filter((major) => majorsIds?.includes(major.value)) ?? []
						}
					/>
					<Select
						className="react-select-container c-12 m-1"
						classNamePrefix="react-select"
						options={departmentsList ?? []}
						isMulti={true}
						placeholder={t('select_department')}
						onChange={(values) => {
							setDepartmentsIds(values.map(({ value }) => value));
						}}
						isLoading={departmentsList === null}
						value={
							departmentsList?.filter((department) =>
								departmentsIds.includes(department.value)
							) ?? []
						}
					/>
					<Select
						className="react-select-container c-12 m-1"
						classNamePrefix="react-select"
						options={browsePapersSortTypeOptions}
						placeholder={t('sort_by')}
						onChange={(v) => {
							setSortType(
								v?.value ?? ManagementBrowsePapersSortType.SUPERVISOR_LAST_NAME_ASC
							);
						}}
						value={
							browsePapersSortTypeOptions.find((st) => st.value === sortType) ?? null
						}
					/>
					<Select
						className="react-select-container c-12 m-1"
						classNamePrefix="react-select"
						options={statusOptions}
						placeholder={t('select_status')}
						onChange={(v) => {
							setStatus(v?.value);
						}}
						isClearable={true}
						value={statusOptions.find((st) => st.value === status)}
					/>
				</div>
				<div className="c-12 m-1 flex flex-start">
					<button className="button-2 c-2 c-12-s" onClick={getPapersHandler}>
						{t('filter')}
					</button>
				</div>
			</div>
		</div>
	);
}
