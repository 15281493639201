export interface BaseResponse {
	isSucceeded?: boolean;
	error?: ModelError | null;
}
export interface AcademicDegreeDto {
	academicDegreeId?: number;
	name?: string | null;
}
export interface AcceptStudentForPaperCommand {
	paperId?: number;
	studentId?: number;
}
export type AcceptStudentForPaperResponse = BaseResponse;
export interface AgreeForStudentPaperCommand {
	isAgreed?: boolean;
	paperId?: number;
}
export type AgreeForStudentPaperResponse = BaseResponse;
export interface AnnouncementDto {
	announcementId?: number;
	polishText?: string | null;
	englishText?: string | null;
}
export interface AssignStudentToPaperCommand {
	paperId?: number;
}
export interface AssignStudentToPaperDto {
	studentId?: number;
	paperId?: number;
	isStudentAccepted?: boolean;
}
export interface AssignStudentToPaperResponse extends BaseResponse {
	studentPaper?: AssignStudentToPaperDto;
}
export interface AssignSupervisorToPaperCommand {
	paperId?: number;
}
export type AssignSupervisorToPaperResponse = BaseResponse;
export interface BrowsePapersByStudentQuery extends BrowsePapersQueryBase {
	supervisorId?: number;
	/** `true` by default on the server */
	onlyNotReserved?: boolean;
}
export interface BrowsePapersByStudentResponse extends BaseResponse {
	papers?: Array<PaperListDto> | null;
}
export interface BrowsePapersQueryBase {
	content?: string;
	tagsIds?: string[];
	sortType?: BrowsePapersSortType;
	pageNumber: number;
	pageSize: number;
}
export enum BrowsePapersSortType {
	DATE_ASC = 0,
	DATE_DESC = 1,
	TITLE_ASC = 2,
	TITLE_DESC = 3,
	SUPERVISOR_NAME_ASC = 4,
	SUPERVISOR_NAME_DESC = 5,
}
export enum BrowseUsersSortType {
	EMAIL_ASC = 0,
	EMAIL_DESC = 1,
	FIRST_NAME_ASC = 2,
	FIRST_NAME_DESC = 3,
	LAST_NAME_ASC = 4,
	LAST_NAME_DESC = 5,
}
export interface BrowsePapersToReviewQuery extends BrowsePapersQueryBase {
	supervisorId?: number;
	departmentId?: number;
	majorDegreeType?: MajorDegreeType;
}
export interface BrowsePapersToReviewResponse extends BaseResponse {
	papers?: Array<ReviewPaperListDto> | null;
}
export interface BrowseUnassignedPapersBySupervisorQuery extends BrowsePapersQueryBase {
	majorId?: number;
	majorDegreeType?: MajorDegreeType;
	studentLastName?: string;
}
export interface BrowseUnassignedPapersBySupervisorResponse extends BaseResponse {
	papers?: Array<UnassignedPaperListDto> | null;
}
export interface ChangePaperStatusToWaitingForApprovalCommand {
	paperId?: number;
}
export type ChangePaperStatusToWaitingForApprovalResponse = BaseResponse;
export interface ChangeSupervisorDescriptionCommand {
	description?: string | null;
}
export type ChangeSupervisorDescriptionResponse = BaseResponse;
export interface CreatePaperByStudentResponse extends BaseResponse {
	createdPaper?: PaperDto;
}
export interface CreatePaperBySupervisorResponse extends BaseResponse {
	createdPaper?: SupervisorPaperDto;
}
export type DeletePaperByStudentResponse = BaseResponse;
export type DeletePaperBySupervisorResponse = BaseResponse;
export interface DepartmentDto {
	departmentId?: number;
	name?: string | null;
	shortName?: string | null;
}
export enum EmailOptOutType {
	NONE = 0,
	ALL = 65535,
}
export enum ErrorCode {
	UNHANDLED_EXCEPTION = 'UNHANDLED_EXCEPTION',
	SERVER_ERROR = 'SERVER_ERROR',
	DATABASE_OPERATION_FAILED = 'DATABASE_OPERATION_FAILED',
	SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
	VALIDATION_FAILED = 'VALIDATION_FAILED',
	ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
	INSUFFICIENT_PERMISSIONS_EXCEPTION = 'INSUFFICIENT_PERMISSIONS_EXCEPTION',
	INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
	ENTITY_VALIDATION_FAILED = 'ENTITY_VALIDATION_FAILED',
	FILE_OPERATION_FAILED = 'FILE_OPERATION_FAILED',
	RESOURCE_EXPIRED = 'RESOURCE_EXPIRED',
	DUPLICATE_ALREADY_EXISTS = 'DUPLICATE_ALREADY_EXISTS',
	// Errors returned by NGINX
	RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
	METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED',
}
export type GenerateThesisAcceptationDocumentCommand = Record<string, never>;
export interface GenerateThesisAcceptationDocumentResponse extends BaseResponse {
	thesisAcceptationDocumentFileModel?: ResourceFileModel;
}
export interface GetAcademicDegreeDictionaryDataResponse extends BaseResponse {
	academicDegrees?: Array<AcademicDegreeDto> | null;
}
export type GetAdminInfoQuery = Record<string, never>;
export interface GetAdminInfoResponse extends BaseResponse {
	adminInfo?: string | null;
}
export type GetAllPublicFilesQuery = Record<string, never>;
export interface GetAllPublicFilesResponse extends BaseResponse {
	publicFiles?: PublicFilesDto;
}
export type GetAllSupervisorsQuery = Record<string, never>;
export interface GetAllSupervisorsResponse extends BaseResponse {
	supervisors?: Array<SupervisorDto> | null;
}
export type GetAssignedAndCreatedPapersQuery = Record<string, never>;
export interface GetAssignedAndCreatedPapersResponse extends BaseResponse {
	createdPapers?: Array<PaperDto> | null;
	assignedPapers?: Array<PaperDto> | null;
}
export type GetDepartmentsFromUserPermissionsQuery = Record<string, never>;
export interface GetDepartmentsFromUserPermissionsResponse extends BaseResponse {
	departments?: Array<DepartmentDto> | null;
}
export type GetLastAnnouncementQuery = Record<string, never>;
export interface GetLastAnnouncementResponse extends BaseResponse {
	lastAnnouncement?: AnnouncementDto;
}
export interface GetMajorDictionaryDataResponse extends BaseResponse {
	majors?: Array<MajorDto> | null;
}
export interface GetPaperDetailsResponse extends BaseResponse {
	paper?: PaperDto;
}
export interface GetPaperFileResponse extends BaseResponse {
	filePath?: string | null;
	contentType?: string | null;
}
export type GetUserPreferencesQuery = Record<string, never>;
export interface GetUserPreferencesResponse extends BaseResponse {
	userPreferencesModel?: UserPreferencesModel;
}
export type GetUserProfileQuery = Record<string, never>;
export interface GetUserProfileResponse extends BaseResponse {
	userProfile?: UserProfileDto;
}
export enum HttpStatusCode {
	NUMBER_100 = 100,
	NUMBER_101 = 101,
	NUMBER_102 = 102,
	NUMBER_103 = 103,
	NUMBER_200 = 200,
	NUMBER_201 = 201,
	NUMBER_202 = 202,
	NUMBER_203 = 203,
	NUMBER_204 = 204,
	NUMBER_205 = 205,
	NUMBER_206 = 206,
	NUMBER_207 = 207,
	NUMBER_208 = 208,
	NUMBER_226 = 226,
	NUMBER_300 = 300,
	NUMBER_301 = 301,
	NUMBER_302 = 302,
	NUMBER_303 = 303,
	NUMBER_304 = 304,
	NUMBER_305 = 305,
	NUMBER_306 = 306,
	NUMBER_307 = 307,
	NUMBER_308 = 308,
	NUMBER_400 = 400,
	NUMBER_401 = 401,
	NUMBER_402 = 402,
	NUMBER_403 = 403,
	NUMBER_404 = 404,
	NUMBER_405 = 405,
	NUMBER_406 = 406,
	NUMBER_407 = 407,
	NUMBER_408 = 408,
	NUMBER_409 = 409,
	NUMBER_410 = 410,
	NUMBER_411 = 411,
	NUMBER_412 = 412,
	NUMBER_413 = 413,
	NUMBER_414 = 414,
	NUMBER_415 = 415,
	NUMBER_416 = 416,
	NUMBER_417 = 417,
	NUMBER_421 = 421,
	NUMBER_422 = 422,
	NUMBER_423 = 423,
	NUMBER_424 = 424,
	NUMBER_426 = 426,
	NUMBER_428 = 428,
	NUMBER_429 = 429,
	NUMBER_431 = 431,
	NUMBER_451 = 451,
	NUMBER_500 = 500,
	NUMBER_501 = 501,
	NUMBER_502 = 502,
	NUMBER_503 = 503,
	NUMBER_504 = 504,
	NUMBER_505 = 505,
	NUMBER_506 = 506,
	NUMBER_507 = 507,
	NUMBER_508 = 508,
	NUMBER_510 = 510,
	NUMBER_511 = 511,
}
export enum LanguageModeType {
	ENGLISH = 0,
	POLISH = 1,
}
export enum MajorDegreeType {
	BACHELOR = 0,
	MASTER = 1,
}
export interface MajorPaperDto {
	majorId?: number;
	paperId?: number;
	majorName?: string | null;
	majorShortName?: string | null;
}
export interface ModelError {
	errorCode?: ErrorCode | null;
	message?: string | null;
	statusCode?: HttpStatusCode;
	validations?: { [key: string]: Array<string> } | null;
}
export interface PaperDetailsDto {
	paperDetailsId?: number;
	polishTitle?: string | null;
	englishTitle?: string | null;
	description?: string | null;
	maxStudents?: number;
	paperId?: number;
	createdOn?: Date | string;
	modifiedOn?: Date | string | null;
}
export interface PaperDto {
	paperId?: number;
	studentId?: number | null;
	supervisorId?: number | null;
	status?: PaperStatus;
	isReserved?: boolean;
	isAssignedToSupervisor?: boolean;
	tags?: Array<TagDto> | null;
	createdOn?: Date | string;
	modifiedOn?: Date | string | null;
	paperDetails?: PaperDetailsDto;
	supervisor?: SupervisorDto;
	paperFiles?: Array<PaperFileDto> | null;
	studentPapers?: Array<StudentPaperDto> | null;
	majorPapers?: Array<MajorPaperDto> | null;
}
export interface ManagementPaperDto {
	paperId?: number;
	studentId?: number | null;
	supervisorId?: number | null;
	status?: PaperStatus;
	isReserved?: boolean;
	isAssignedToSupervisor?: boolean;
	tags?: Array<TagDto> | null;
	createdOn?: Date | string;
	modifiedOn?: Date | string | null;
	paperDetails?: PaperDetailsDto;
	supervisor?: SupervisorDetailsDto;
	studentPapers?: Array<ManagementStudentPaperDto> | null;
	majorPapers?: Array<MajorPaperDto> | null;
}
export interface PaperFileDto {
	paperFileId?: number;
	filePath?: string | null;
	paperId?: number;
}
export interface PaperInfoDto {
	paperId?: number;
	status?: PaperStatus;
	isReserved?: boolean;
	paperDetails?: PaperDetailsDto;
	supervisor?: SupervisorDto;
}
export interface PaperListDto {
	paperId?: number;
	studentId?: number | null;
	supervisorId?: number | null;
	status?: PaperStatus;
	isReserved?: boolean;
	tags?: Array<TagDto> | null;
	createdOn?: Date | string;
	modifiedOn?: Date | string | null;
	paperDetails?: PaperDetailsDto;
	supervisor?: SupervisorDto;
}
export enum PaperStatus {
	CREATED = 0,
	WAITING_FOR_APPROVAL = 1,
	APPROVED = 2,
	ASSIGNED = 3,
	IN_PROGRESS = 4,
	COMPLETED = 5,
}
export interface PermissionDto {
	permissionId?: number;
	name?: string | null;
	type?: PermissionType;
	isGeneral?: boolean;
}
export enum PermissionType {
	MANAGE_PERMISSIONS = 0,
	REVIEW_PAPERS = 1,
	GENERATE_REPORTS = 2,
	GENERATE_PERSONAL_REPORTS = 3,
	// Student reports are disabled
	// GENERATE_STUDENT_REPORTS = 4,
	MANAGE_ACADEMIC_YEAR = 5,
	LOGIN_AS_USER = 6,
	MANAGE_USERS = 10,
	MANAGE_DICTIONARY_DATA = 11,
	MANAGE_STUDENTS = 12,
	MANAGE_TAGS = 13,
	MANAGE_ANNOUNCEMENTS = 14,
	READ_USERS = 20,
	READ_STUDENTS = 22,
	IMPORT_DATA = 30,
	SEND_MANUAL_EMAIL = 40,
	MANAGE_PAPERS = 50,
	MANAGE_PUBLIC_FILES = 70,
	ARCHIVE_SYSTEM = 100,
}
export interface PublicFileEntry {
	publicFileId?: number;
	filePath?: string | null;
	createdOn?: Date;
	modifiedOn?: Date | null;
}
export interface PublicFilesDto {
	publicFiles?: Array<PublicFileEntry> | null;
}
export interface PutAcademicDegreeCommand {
	academicDegreeId?: number;
}
export type PutAcademicDegreeResponse = BaseResponse;
export interface PutTagCommand {
	content?: string | null;
}
export interface PutTagResponse extends BaseResponse {
	tag?: TagDto;
	isUpdated?: boolean;
}
export type RejectStudentFromPaperResponse = BaseResponse;
export interface ReviewPaperCommand {
	paperId?: number;
	isAccepted?: boolean;
}
export interface ReviewPaperListDto {
	paperId?: number;
	studentId?: number | null;
	supervisorId?: number | null;
	status?: PaperStatus;
	tags?: Array<TagDto> | null;
	createdOn?: Date | string;
	modifiedOn?: Date | string | null;
	paperDetails?: PaperDetailsDto;
	supervisor?: SupervisorDto;
	students?: Array<StudentPaperDto> | null;
	majorPapers?: Array<MajorPaperDto> | null;
}
export interface ReviewPaperResponse extends BaseResponse {
	paperId?: number;
	isAccepted?: boolean;
}
export interface ReviewPapersCommand {
	paperIdsToReview?: Array<number> | null;
	isAccepted?: boolean;
}
export interface ReviewPapersResponse extends BaseResponse {
	isAccepted?: boolean;
}
export interface RollbackPaperToDraftCommand {
	paperId?: number;
}
export type RollbackPaperToDraftResponse = BaseResponse;
export interface SaveEmailPreferencesCommand {
	emailOptOutType?: EmailOptOutType;
}
export interface SaveEmailPreferencesResponse extends BaseResponse {
	userPreferencesModel?: UserPreferencesModel;
}
export interface SaveUserPreferencesCommand {
	languageModeType?: LanguageModeType;
}
export interface SaveUserPreferencesResponse extends BaseResponse {
	userPreferencesModel?: UserPreferencesModel;
}
export interface SearchTagsByContentResponse extends BaseResponse {
	tags?: Array<TagDto> | null;
}
export interface StudentDto {
	studentId?: number;
	emailAddress?: string | null;
	albumNumber?: number;
	majorName?: string | null;
}
// edit
export interface StudentPaperBody {
	supervisorId?: number;
	paperId?: number;
	polishTitle?: string;
	englishTitle?: string;
	description?: string;
	maxStudents?: number;
	filesToAdd?: Array<Blob>;
	filesIdsToKeep?: Array<number>;
	majorsIdsToAdd?: Array<number>;
	majorsIdsToKeep?: Array<number>;
	tagsIds?: Array<string>;
}
// create
export interface StudentPaperBody1 {
	supervisorId?: number;
	polishTitle?: string;
	englishTitle?: string;
	description?: string;
	maxStudents?: number;
	files?: Array<Blob>;
	majorsIds?: Array<number>;
	tagsIds?: Array<string>;
}
export interface StudentPaperDto {
	studentId?: number;
	paperId?: number;
	isStudentAccepted?: boolean;
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	defenceYear?: Date | string;
	assignedOn?: Date | string | null;
}
export interface ManagementStudentPaperDto extends StudentPaperDto {
	majorId?: number;
}
export interface SupervisorAcademicDegreeDto {
	supervisorId?: number;
	academicDegree?: AcademicDegreeDto;
}
export interface SupervisorDetailsDto {
	supervisorId?: number;
	departmentId?: number | null;
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	department?: DepartmentDto;
	supervisorAcademicDegrees?: Array<ManagementSupervisorAcademicDegreeDto> | null;
}
export interface SupervisorDto {
	supervisorId?: number;
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	description?: string | null;
	department?: DepartmentDto;
	supervisorAcademicDegrees?: Array<SupervisorAcademicDegreeDto> | null;
}
// edit
export interface SupervisorPaperBody {
	isReserved?: boolean;
	paperId?: number;
	polishTitle?: string;
	englishTitle?: string;
	description?: string;
	maxStudents?: number;
	filesToAdd?: Array<Blob>;
	filesIdsToKeep?: Array<number>;
	majorsIdsToAdd?: Array<number>;
	majorsIdsToKeep?: Array<number>;
	tagsIds?: Array<string>;
}
// create
export interface SupervisorPaperBody1 {
	isReserved?: boolean;
	polishTitle?: string;
	englishTitle?: string;
	description?: string;
	maxStudents?: number;
	files?: Array<Blob>;
	majorsIds?: Array<number>;
	tagsIds?: Array<string>;
}
export interface SupervisorPaperDto {
	paperId?: number;
	supervisorId?: number | null;
	status?: PaperStatus;
	isReserved?: boolean;
	createdOn?: Date | string;
	modifiedOn?: Date | string | null;
	paperDetails?: PaperDetailsDto;
	supervisor?: SupervisorDto;
	paperFiles?: Array<PaperFileDto> | null;
	majorPapers?: Array<MajorPaperDto> | null;
	tags?: Array<TagDto> | null;
}
export interface TagDto {
	id?: string | null;
	content?: string | null;
	creatorId?: number;
	createdOn?: Date | string;
	modifiedOn?: Date | string | null;
}
export interface UnassignedPaperListDto {
	paperId?: number;
	studentId?: number | null;
	supervisorId?: number | null;
	status?: PaperStatus;
	tags?: Array<TagDto> | null;
	createdOn?: Date | string;
	modifiedOn?: Date | string | null;
	paperDetails?: PaperDetailsDto;
	students?: Array<StudentPaperDto> | null;
	majorPapers?: Array<MajorPaperDto> | null;
}
export interface UpdatePaperByStudentResponse extends BaseResponse {
	updatedPaper?: PaperDto;
}
export interface UpdatePaperBySupervisorResponse extends BaseResponse {
	updatedPaper?: PaperDto;
}
export interface UserPermissionDto {
	userId?: number;
	permissionId?: number;
	departmentsIds?: Array<number> | null;
	permission?: PermissionDto;
}
export interface UserPreferencesModel {
	languageModeType?: LanguageModeType;
	emailOptOutType?: EmailOptOutType;
}
export interface UserProfileDto {
	userId?: number;
	emailAddress?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	albumNumber?: number;
	supervisor?: SupervisorDto;
	leaderOfDepartment?: SupervisorDto;
	userPermissions?: Array<UserPermissionDto> | null;
	students?: Array<StudentDto> | null;
	studentAcceptedPaper?: PaperInfoDto;
}
export interface CreateUserDto {
	emailAddress?: string | null;
	firstName?: string | null;
	lastName?: string | null;
}
export interface CreateUserWithPasswordCommand {
	emailAddress?: string | null;
	password?: string | null;
	firstName?: string | null;
	lastName?: string | null;
}
export interface CreateUserWithPasswordResponse extends BaseResponse {
	user?: CreateUserDto;
}
export interface GenerateUserJwtTokenForPermittedUserCommand {
	userId?: number;
}
export interface MajorDto {
	majorId?: number;
	name?: string | null;
}
export interface MajorLibraryDto {
	majorId?: number;
	name?: string | null;
}
export interface RefreshAuthenticationTokensCommand {
	jwtToken?: string | null;
	refreshAuthToken?: string | null;
}
export interface RefreshAuthenticationTokensResponse extends BaseResponse {
	jwtToken?: string | null;
	refreshAuthToken?: string | null;
}
export interface RegenerateTokenWithOneStudentCommand {
	jwtToken?: string | null;
	studentId?: number;
}
export interface RegenerateTokenWithOneStudentResponse extends BaseResponse {
	jwtToken?: string | null;
	hasStudentPaperAssigned?: boolean;
}
export interface ResetPasswordCommand {
	newPassword?: string | null;
	userId?: number;
	tokenCode?: string | null;
}
export interface ResetPasswordResponse extends BaseResponse {
	refreshAuthToken?: string | null;
}
export interface SendResetPasswordTokenCommand {
	emailAddress?: string | null;
}
export type SendResetPasswordTokenResponse = BaseResponse;
export interface SignInUserDto {
	userId?: number;
	emailAddress?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	supervisor?: SupervisorLibraryDto;
	students?: Array<StudentLibraryDto> | null;
	userPermissions?: Array<SignInUserPermissionDto> | null;
}
export interface SignInUserPermissionDto {
	userId?: number;
	permissionId?: number;
	permissionType?: PermissionType;
	departmentsIds?: Array<number> | null;
	isGeneral?: boolean;
}
export interface SignInWithCasProviderCommand {
	ticket?: string | null;
	// prividerName is a readonly constant and cannot be set. This is a Swagger bug.
	// providerName?: string | null;
}
export interface SignInWithCasProviderResponse extends BaseResponse {
	user?: SignInUserDto;
	jwtToken?: string | null;
	refreshAuthToken?: string | null;
	hasStudentPaperAssigned?: boolean;
}
export interface SignInWithPasswordCommand {
	emailAddress?: string | null;
	password?: string | null;
}
export interface SignInWithPasswordResponse extends BaseResponse {
	user?: SignInUserDto;
	jwtToken?: string | null;
	refreshAuthToken?: string | null;
	hasStudentPaperAssigned?: boolean;
}
export interface SignOutUserQuery {
	refreshAuthToken?: string | null;
}
export type SignOutUserResponse = BaseResponse;
export interface StudentLibraryDto {
	studentId?: number;
	major?: MajorLibraryDto;
}
export interface SupervisorLibraryDto {
	supervisorId?: number;
}
export type VerifyResetPasswordTokenResponse = BaseResponse;
export enum AccountTypeEnum {
	INTERNAL = 0,
	CAS = 1,
}
export interface AddAcademicDegreeCommand {
	name?: string | null;
}
export interface AddAcademicDegreeResponse extends BaseResponse {
	createdAcademicDegree?: AcademicDegreeDto;
}
export interface AddDepartmentCommand {
	name?: string | null;
	shortName?: string | null;
}
export interface AddDepartmentResponse extends BaseResponse {
	createdDepartment?: DepartmentDto;
}
export interface AddMajorCommand {
	name?: string | null;
	shortName?: string | null;
	majorDegreeType?: MajorDegreeType;
}
export interface AddMajorResponse extends BaseResponse {
	createdMajor?: ManagementMajorDto;
}
export interface AddOrUpdateLastAnnouncementCommand {
	polishText?: string | null;
	englishText?: string | null;
}
export interface AddOrUpdateLastAnnouncementResponse extends BaseResponse {
	lastAnnouncement?: AnnouncementDto;
}
export type ArchiveSystemCommand = Record<string, never>;
export type ArchiveSystemResponse = BaseResponse;
export interface BrowseOtherUserDto {
	userId?: number;
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	department?: DepartmentDto;
	userPermissions?: Array<UserPermissionDto> | null;
}
export interface BrowseOtherUsersResponse extends BaseResponse {
	otherUsers?: Array<BrowseOtherUserDto> | null;
}
export interface ManagementBrowsePapersResponse extends BaseResponse {
	papers?: Array<ManagementPaperDto> | null;
}
export enum ManagementBrowsePapersSortType {
	SUPERVISOR_LAST_NAME_ASC = 0,
	SUPERVISOR_LAST_NAME_DESC = 1,
}
export interface BrowseStudentUserDto {
	userId?: number;
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	students?: Array<ManagementStudentDto> | null;
	userPermissions?: Array<UserPermissionDto> | null;
}
export interface BrowseStudentsResponse extends BaseResponse {
	students?: Array<BrowseStudentUserDto> | null;
}

export interface BrowsePapersQuery {
	majorsIds?: number[];
	departmentsIds: number[];
	status?: PaperStatus;
	supervisorLastName?: string;
	studentLastName?: string;
	sortType?: ManagementBrowsePapersSortType;
	pageNumber?: number;
	pageSize?: number;
}

export interface BrowseStudentsQuery {
	albumNumber?: number;
	majorsIds?: number[];
	emailAddress?: string;
	firstName?: string;
	lastName?: string;
	sortType?: number;
	permissionTypes?: number[];
	pageNumber?: number;
	pageSize?: number;
}

export interface BrowseSupervisorsQuery {
	// WARNING: departmentsIds are required, because otherwise validation fails
	departmentsIds: number[];
	emailAddress?: string;
	firstName?: string;
	lastName?: string;
	sortType?: number;
	permissionTypes?: number[];
	pageNumber?: number;
	pageSize?: number;
}

export interface BrowseOtherUsersQuery {
	// WARNING: departmentsIds are required, because otherwise validation fails
	departmentsIds: number[];
	emailAddress?: string;
	firstName?: string;
	lastName?: string;
	sortType?: number;
	permissionTypes?: number[];
	pageNumber?: number;
	pageSize?: number;
}

export interface BrowseSupervisorUserDto {
	userId?: number;
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	supervisor?: ManagementSupervisorDto;
	userPermissions?: Array<UserPermissionDto> | null;
}
export interface BrowseSupervisorsResponse extends BaseResponse {
	supervisors?: Array<BrowseSupervisorUserDto> | null;
}
export interface ChangePaperStatusCommand {
	paperId?: number;
	status?: PaperStatus;
}
export interface ChangePaperStatusResponse extends BaseResponse {
	paperId?: number;
	currentStatus?: PaperStatus;
}
export interface ChangeYearConfigurationCommand {
	yearConfigurationType?: YearConfigurationType;
}
export type ChangeYearConfigurationResponse = BaseResponse;
export interface CreateStudentUserCommand {
	albumNumber?: number;
	majorId?: number;
	userId?: number;
}
export interface CreateStudentUserResponse extends BaseResponse {
	student?: ManagementStudentDto;
}
export interface CreateSupervisorUserCommand {
	userId?: number;
	// WARNING: departmentsId is required, because otherwise validation fails
	departmentId: number;
	academicDegreeId?: number | null;
}
export interface CreateSupervisorUserResponse extends BaseResponse {
	supervisor?: ManagementSupervisorDto;
}
export interface CreateUserCommand {
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	// WARNING: departmentsId is required, because otherwise validation fails
	departmentId: number | null;
	accountType?: AccountTypeEnum;
}
export interface CreateUserResponse extends BaseResponse {
	user?: UserDetailsDto;
}
export type DeleteAcademicDegreeResponse = BaseResponse;
export type DeleteDepartmentResponse = BaseResponse;
export type DeleteLastAnnouncementCommand = Record<string, never>;
export type DeleteLastAnnouncementResponse = BaseResponse;
export type DeleteMajorResponse = BaseResponse;
export interface DeletePublicFilesResponse extends BaseResponse {
	deletedFilesIds?: Array<number> | null;
}
export interface DeleteStudentPaperResponse extends BaseResponse {
	paperId?: number;
	studentId?: number;
}
export type DeleteStudentUserResponse = BaseResponse;
export type DeleteSupervisorUserResponse = BaseResponse;
export type DeleteTagResponse = BaseResponse;
export type DeleteUserResponse = BaseResponse;
export interface GenerateReportCommand {
	reportType?: ReportType;
	departmentId?: number | null;
	majorId?: number | null;
	paperStatus?: PaperStatus;
	supervisorId?: number | null;
	studentId?: number | null;
}
export interface GenerateReportResponse extends BaseResponse {
	reportFileModel?: ResourceFileModel;
}
export interface GenerateUserJwtTokenForPermittedUserResponse extends BaseResponse {
	// DOES NOT EXTEND BaseResponse (now it does) and isn't a response
	// content is the same as SignInPasswordResponse
	user?: SignInUserDto;
	jwtToken?: string | null;
	refreshAuthToken?: string | null;
	hasStudentPaperAssigned?: boolean;
}
export type GetAcademicDegreesQuery = Record<string, never>;
export interface GetAcademicDegreesResponse extends BaseResponse {
	academicDegrees?: Array<AcademicDegreeDto> | null;
}
export type GetAllowedReportTypesDictionaryQuery = Record<string, never>;
export interface GetAllowedReportTypesDictionaryResponse extends BaseResponse {
	allowedReportTypesDictionary?: Partial<
		Record<'General' | 'Personal' | 'Department' | 'Student', Array<number>>
	> | null;
}
export type GetDepartmentsQuery = Record<string, never>;
export interface GetDepartmentsResponse extends BaseResponse {
	departments?: Array<DepartmentDto> | null;
}
export interface GetLastReportFileForSpecifiedYearResponse extends BaseResponse {
	lastReportFileModel?: ResourceFileModel;
}
export type GetMajorsQuery = Record<string, never>;
export interface GetMajorsResponse extends BaseResponse {
	majors?: Array<ManagementMajorDto> | null;
}
export type GetPermissionsQuery = Record<string, never>;
export interface GetPermissionsResponse extends BaseResponse {
	permissions?: Array<PermissionDto> | null;
}
export interface GetPermittedCurrentUserDepartmentsResponse extends BaseResponse {
	permittedDepartments?: Array<DepartmentDto> | null;
	permissionTypes?: Array<PermissionType> | null;
}
export type GetTagsQuery = Record<string, never>;
export interface GetTagsResponse extends BaseResponse {
	tags?: Array<TagDocumentDto> | null;
}
export interface GetUserDetailsResponse extends BaseResponse {
	user?: UserDetailsDto;
}
export type GetYearConfigurationPhaseQuery = Record<string, never>;
export interface GetYearConfigurationPhaseResponse extends BaseResponse {
	phaseType?: YearConfigurationType;
}
export interface GrantPermissionCommand {
	permissionType?: PermissionType;
	// WARNING: departmentsIds are required, because otherwise validation fails
	departmentsIds: Array<number>;
	userId?: number;
}
export interface GrantPermissionResponse extends BaseResponse {
	currentUserPermissions?: Array<UserPermissionDto> | null;
}
export interface ImportMajorBody {
	dataImportFile?: Blob;
}
export interface ImportMajorDataResponse extends BaseResponse {
	importedMajors?: Array<ManagementMajorDto> | null;
}
export interface ImportStudentBody {
	dataImportFile?: Blob;
}
export type ImportStudentDataResponse = BaseResponse;
export interface ImportSupervisorBody {
	dataImportFile?: Blob;
}
export type ImportSupervisorDataResponse = BaseResponse;
export interface LoginAsOtherUserCommand {
	userId?: number;
}
export interface LoginAsOtherUserResponse extends BaseResponse {
	signedInUserData?: GenerateUserJwtTokenForPermittedUserResponse;
}
export interface ManagementMajorDto extends MajorDto {
	shortName?: string | null;
	majorDegreeType?: MajorDegreeType;
}
export interface ManagementPublicFileBody {
	files?: Array<Blob>;
}
export interface MergePermissionRecord {
	departmentsIdsToGrant?: Array<number> | null;
	departmentsIdsToRevoke?: Array<number> | null;
}
export interface MergePermissionsCommand {
	/**
	 * A "{@link PermissionType}" -> "allowed departments' ids" map.
	 * This has been manually typed, as Swagger does not restrict keys.
	 */
	mergePermissionsDictionary?: Partial<Record<PermissionType, MergePermissionRecord>> | null;
	userId?: number;
}
export type MergePermissionsResponse = BaseResponse;
export enum ReceiversOptionType {
	ALL_SUPERVISORS = 0,
	ALL_REVIEWERS = 1,
	STUDENTS_WITH_PAPER_ASSIGNED = 2,
	STUDENTS_WITHOUT_PAPER_ASSIGNED = 3,
}
export enum ReportType {
	GENERAL = 0,
	DEPARTMENT = 1,
	PERSONAL = 2,
	// Student reports are disabled
	// STUDENT = 3,
}
export interface ResourceFileModel {
	path?: string | null;
	contentType?: string | null;
}
export interface RevokePermissionResponse extends BaseResponse {
	currentUserPermissions?: Array<UserPermissionDto> | null;
}
export interface SendManualEmailCommand {
	subject?: string | null;
	body?: string | null;
	receiversEmails?: Array<string> | null;
	receiversOptionsDictionary?: Partial<Record<ReceiversOptionType, boolean>> | null;
}
export type SendManualEmailResponse = BaseResponse;
export interface ManagementStudentDto {
	studentId?: number;
	albumNumber?: number;
	major?: ManagementMajorDto;
}
export interface ManagementSupervisorAcademicDegreeDto extends SupervisorAcademicDegreeDto {
	academicDegreeId?: number;
}
export interface ManagementSupervisorDto {
	supervisorId?: number;
	departmentId?: number | null;
	department?: DepartmentDto;
	supervisorAcademicDegrees?: Array<ManagementSupervisorAcademicDegreeDto> | null;
}
export interface TagDocumentDto {
	id?: string | null;
	content?: string | null;
	creatorId?: number;
}
export interface UpdateAcademicDegreeCommand {
	academicDegreeId?: number;
	name?: string | null;
}
export interface UpdateAdminInfoCommand {
	adminInfo?: string | null;
}
export type UpdateAdminInfoResponse = BaseResponse;
export interface UpdateAcademicDegreeResponse extends BaseResponse {
	updatedAcademicDegree?: AcademicDegreeDto;
}
export interface UpdateDepartmentCommand {
	departmentId?: number;
	name?: string | null;
	shortName?: string | null;
}
export interface UpdateDepartmentResponse extends BaseResponse {
	updatedDepartment?: DepartmentDto;
}
export interface UpdateMajorCommand {
	majorId?: number;
	name?: string | null;
	shortName?: string | null;
	majorDegreeType?: MajorDegreeType;
}
export interface UpdateMajorResponse extends BaseResponse {
	updatedMajor?: ManagementMajorDto;
}
export interface UpdateStudentUserCommand {
	userId?: number;
	studentId?: number;
	albumNumber?: number;
	majorId?: number;
}
export interface UpdateStudentUserResponse extends BaseResponse {
	student?: ManagementStudentDto;
}
export interface UpdateSupervisorUserCommand {
	supervisorId?: number;
	userId?: number;
	// WARNING: departmentsIds are required, because otherwise validation fails
	departmentId: number;
	academicDegreeId?: number | null;
}
export interface UpdateSupervisorUserResponse extends BaseResponse {
	supervisor?: ManagementSupervisorDto;
}
export interface UpdateUserCommand {
	userId?: number;
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	departmentId?: number | null;
}
export interface UpdateUserResponse extends BaseResponse {
	user?: UserDetailsDto;
}
export interface UploadPublicFilesResponse extends BaseResponse {
	currentPublicFiles?: PublicFilesDto;
}
export interface UpsertTagCommand {
	content?: string | null;
}
export interface UpsertTagResponse extends BaseResponse {
	tag?: TagDocumentDto;
	isUpdated?: boolean;
}
export interface UserDetailsDto {
	userId?: number;
	firstName?: string | null;
	lastName?: string | null;
	emailAddress?: string | null;
	departmentId?: number | null;
	department?: DepartmentDto;
	supervisor?: ManagementSupervisorDto;
	students?: Array<ManagementStudentDto> | null;
	userPermissions?: Array<UserPermissionDto> | null;
}
export enum YearConfigurationType {
	ADMIN_PHASE = 0,
	SUPERVISOR_PHASE = 1,
	SUBMISSION_PHASE = 2,
	STUDENT_PHASE = 3,
}
