import { useTranslation } from 'react-i18next';
import { StudentPaperDto } from '../../../services/models';
import { SingleStudent } from './SingleStudent';

export default function PaperDetailsAssignedStudents(
	props: PaperDetailsAssignedStudentsProps
): JSX.Element | null {
	const { studentPapers, showEmail, tableMode, topic = '' } = props;
	const { t } = useTranslation();
	const assignedStudents = studentPapers.filter((s) => s.isStudentAccepted === true);
	if (assignedStudents.length === 1) {
		const student = assignedStudents[0];
		return (
			<span className={`${!tableMode && 'mb-3'}`}>
				{!tableMode && <b>{t('assigned_student')}: </b>}
				<SingleStudent student={student} showEmail={showEmail} topic={topic} />
			</span>
		);
	} else if (assignedStudents.length > 1) {
		return (
			<span className={`${!tableMode && 'mb-3'}`}>
				{!tableMode && <b>{t('assigned_students')}:</b>}
				<ul>
					{assignedStudents.map((st) => (
						<li key={st.studentId}>
							<SingleStudent student={st} showEmail={showEmail} topic={topic} />
						</li>
					))}
				</ul>
			</span>
		);
	} else {
		return null;
	}
}

interface NoEmail {
	studentPapers: StudentPaperDto[];
	topic?: undefined | string;
	showEmail?: undefined | false;
	tableMode?: boolean;
}

interface WithEmail {
	studentPapers: StudentPaperDto[];
	topic: string;
	showEmail: true;
	tableMode?: boolean;
}

export type PaperDetailsAssignedStudentsProps = NoEmail | WithEmail;
