import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../services/hooks';
import { PaperDto } from '../../services/models';
import { getPaperDetails, useErrorHandler } from '../../services/network';
import GoBackButton from '../GoBackButton/GoBackButton';
import EditPaper from './EditPaper/EditPaper';

export default function UpdatePaper({ showHeader }: UpdatePaperProps) {
	const [paper, setPaper] = useState<PaperDto | undefined>(undefined);
	const user = useUser();
	const { paperId: pid } = useParams();
	const paperId = Number.parseInt(pid ?? '');
	const networkErrorHandler = useErrorHandler();
	useEffect(() => {
		getPaperDetails(paperId)
			.then((paperDetails) => {
				setPaper(paperDetails?.paper);
			})
			.catch(networkErrorHandler);
	}, [paperId, networkErrorHandler]);
	if (!user) {
		return <></>;
	}
	const component = paper ? (
		<div className="content-wrapper">
			<GoBackButton />
			<EditPaper
				create={false}
				user={user}
				isStudent={user.studentId !== null}
				paper={paper}
				paperId={paperId}
				showHeader={showHeader}
			/>
		</div>
	) : (
		<div>Loading</div>
	);
	return component;
}

export interface UpdatePaperProps {
	showHeader?: boolean;
}
