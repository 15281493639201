export default {
	minPasswordLength: 5,
	maxPasswordLength: 30,
	maxTitleLength: 250,
	maxDescriptionLength: 2500,
	defaultMaxStudents: 1,
	maxAcademicDegreeLength: 200,
	maxLimitStudents: 3,
	maxFileSizeInBytes: 5242880, // 5 MiB
	maxFilesCount: 10,
	maxMajorLength: 200,
	maxMajorShortLength: 200,
	maxDepartmentLength: 200,
	maxDepartmentShortLength: 200,
	maxEmailLength: 320,
	maxNameLength: 50, // max first and last name length
	emailSubjectLength: 250,
	emailContentLength: 10000,
	cacheRefreshDelay: 100, // time (in ms) to wait after import/create to request new data
	defaultPageSize: 50,
} as const;
