import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './GoBackButton.scss';

export interface GoBackButtonProps {
	customHistory?: boolean;
}

export default function GoBackButton(props: GoBackButtonProps) {
	const { customHistory } = props;
	const navigate = useNavigate();
	const { state } = useLocation();
	const { t } = useTranslation();
	const backStateExists =
		typeof state === 'object' &&
		state !== null &&
		'backNum' in state &&
		typeof (state as Record<string, unknown>)['backNum'] === 'number';
	return (
		<div className="go-back-button mt-1">
			<span
				onClick={() =>
					navigate(
						customHistory && backStateExists
							? -(state as { backNum: number }).backNum
							: -1
					)
				}
			>
				🡐 {t('go_back')}
			</span>
		</div>
	);
}
