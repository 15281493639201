import {
	EmailOptOutType,
	GetUserPreferencesResponse,
	LanguageModeType,
	SaveEmailPreferencesCommand,
	SaveEmailPreferencesResponse,
	SaveUserPreferencesCommand,
	SaveUserPreferencesResponse,
} from '../models';
import { getRequest, putRequest } from './base';

export async function getUserPreferences(): Promise<GetUserPreferencesResponse> {
	const response = await getRequest<GetUserPreferencesResponse>('/core/user/preferences');
	return response.data;
}

export async function saveUserPreferences(languageModeType: LanguageModeType) {
	const response = await putRequest<SaveUserPreferencesResponse, SaveUserPreferencesCommand>(
		'/core/user/preferences',
		{ languageModeType }
	);
	return response.data;
}

export async function saveEmailPreferences(emailOptOutType: EmailOptOutType) {
	const response = await putRequest<SaveEmailPreferencesResponse, SaveEmailPreferencesCommand>(
		'/core/user/preferences/email',
		{ emailOptOutType }
	);
	return response.data;
}
