import { SupervisorDto } from '../services/models';

export function getFileName(path: string) {
	return path.substring(path.lastIndexOf('/') + 1);
}

export function renderSupervisor(supervisor: SupervisorDto, showDept = false): string {
	const {
		supervisorAcademicDegrees = [],
		firstName = '',
		lastName = '',
		department,
	} = supervisor;
	const degree = (supervisorAcademicDegrees?.map((deg) => deg.academicDegree?.name) ?? []).join(
		' '
	);
	if (showDept) {
		const dept = department?.name
			? `[${department.name
					.split(' ')
					.map((l) => (l.length === 1 ? l : l[0].toUpperCase()))
					.join('')} - ${department.name}]`
			: '';

		return `${degree} ${lastName} ${firstName} ${dept}`;
	}
	return `${degree} ${lastName} ${firstName}`;
}

export function formatDepartment(dep: {
	shortName?: string | undefined | null;
	name?: string | undefined | null;
}) {
	return `[${dep.shortName}] ${dep.name}`;
}
