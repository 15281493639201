import { MajorDto, SignInUserDto, SignInUserPermissionDto } from '../../services/models';

export default class User {
	userId: number;
	emailAddress: string;
	firstName: string;
	lastName: string;
	supervisorId: number | null;
	studentId: number | null;
	major: Required<MajorDto> | null;
	userPermissions: SignInUserPermissionDto[];
	hasStudentPaperAssigned: boolean;
	/** Whether the user is spoofed by admin */
	byAdmin?: boolean;

	constructor(
		user: SignInUserDto,
		hasStudentPaperAssigned?: boolean,
		studentId?: number,
		major?: Required<MajorDto> | null,
		byAdmin?: boolean
	) {
		if (!user.userId || !user.emailAddress || !user.firstName || !user.lastName) {
			throw new Error('Provided user object is invalid');
		}
		this.userId = user.userId;
		this.emailAddress = user.emailAddress;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		// Don't set supervisorId on students
		if (studentId !== undefined && studentId !== null) {
			this.supervisorId = null;
		} else if (user.supervisor !== undefined && user.supervisor !== null) {
			if (typeof user.supervisor?.supervisorId !== 'number')
				throw new Error('Invalid user data received');
			this.supervisorId = user.supervisor.supervisorId;
		} else {
			// User is neither student nor supervisor, probably impossible
			this.supervisorId = null;
		}
		this.studentId = studentId ?? null;
		this.major = major ?? null;
		this.userPermissions = user.userPermissions ?? [];
		this.hasStudentPaperAssigned = hasStudentPaperAssigned ?? false;
		this.byAdmin = byAdmin;
	}

	static fromLocalStorage(): User | undefined {
		const userData = localStorage.getItem('user');
		if (!userData) return undefined;
		const parsedData = JSON.parse(userData);
		const {
			userId,
			emailAddress,
			firstName,
			lastName,
			supervisorId,
			studentId,
			major,
			userPermissions,
			hasStudentPaperAssigned,
			byAdmin,
		} = parsedData;
		if (
			typeof userId === 'number' &&
			typeof emailAddress === 'string' &&
			typeof firstName === 'string' &&
			typeof lastName === 'string' &&
			(typeof supervisorId === 'number' || supervisorId === null) &&
			(typeof studentId === 'number' || studentId === null) &&
			(major === null ||
				(typeof major?.majorId === 'number' &&
					(typeof major?.name === 'string' || major?.name === null))) &&
			Array.isArray(userPermissions) &&
			typeof hasStudentPaperAssigned === 'boolean'
		) {
			try {
				const supervisor = supervisorId !== null ? { supervisorId } : undefined;
				return new User(
					{ userId, emailAddress, firstName, lastName, supervisor, userPermissions },
					hasStudentPaperAssigned,
					studentId,
					major as Required<MajorDto> | null,
					byAdmin === true
				);
			} catch (error) {
				return undefined;
			}
		}
		return undefined;
	}
}
