import { TFunction } from 'i18next';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import { LoginRequired, NetworkError, ServerError, UnexpectedResponse } from '.';
import User from '../../utils/localStorageClasses/User';
import { assertUnreachable } from '../../utils/typeGuards';
import { UserContext } from '../contexts';
import { ErrorCode } from '../models';
import { logoutCredentialCleanup } from './auth';
import { QuietError, SessionExpired } from './base';

function handleError(
	navigate: NavigateFunction,
	t: TFunction<'translation', undefined>,
	setUser: (user: User | undefined) => void,
	error?: Error | null
): void {
	if (!error) return;
	console.error(error);
	let message: string | null = '';
	let redirecTarget: string | null = null;
	let rethrow = false;
	if (error instanceof ServerError) {
		switch (error.errorCode) {
			case ErrorCode.UNHANDLED_EXCEPTION:
			case ErrorCode.SERVER_ERROR:
			case ErrorCode.DATABASE_OPERATION_FAILED:
			case ErrorCode.SERVICE_UNAVAILABLE:
				message = t('server_error_occurred');
				break;

			case ErrorCode.ENTITY_NOT_FOUND:
				message = t('not_found_msg', {
					message: error.message ?? t('resource_not_found'),
				});
				redirecTarget = '/404';
				break;

			case ErrorCode.INVALID_CREDENTIALS:
				// Login only
				rethrow = true;
				break;

			case ErrorCode.FILE_OPERATION_FAILED:
				// Only while deleting files
				message = t('file_operation_failed', { message: error.message });
				break;

			case ErrorCode.INSUFFICIENT_PERMISSIONS_EXCEPTION:
				message = t('you_have_no_permissions');
				redirecTarget = '/';
				break;

			case ErrorCode.RESOURCE_EXPIRED:
				// Reset password (and verify reset password); needs further handling - RETHROW
				message = t('link_expired');
				rethrow = true;
				break;

			case ErrorCode.VALIDATION_FAILED:
			case ErrorCode.ENTITY_VALIDATION_FAILED:
				// Needs further handling - RETHROW
				message = t('invalid_data_detected') ?? '';
				if (error.validations) {
					if (Object.keys(error.validations).length) message += ': ';
					for (const key in error.validations) {
						if (Object.prototype.hasOwnProperty.call(error.validations, key)) {
							const errorMessage = error.validations[key];
							message += errorMessage.join('; ') + '; ';
						}
					}
					message = message.substring(0, message.length - 2);
				} else {
					message += ': ' + error.message;
				}
				rethrow = true;
				break;

			case ErrorCode.DUPLICATE_ALREADY_EXISTS:
				// Can happen when a student selects a topic they have already selected,
				// or when a student is accepted, but they were already accepted in another topic.
				// Because of different possible meanings it must be handled on the higher level - RETHROW
				// More common in management, where creating users/departments/majors etc. can fail with this code
				rethrow = true;
				message = null;
				break;

			case ErrorCode.RATE_LIMIT_EXCEEDED:
				message = t('not_so_fast_rate_limit');
				break;

			case ErrorCode.METHOD_NOT_ALLOWED:
				message = t('method_not_allowed');
				break;

			case undefined:
			case null:
				message = t('unknown_error_occurred');
				break;

			default:
				message = t('unknown_error_occurred');
				assertUnreachable(error.errorCode);
		}
	} else if (error instanceof UnexpectedResponse) {
		message = t('unexpected_response');
	} else if (error instanceof LoginRequired) {
		message = t('log_in_to_proceed');
		redirecTarget = '/auth';
	} else if (error instanceof SessionExpired) {
		message = t('session_expired');
		redirecTarget = '/auth';
		// Delete remaining user data
		logoutCredentialCleanup();
		setUser(undefined);
	} else if (error instanceof NetworkError) {
		message = t('could_not_connect_details', { message: error.message });
	} else if (error instanceof QuietError) {
		message = null; // Ignore this type of errors
	} else {
		message = `${t('error_occurred')}: ${error.name}`;
	}
	if (message) toast.error(message);
	if (redirecTarget) {
		navigate(redirecTarget, { state: { errorMessage: error.message } });
	} else if (rethrow && error instanceof Error) {
		throw error;
	}
}

/**
 * Default error handler handles most of the errors which can occur.
 * If further handling is required it rethrows the error.\
 * Following errors are rethrown:
 *
 * - {@link ServerError} if its `errorCode` is one of the following:
 *   - {@link ErrorCode.INVALID_CREDENTIALS}, may happen only while logging in
 *   - {@link ErrorCode.RESOURCE_EXPIRED}, AFAIK it is specific to password reset link/token
 *   - {@link ErrorCode.VALIDATION_FAILED}
 *   - {@link ErrorCode.ENTITY_VALIDATION_FAILED}
 *   - {@link ErrorCode.DUPLICATE_ALREADY_EXISTS}, for details see a comment
 * in `errorHandler.ts` in case matching this error code (selecting a topic and accepting a student)
 */
export function useErrorHandler(): ErrorHandler {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { setUser } = useContext(UserContext);
	return useCallback(
		(error?: Error | null | undefined) => handleError(navigate, t, setUser, error),
		[navigate, t, setUser]
	);
}

export function basicDuplicateHandler(e: unknown, t: TFunction) {
	if (e instanceof ServerError && e.errorCode === ErrorCode.DUPLICATE_ALREADY_EXISTS) {
		toast.error(t('duplicate_conflict', { message: e.message }));
	} else {
		throw e;
	}
}

export type ErrorHandler = (error?: Error | null | undefined) => void;
