import { PermissionType, SignInUserPermissionDto, UserPermissionDto } from '../services/models';

export const ADMIN_MANAGEMENT = [
	PermissionType.MANAGE_PERMISSIONS,
	PermissionType.MANAGE_ACADEMIC_YEAR,
	PermissionType.LOGIN_AS_USER,
	PermissionType.MANAGE_USERS,
	PermissionType.MANAGE_DICTIONARY_DATA,
	PermissionType.MANAGE_STUDENTS,
	PermissionType.MANAGE_TAGS,
	PermissionType.MANAGE_ANNOUNCEMENTS,
	PermissionType.READ_USERS,
	PermissionType.READ_STUDENTS,
	PermissionType.IMPORT_DATA,
	PermissionType.SEND_MANUAL_EMAIL,
	PermissionType.MANAGE_PAPERS,
	PermissionType.MANAGE_PUBLIC_FILES,
	PermissionType.ARCHIVE_SYSTEM,
];

/**
 * Checks if user has any of the required permissions. Returns departments with **any** of the permissions,
 * because that's how they are verified on backend.
 * @param usersPermissions Array of user permissions received after the user has logged in
 * @param wantedPermissions Permissions required to access something
 * @returns `true` if any of required permissions has `isGeneral` flag, otherwise an array of allowed departmets (IDs)
 * or false if user doesn't have any of the required permissions
 */
export function hasPermissions(
	usersPermissions: SignInUserPermissionDto[] | undefined,
	wantedPermissions: PermissionType | PermissionType[]
): boolean | number[] {
	if (!Array.isArray(wantedPermissions)) wantedPermissions = [wantedPermissions];
	const matchingPermissions = usersPermissions?.filter((perm) =>
		(wantedPermissions as PermissionType[]).includes(perm.permissionType as PermissionType)
	);
	const anyIsGeneral = matchingPermissions?.some((perm) => perm.isGeneral);
	if (anyIsGeneral) return true;
	const departments = matchingPermissions?.flatMap((perm) =>
		Array.isArray(perm.departmentsIds) ? perm.departmentsIds : []
	);
	return departments?.length ? departments : false;
}

/**
 * Converts {@link UserPermissionDto} arrays to {@link SignInUserPermissionDto} arrays
 * @param userPermissions Array of {@link UserPermissionDto}
 * @returns An array of {@link SignInUserPermissionDto} compatible objects
 */
export function userPermissionToSignInCompat(
	userPermissions: UserPermissionDto[]
): SignInUserPermissionDto[] {
	return userPermissions.map((perm) => ({
		userId: perm.userId,
		permissionId: perm.permissionId,
		permissionType: perm.permission?.type,
		departmentsIds: perm.departmentsIds,
		isGeneral: perm.permission?.isGeneral,
	}));
}
