import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toast';
import { useUser } from '../../services/hooks';
import { PaperDto, PaperStatus, YearConfigurationType } from '../../services/models';
import { getAssignedAndCreatedPapers, useErrorHandler } from '../../services/network';
import { getYearConfiguration } from '../../services/network/AdminPanel/yearConfiguration';
import { renderSupervisor } from '../../utils/nameFormatting';
import UnifiedBrowser, { BrowserType } from '../Browser/UnifiedBrowser/UnifiedBrowser';
import LocallyFilteredPapers from '../LocallyFilteredPapers/LocallyFilteredPapers';
import PaperStatusDisplay from '../PaperStatusDisplay/PaperStatusDisplay';
import CreatePaper from '../Papers/CreatePaper';
import PhaseRestricted from '../PhaseRestricted/PhaseRestricted';
import './PapersManager.scss';

enum TabType {
	CREATE = 'create',
	BROWSER = 'browser',
	CREATED_PAPERS = 'created',
	ASSIGNED_PAPERS = 'assigned',
}

const smallScreenRule = '(max-width: 540px)';

export default function PapersManager(): JSX.Element {
	const user = useUser();
	const isStudent = typeof user?.studentId === 'number';
	const isSupervisor = typeof user?.supervisorId === 'number';
	const [searchParams, setSearchParams] = useSearchParams();
	const initTab = Object.values(TabType).includes(searchParams.get('tab') as TabType)
		? (searchParams.get('tab') as TabType)
		: user?.hasStudentPaperAssigned
		? TabType.CREATED_PAPERS
		: TabType.CREATE;
	const [render, setRender] = useState<TabType>(initTab);
	const [createdPapers, setCreatedPapers] = useState<Array<PaperDto>>();
	const [assignedPapers, setAssignedPapers] = useState<Array<PaperDto>>();
	const [smallScreen, setSmallScreen] = useState(window.matchMedia(smallScreenRule).matches);
	const [currentPhase, setCurrentPhase] = useState<YearConfigurationType | undefined | null>(
		null
	);
	const navigate = useNavigate();
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();
	const studentWithCreatedPaper = isStudent && !!createdPapers?.length;
	const creationForbidden = studentWithCreatedPaper || (user?.hasStudentPaperAssigned ?? false);

	useEffect(() => {
		const matchMedia = window.matchMedia(smallScreenRule);
		const eventHandler = (e: MediaQueryListEvent) => setSmallScreen(e.matches);
		try {
			matchMedia.addEventListener('change', eventHandler);
		} catch (e) {
			if (e instanceof TypeError) {
				matchMedia.addListener(eventHandler);
			}
		}
		return () => {
			try {
				matchMedia.removeEventListener('change', eventHandler);
			} catch (e) {
				if (e instanceof TypeError) {
					matchMedia.removeListener(eventHandler);
				}
			}
		};
	}, [setSmallScreen]);

	useEffect(() => {
		getAssignedAndCreatedPapers()
			.then((response) => {
				if (response.isSucceeded) {
					setAssignedPapers(
						response.assignedPapers?.filter(
							({ paperId }) =>
								typeof paperId === 'number' &&
								!response.createdPapers
									?.map((paper) => paper.paperId)
									.includes(paperId)
						) ?? []
					);
					setCreatedPapers(response.createdPapers ?? []);
				}
			})
			.catch(networkErrorHandler);
	}, [networkErrorHandler]);

	useEffect(() => {
		if (creationForbidden && render === TabType.CREATE) {
			setRender(TabType.CREATED_PAPERS);
			setSearchParams({ tab: TabType.CREATED_PAPERS }, { replace: true });
		}
	}, [creationForbidden, render, searchParams, setSearchParams]);

	useEffect(() => {
		const tab = searchParams.get('tab') as TabType;
		if (Object.values(TabType).includes(tab) && render !== tab) setRender(tab);
	}, [render, searchParams]);

	useEffect(() => {
		getYearConfiguration()
			.then((r) => setCurrentPhase(r.phaseType))
			.catch(networkErrorHandler)
			.catch((e) => {
				toast.error(t('error_occurred_refresh'));
				console.error(e);
			});
	}, [networkErrorHandler, t]);

	if (!user) return <></>;

	const displayCreatedPapers = createdPapers?.map((item) => (
		<tr
			key={item.paperId}
			className={
				isSupervisor &&
				item.status === PaperStatus.APPROVED &&
				item.studentPapers?.filter((sp) => !sp.isStudentAccepted).length
					? 'special-row'
					: ''
			}
		>
			<td className={smallScreen ? 'c-12' : undefined}>
				<p>
					{item.paperDetails?.polishTitle} /<br />
					{item.paperDetails?.englishTitle}
				</p>
			</td>
			{!smallScreen && (
				<>
					<td>
						<p>
							<PaperStatusDisplay paper={item} />
						</p>
					</td>
					<td>
						<p className="c-1">{item.paperDetails?.maxStudents}</p>
					</td>
					{isSupervisor && (
						<>
							<td>
								<p>
									{item.studentPapers
										?.filter((st) => st.isStudentAccepted)
										.map((st) => `${st.firstName} ${st.lastName}`)
										.join(', ')}
								</p>
							</td>
							<td>
								<p>{item.majorPapers?.map((m) => m.majorName).join(', ')}</p>
							</td>
						</>
					)}
					<td>
						<p>{item.tags?.map((el) => el.content).join(', ')}</p>
					</td>
					<td>
						<p>{new Date(item.createdOn ?? 0).toLocaleString()}</p>
					</td>
				</>
			)}
			<PhaseRestricted cachedPhase={currentPhase} hide>
				<td className={smallScreen ? 'c-12 flex flex-column boxcell' : ''}>
					<button className="button-1" onClick={() => navigate(`/paper/${item.paperId}`)}>
						{t('open')}
					</button>
				</td>
			</PhaseRestricted>
		</tr>
	));

	const displayAssignedPapers = assignedPapers?.map((item) => (
		<tr key={item.paperId}>
			<td className={smallScreen ? 'c-12' : undefined}>
				<p>
					{item.paperDetails?.polishTitle} /<br />
					{item.paperDetails?.englishTitle}
				</p>
			</td>
			{!smallScreen && (
				<>
					<td>
						<p className="c-1">{item.paperDetails?.maxStudents}</p>
					</td>
					{isSupervisor ? (
						<>
							<td>
								<p>
									{item.studentPapers
										?.filter((st) => st.isStudentAccepted)
										.map((st) => `${st.firstName} ${st.lastName}`)
										.join(', ')}
								</p>
							</td>
							<td>
								<p>{item.majorPapers?.map((m) => m.majorName).join(', ')}</p>
							</td>
						</>
					) : (
						<>
							<td>
								<p>{renderSupervisor(item.supervisor ?? {})}</p>
							</td>
							<td>
								<p>
									<PaperStatusDisplay paper={item} />
								</p>
							</td>
						</>
					)}
					<td>
						<p>{item.tags?.map((el) => el.content).join(', ')}</p>
					</td>
					<td>
						<p>{new Date(item.createdOn ?? 0).toLocaleString()}</p>
					</td>
				</>
			)}
			<PhaseRestricted cachedPhase={currentPhase} hide>
				<td className={smallScreen ? 'c-12 flex flex-column boxcell' : 'flex flex-column'}>
					<button className="button-1" onClick={() => navigate(`/paper/${item.paperId}`)}>
						{t('open')}
					</button>
				</td>
			</PhaseRestricted>
		</tr>
	));

	const renderList = (variant: TabType) => {
		switch (variant) {
			case TabType.CREATED_PAPERS:
				return (
					<div className="c-12 mt-3 flex flex-middle flex-column overflow-x-auto">
						<div className="c-10 flex flex-middle mb-1">
							{createdPapers?.length ? (
								<table className="c-12 browser-table">
									<thead>
										<tr>
											<th>
												<p>
													{t('title')} / {t('title_en')}:
												</p>
											</th>
											{!smallScreen && (
												<>
													<th>
														<p>{t('paper_status')}:</p>
													</th>
													<th>
														<p>{t('students_number')}:</p>
													</th>
													{isSupervisor && (
														<>
															<th>
																<p>{t('students')}:</p>
															</th>
															<th>
																<p>{t('programme')}:</p>
															</th>
														</>
													)}
													<th>
														<p>{t('tags')}:</p>
													</th>
													<th>
														<p>{t('creation_date')}</p>
													</th>
												</>
											)}
										</tr>
									</thead>
									<tbody>{displayCreatedPapers}</tbody>
								</table>
							) : (
								<div className="c-12 flex flex-center mt-3">
									<h3 className="text-center">{t('no_papers_created')}</h3>
								</div>
							)}
						</div>
					</div>
				);
			case TabType.ASSIGNED_PAPERS:
				return (
					<div className="c-12 mt-3 flex flex-middle flex-column overflow-x-auto">
						<div className="c-10 flex flex-middle mb-1">
							{assignedPapers?.length ? (
								<table className="c-12 browser-table">
									<thead>
										<tr>
											<th>
												<p>
													{t('title')} / {t('title_en')}:
												</p>
											</th>
											{!smallScreen && (
												<>
													<th>
														<p>{t('students_number')}:&nbsp;</p>
													</th>
													{isSupervisor ? (
														<>
															<th>
																<p>{t('students')}:</p>
															</th>
															<th>
																<p>{t('programme')}:</p>
															</th>
														</>
													) : (
														<>
															<th>
																<p>{t('supervisor')}</p>
															</th>
															<th>
																<p>{t('paper_status')}:</p>
															</th>
														</>
													)}
													<th>
														<p>{t('tags')}:</p>
													</th>
													<th>
														<p>{t('creation_date')}</p>
													</th>
												</>
											)}
										</tr>
									</thead>
									<tbody>{displayAssignedPapers}</tbody>
								</table>
							) : (
								<div className="c-12 flex flex-center mt-3">
									<h3 className="text-center">{t('no_papers_assigned')}</h3>
								</div>
							)}
						</div>
					</div>
				);
			default:
				return <></>;
		}
	};

	const changeTab = (tab: TabType) => {
		setRender(tab);
		setSearchParams({ tab }, { replace: render !== TabType.BROWSER });
	};

	const PhaseRestrictedTabRedir = ({ changeTab }: { changeTab: (type: TabType) => void }) => {
		changeTab(TabType.CREATED_PAPERS);
		return <Navigate to={`#${TabType.CREATED_PAPERS}`} />;
	};
	function renderSwitch(tabType: TabType) {
		switch (tabType) {
			case TabType.CREATE:
				return (
					<PhaseRestricted
						cachedPhase={currentPhase}
						altError={<PhaseRestrictedTabRedir changeTab={changeTab} />}
					>
						<CreatePaper showHeader={false} />
					</PhaseRestricted>
				);
			case TabType.BROWSER:
				return (
					<PhaseRestricted
						cachedPhase={currentPhase}
						altError={<PhaseRestrictedTabRedir changeTab={changeTab} />}
					>
						<UnifiedBrowser showHeader={false} browserType={BrowserType.SUPERVISOR} />
					</PhaseRestricted>
				);
			case TabType.CREATED_PAPERS:
				return isSupervisor ? (
					<LocallyFilteredPapers papers={createdPapers} currentPhase={currentPhase} />
				) : (
					renderList(TabType.CREATED_PAPERS)
				);
			case TabType.ASSIGNED_PAPERS:
				return renderList(TabType.ASSIGNED_PAPERS);
			default:
				return <div>{t('something_went_wrong')}</div>;
		}
	}
	return (
		<div className="content-wrapper">
			<div className="scroll-content main-shadow bg-white">
				<div className="manager-header bg-white flex flex-middle">
					<div className="manager-navbar flex">
						{!creationForbidden && (
							<PhaseRestricted cachedPhase={currentPhase} hide>
								<button
									className={`manager-link ${
										render === TabType.CREATE ? 'manager-link-active' : ''
									}`}
									onClick={() => changeTab(TabType.CREATE)}
								>
									{t('create_topic')}
								</button>
							</PhaseRestricted>
						)}
						<button
							className={`manager-link ${
								render === TabType.CREATED_PAPERS ? 'manager-link-active' : ''
							}`}
							onClick={() => changeTab(TabType.CREATED_PAPERS)}
						>
							{t('created_papers')}
						</button>
						{isSupervisor && (
							<PhaseRestricted cachedPhase={currentPhase} hide>
								<button
									className={`manager-link ${
										render === TabType.BROWSER ? 'manager-link-active' : ''
									}`}
									onClick={() => changeTab(TabType.BROWSER)}
								>
									{t('papers_wo_supervisor')}
								</button>
							</PhaseRestricted>
						)}
						<button
							className={`manager-link manager-link-last ${
								render === TabType.ASSIGNED_PAPERS ? 'manager-link-active' : ''
							}`}
							onClick={() => changeTab(TabType.ASSIGNED_PAPERS)}
						>
							{t(isStudent ? 'assigned_papers' : 'papers_awaiting_acceptance')}
						</button>
					</div>
				</div>
				{renderSwitch(render)}
			</div>
		</div>
	);
}
