import './TableRow.scss';

interface TableRowProps {
	names: React.ReactNode[];
	actionHandler: () => void;
	buttonText: string;
	buttonClass?: string;
	buttonEnabled?: boolean;
	additionalButton?: React.ReactNode;
}

export default function TableRow(props: TableRowProps) {
	const { names, actionHandler, buttonText, buttonClass, buttonEnabled, additionalButton } =
		props;
	return (
		<tr>
			{names.map((el, index) => {
				return (
					<td key={index}>
						<p key={index}>{el}</p>
					</td>
				);
			})}
			<td>
				<button
					className={'table-btn-float ' + (buttonClass ? buttonClass : 'button-1')}
					onClick={actionHandler}
					disabled={!(buttonEnabled ?? true)}
				>
					{buttonText}
				</button>
				{additionalButton && <div className="mr-1 table-btn-float">{additionalButton}</div>}
			</td>
		</tr>
	);
}
