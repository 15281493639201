import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function NotFound(): JSX.Element {
	const { state } = useLocation() as { state: { errorMessage?: unknown } };
	const { t } = useTranslation();
	const message = typeof state?.errorMessage === 'string' ? state.errorMessage : '';
	return (
		<div className="content-wrapper">
			<div className="flex flex-center flex-middle max-height">
				<h2 className="mb-10">
					{t('page_not_found')}
					{message && <span>{message}</span>}
				</h2>
			</div>
		</div>
	);
}
