import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import * as Yup from 'yup';
import { UserContext } from '../../../services/contexts';
import { ErrorCode, MajorDto } from '../../../services/models';
import { login, ServerError, useErrorHandler } from '../../../services/network';
import { saveUserAndLogin } from '../../../services/userManagement';
import User from '../../../utils/localStorageClasses/User';

export default function LoginForm(props: { children: React.ReactNode }) {
	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		emailAddress: Yup.string().email(t('email_invalid')).required(t('email_required')),
		password: Yup.string().required(t('password_required')),
	});

	return (
		<Formik
			initialValues={{
				emailAddress: '',
				password: '',
			}}
			validationSchema={validationSchema}
			onSubmit={(data, helpers) => {
				login(data.emailAddress, data.password)
					.catch(networkErrorHandler)
					.catch((e) => {
						if (e instanceof ServerError) {
							if (e.errorCode === ErrorCode.INVALID_CREDENTIALS) {
								toast.error(t('email_or_password_invalid'));
								helpers.setErrors({
									emailAddress: t('email_or_password_invalid'),
									password: t('email_or_password_invalid'),
								});
								return;
							} else if (
								e.errorCode === ErrorCode.VALIDATION_FAILED &&
								e.validations
							) {
								for (const key in e.validations) {
									if (Object.prototype.hasOwnProperty.call(e.validations, key)) {
										const validationError = e.validations[key];
										const lowercaseKey = key.toLowerCase();
										if (lowercaseKey === 'emailaddress') {
											helpers.setFieldError(
												'emailAddress',
												`${t('email_invalid')}: ${validationError.join(
													'; '
												)}`
											);
										} else if (lowercaseKey === 'password') {
											helpers.setFieldError(
												'password',
												validationError.join('; ')
											);
										}
									}
								}
							}
						}
						throw e;
					})
					.then((response) => {
						if (response) {
							if (
								response.user?.supervisor === null &&
								(response.user.students?.length ?? 0) > 1
							) {
								return navigate('/chooseMajor', {
									state: { signInResponse: response },
								});
							}
							const { user, hasStudentPaperAssigned } = response;
							if (user) {
								const student =
									response.user?.students?.length === 1
										? response.user.students[0]
										: undefined;
								if (
									student &&
									(typeof student.major?.majorId !== 'number' ||
										typeof student.major.name !== 'string')
								) {
									throw new Error(t('error_could_not_log_in'));
								}
								saveUserAndLogin(
									setUser,
									navigate,
									new User(
										user,
										hasStudentPaperAssigned,
										student?.studentId,
										student?.major as Required<MajorDto>
									)
								);
							} else {
								throw new Error(t('error_could_not_log_in'));
							}
						}
					})
					.catch((error) => {
						toast.error(error instanceof Error ? error.message : t('unknown_error'));
					});
			}}
		>
			<Form>
				<div>
					<label className="h4" htmlFor="emailAddress">
						{t('email')}
					</label>
					<Field
						id="emailAddress"
						name="emailAddress"
						type="text"
						className="input-1 c-11"
					/>
					<div className="col-danger error-message">
						<ErrorMessage name="emailAddress" />
					</div>
				</div>

				<div>
					<label className="h4" htmlFor="password">
						{t('password')}
					</label>
					<Field id="password" name="password" type="password" className="input-1 c-11" />
					<div className="col-danger error-message">
						<ErrorMessage name="password" />
					</div>
				</div>
				<div className="c-12 flex flex-column flex-middle mt-5">
					<button type="submit" className="button-1">
						{t('sign_in')}
					</button>
					<Link to="/resetPassword" className="mt-1 col-black">
						<p>{t('forgot_password')}</p>
					</Link>
					{props.children}
				</div>
			</Form>
		</Formik>
	);
}
