import TableRow from '../TableRow/TableRow';

interface TableProps {
	header: string[];
	records: TableRecord[];
	buttonText: string;
	className?: string;
	buttonClass?: string;
}
export interface TableRecord {
	names: React.ReactNode[];
	actionHandler: () => void;
	buttonEnabled?: boolean;
	additionalButton?: React.ReactNode;
}

export default function Table(props: TableProps) {
	return (
		<div className="c-12 flex flex-middle flex-column overflow-x-auto">
			<table
				className={`c-11 browser-table ${
					props.className ? props.className : 'browser-table'
				}`}
			>
				<thead>
					<tr>
						{props.header.map((el) => {
							return (
								<th key={el}>
									<p key={el}>{el}</p>
								</th>
							);
						})}
					</tr>
				</thead>
				{props.records.map((el, index) => {
					return (
						<tbody key={index}>
							<TableRow
								key={index}
								names={el.names}
								actionHandler={el.actionHandler}
								buttonText={props.buttonText}
								buttonClass={props.buttonClass}
								buttonEnabled={el.buttonEnabled}
								additionalButton={el.additionalButton}
							/>
						</tbody>
					);
				})}
			</table>
		</div>
	);
}
