import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import { assignSupervisorToPaper, useErrorHandler } from '../../../services/network';
import PaperDetails from './PaperDetails';
import PaperDetailsAssignedStudents from './PaperDetailsAssignedStudents';
import { CommonSpecialisedPaperDetailsProps } from './PaperDetailsMain';

export default function PaperDetailsSupervisor(
	props: CommonSpecialisedPaperDetailsProps
): JSX.Element {
	const { paper, paperId } = props;
	const [enableButtons, setEnableButtons] = useState(true);
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	const assign = () => {
		setEnableButtons(false);
		assignSupervisorToPaper(paperId)
			.then(() => toast.success(t('assigned')))
			.catch((e) => {
				setEnableButtons(true);
				throw e;
			})
			.catch(networkErrorHandler)
			.catch((e) => {
				if (e instanceof Error) {
					toast.error(e.message);
				} else if (typeof e === 'string') {
					toast.error(e);
				}
			});
	};

	return (
		<div className="scroll-content scroll-content-back bg-white">
			<PaperDetails paper={paper} />
			<div className="mt-3 ml-5 ml-3-m ml-1-s mb-3 flex flex-wrap p">
				<PaperDetailsAssignedStudents
					studentPapers={paper.studentPapers ?? []}
					showEmail
					topic={paper.paperDetails?.polishTitle ?? ''}
				/>
			</div>
			{enableButtons && (
				<div className="mt-3 ml-5 ml-3-m ml-1-s flex flex-center-s">
					<button className="button-1 p-1" onClick={assign}>
						{t('become_supervisor')}
					</button>
				</div>
			)}
		</div>
	);
}
