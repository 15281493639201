import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import { useOutsideListener, useUser } from '../../services/hooks';
import { LanguageModeType } from '../../services/models';
import { getUserPreferences, saveUserPreferences, useErrorHandler } from '../../services/network';
import { assertUnreachable } from '../../utils/typeGuards';
import { ThemeName, ThemeProps } from '../../utils/userTheme';
import './Header.scss';

export default function Header(props: ThemeProps): JSX.Element {
	const { theme, setTheme } = props;
	const [expandedLang, setExpandedLang] = useState(false);
	const { t, i18n } = useTranslation();
	const langMenuRef = useRef(null);
	const user = useUser();
	const networkErrorHandler = useErrorHandler();
	const userExists = useMemo(() => user !== null, [user]);
	// initialize to false, so that preferences are checked on the first load
	const userExistsRef = useRef(false);

	// load user language preferences
	useEffect(() => {
		// update preferences only if the user has logged in
		if (userExists && !userExistsRef.current)
			getUserPreferences()
				.then((r) => {
					let tmpLang: 'en' | 'pl';
					const languageModeType = r.userPreferencesModel?.languageModeType;
					switch (languageModeType) {
						case LanguageModeType.POLISH:
							tmpLang = 'pl';
							break;
						case LanguageModeType.ENGLISH:
						case undefined:
							tmpLang = 'en';
							break;
						default:
							assertUnreachable(languageModeType);
					}
					if (!i18n.language.toLowerCase().startsWith(tmpLang))
						i18n.changeLanguage(tmpLang);
				})
				.catch(networkErrorHandler);
		// update the ref value
		userExistsRef.current = userExists;
	}, [i18n, networkErrorHandler, userExists]);

	useOutsideListener(langMenuRef, () => setExpandedLang(false));

	function setAllTheme(theme2: string): void {
		if (window.localStorage.getItem('theme') !== theme2) {
			window.localStorage.setItem('theme', theme2);
		} else {
			window.localStorage.setItem('theme', 'default');
		}

		setTheme((window.localStorage.getItem('theme') ?? 'default') as ThemeName);
	}

	const lang = i18n.resolvedLanguage.split('-')[0].toLowerCase();
	const toggleExpandedLang = () => setExpandedLang((exp) => !exp);
	const setLang = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		const selectedLang = e.currentTarget.dataset.lang;
		i18n.changeLanguage(selectedLang ?? 'en');
		setExpandedLang(false);
		saveUserPreferences(
			selectedLang === 'pl' ? LanguageModeType.POLISH : LanguageModeType.ENGLISH
		).catch(() => toast.warn(t('couldnt_save_lang')));
	};

	return (
		<div className="c-12 bg-white bg-midnightblue-m header">
			<div className="c-12 flex flex-middle max-height">
				<div className="flex flex-middle" style={{ marginLeft: 'auto', marginRight: '0' }}>
					{user && (
						<div className="mr-1 col-black col-white-4-m col-midnightblue-s">
							{user.firstName}&nbsp;{user.lastName}
						</div>
					)}
					<div className="navbar-menu-container mr-1" ref={langMenuRef}>
						<button
							aria-controls="language-menu"
							aria-expanded={expandedLang}
							className="navbar-menu-btn flex flex-center flex-middle"
							title="language"
							onClick={toggleExpandedLang}
							type="button"
						>
							{lang.toUpperCase()}
						</button>
						<div
							id="language-menu"
							role="menu"
							className={`navbar-list-container ${expandedLang ? '' : 'hidden'}`}
						>
							<ul role="none" className="navbar-list">
								<li role="none">
									<a
										role="menuitemradio"
										aria-selected={lang === 'pl'}
										data-lang="pl"
										onClick={setLang}
										tabIndex={0}
										title={t('polish')}
									>
										PL
									</a>
								</li>
								<li role="none">
									<a
										role="menuitemradio"
										aria-selected={lang !== 'pl'}
										data-lang="en"
										onClick={setLang}
										tabIndex={0}
										title={t('english')}
									>
										EN
									</a>
								</li>
							</ul>
						</div>
					</div>
					<label className="switch-contrast mr-1">
						<input
							type="checkbox"
							checked={theme === 'contrast'}
							onChange={() => setAllTheme('contrast')}
							aria-label={t('high_contrast_theme')}
							role="switch"
							aria-checked={theme === 'contrast' ? 'true' : 'false'}
						/>
						<span className="slider-contrast round"></span>
					</label>
					<label className="switch mr-1">
						<input
							type="checkbox"
							checked={theme === 'dark'}
							onChange={() => setAllTheme('dark')}
							aria-label={t('dark_theme')}
							role="switch"
							aria-checked={theme === 'dark' ? 'true' : 'false'}
						/>
						<span className="slider round"></span>
					</label>
				</div>
			</div>
		</div>
	);
}
