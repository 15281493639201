import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { customDateTimeFormat } from '../utils/datetime';
i18next
	.use(initReactI18next)
	.use(HttpApi)
	.use(LanguageDetector) // Registering the detection plugin
	.init({
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // React escapes dynamic values by itself
		},
		debug: process.env.NODE_ENV === 'development',
		supportedLngs: ['en', 'pl'],
		nonExplicitSupportedLngs: true,
		load: 'languageOnly',
	});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
i18next.services.formatter?.add('localdatetime', (date, _lng, _options) =>
	customDateTimeFormat(date)
);
export default i18next;
