import { useCallback } from 'react';
import FileInput, { FormikFileInputProps } from './FileInput';

/**
 * A wrapper over FileInput better suited for formik.
 */

export function FormikFileInput(props: FormikFileInputProps): JSX.Element {
	const {
		form: { setFieldValue },
		maxFiles,
		maxSize,
		field: { name, value },
		hideRemove,
		hideFiles,
	} = props;

	// REMEMBER ABOUT useCallback! Without it an infinite loop will occur.
	const onChange = useCallback(
		(files: File[]) => setFieldValue(name, files),
		[setFieldValue, name]
	);
	const onError = useCallback((message: string) => alert(message), []);

	return (
		<FileInput
			maxFiles={maxFiles}
			maxSize={maxSize}
			formikData={{
				value,
				onChange,
				onError,
				hideRemove,
				hideFiles,
			}}
		/>
	);
}
