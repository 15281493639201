import {
	GenerateUserJwtTokenForPermittedUserResponse,
	LoginAsOtherUserCommand,
	LoginAsOtherUserResponse,
} from '../../models';
import { postRequest } from '../base';

export async function loginAs(
	userId: number,
	departments: number[] | boolean
): Promise<GenerateUserJwtTokenForPermittedUserResponse | undefined> {
	const response = await postRequest<LoginAsOtherUserResponse, Required<LoginAsOtherUserCommand>>(
		'management/user/loginAsOther',
		{ userId },
		departments
	);
	return response.data.signedInUserData;
}
