import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import { PaperStatus } from '../../../services/models';
import { assignStudentToPaper, useErrorHandler } from '../../../services/network';
import PaperStatusDisplay from '../../PaperStatusDisplay/PaperStatusDisplay';
import PaperDetails, { ReservedMessageKind } from './PaperDetails';
import { CommonSpecialisedPaperDetailsProps } from './PaperDetailsMain';

export default function PaperDetailsStudent(
	props: CommonSpecialisedPaperDetailsProps
): JSX.Element {
	const { paper, paperId, user } = props;
	const studentPapers = paper.studentPapers ?? [];
	const [submitting, setSubmitting] = useState(false);

	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();
	if (typeof user.studentId !== 'number') return <></>;

	const assign = () => {
		setSubmitting(true);
		assignStudentToPaper(paperId)
			.then(() => toast.success(t('assigned')))
			.catch((e) => {
				setSubmitting(false);
				throw e;
			})
			.catch(networkErrorHandler)
			.catch((e) => {
				if (e instanceof Error) {
					toast.error(e.message);
				} else if (typeof e === 'string') {
					toast.error(e);
				}
			});
	};
	const papersStudentAlreadyWants = studentPapers.filter(
		(paper) => paper.studentId === user.studentId
	);
	const studentAlreadyWants = papersStudentAlreadyWants.length > 0;
	const studentIsAccepted = papersStudentAlreadyWants.some((sp) => sp.isStudentAccepted);

	return (
		<div className="scroll-content scroll-content-back bg-white">
			<PaperDetails
				paper={paper}
				hideStatus
				reservedMessage={
					studentIsAccepted ? ReservedMessageKind.HIDDEN : ReservedMessageKind.WARNING
				}
			/>
			{!submitting &&
				paper.status === PaperStatus.APPROVED &&
				!studentAlreadyWants &&
				!user.hasStudentPaperAssigned && (
					<div className="mt-3 ml-5 ml-3-m ml-1-s flex flex-center-s">
						<button className="button-1 p-1" onClick={assign}>
							{t('choose_topic')}
						</button>
					</div>
				)}
			<div className="ml-5 ml-3-m ml-1-s mb-3">
				{studentAlreadyWants && (
					<p>
						<PaperStatusDisplay paper={paper} />
					</p>
				)}
			</div>
		</div>
	);
}
