import { deleteRequest, getRequest, postRequest, postRequestFD, putRequest } from '..';
import {
	AddMajorCommand,
	AddMajorResponse,
	DeleteMajorResponse,
	GetMajorsResponse,
	ImportMajorDataResponse,
	MajorDegreeType,
	UpdateMajorCommand,
	UpdateMajorResponse,
} from '../../models';

export async function getMajorList(): Promise<GetMajorsResponse> {
	const response = await getRequest<GetMajorsResponse>(`/management/major/list`);
	return response.data;
}

export async function importMajorDataResponse(
	imagefile: File,
	departments: number[] | boolean
): Promise<ImportMajorDataResponse> {
	const formData = new FormData();
	formData.append('dataImportFile', imagefile);
	const response = await postRequestFD<ImportMajorDataResponse>(
		`/management/data/import/major`,
		formData,
		departments
	);
	return response.data;
}

export async function addMajor(
	name: string,
	shortName: string,
	majorDegreeType: MajorDegreeType,
	departments: number[] | boolean
): Promise<AddMajorResponse> {
	const response = await postRequest<AddMajorResponse, AddMajorCommand>(
		'/management/major',
		{
			name,
			shortName,
			majorDegreeType,
		},
		departments
	);
	return response.data;
}

export async function updateMajor(
	majorId: number,
	name: string,
	shortName: string,
	majorDegreeType: number,
	departments: number[] | boolean
): Promise<UpdateMajorResponse> {
	const response = await putRequest<UpdateMajorResponse, UpdateMajorCommand>(
		'/management/major',
		{ majorId, name, shortName, majorDegreeType },
		departments
	);
	return response.data;
}

export async function deleteMajor(
	majorId: number,
	departments: number[] | boolean
): Promise<DeleteMajorResponse> {
	const response = await deleteRequest<DeleteMajorResponse>(
		'/management/major',
		{
			majorId,
		},
		departments
	);
	return response.data;
}
