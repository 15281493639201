import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import { agreeForStudentPaper, useErrorHandler } from '../../../services/network';
import PaperDetails from './PaperDetails';
import PaperDetailsAssignedStudents from './PaperDetailsAssignedStudents';
import { CommonSpecialisedPaperDetailsProps } from './PaperDetailsMain';

export default function PaperDetailsAssigned(
	props: CommonSpecialisedPaperDetailsProps
): JSX.Element {
	const { paper, paperId } = props;
	const studentPapers = paper.studentPapers ?? [];
	const [isAssigned, setIsAssigned] = useState(false);
	const [submiting, setSubmitting] = useState(false);

	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	function aggreeOrDeclinePaper(isAccepted: boolean) {
		if (
			confirm(
				t(
					isAccepted
						? 'do_you_want_to_agree_student_paper'
						: 'do_you_want_to_reject_student_paper'
				)
			)
		) {
			setSubmitting(true);
			agreeForStudentPaper(isAccepted, paperId)
				.then(() => {
					toast.success(isAccepted ? t('accepted') : t('rejected'));
					setIsAssigned(true);
				})
				.catch(networkErrorHandler)
				.finally(() => setSubmitting(false));
		}
	}

	return (
		<div className="scroll-content scroll-content-back bg-white">
			<PaperDetails paper={paper} />
			{studentPapers.some((stp) => stp.isStudentAccepted) && (
				<div className="mt-3 ml-5 ml-3-m ml-1-s mb-3 flex flex-wrap p">
					<PaperDetailsAssignedStudents
						studentPapers={studentPapers}
						showEmail
						topic={paper.paperDetails?.polishTitle ?? ''}
					/>
				</div>
			)}
			{!isAssigned && !submiting && (
				<div className="mt-3 ml-5 ml-3-m ml-1-s flex flex-center-s">
					<button
						className="button-1 p-1 m-1"
						onClick={() => {
							aggreeOrDeclinePaper(true);
						}}
					>
						{t('accept')}
					</button>
					<button
						className="button-1 p-1 m-1"
						onClick={() => {
							aggreeOrDeclinePaper(false);
						}}
					>
						{t('reject')}
					</button>
				</div>
			)}
		</div>
	);
}
