import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';
import { MajorDto } from '../../services/models';
import { getMajors, useErrorHandler } from '../../services/network';

export interface MajorBase extends MajorDto {
	majorId: number;
	name: string;
}

class Major implements MajorBase {
	majorId: number;
	name: string;

	constructor(tag: unknown) {
		if (Major.checkMajor(tag)) {
			this.majorId = tag.majorId;
			this.name = tag.name;
		} else {
			throw new Error('this should be unreachable');
		}
	}

	static checkMajor(major: unknown): major is MajorBase {
		if (typeof major !== 'object') throw new Error('Major should be an object');
		/* eslint-disable @typescript-eslint/no-explicit-any */
		if (typeof (major as any).majorId !== 'number') throw new Error('majorId is incorrect');
		if (typeof (major as any).name !== 'string') throw new Error('Major has no name');
		/* eslint-enable @typescript-eslint/no-explicit-any */
		return true;
	}
}

interface FixableMajorOption {
	value: number;
	label: string;
	isFixed: boolean;
}

async function loadMajors(lockedMajorId?: number): Promise<FixableMajorOption[]> {
	const response = await getMajors();
	const majors = response.majors ?? [];
	if (Array.isArray(majors)) {
		return majors
			.map((major) => new Major(major))
			.map((major) => ({
				value: major.majorId,
				label: major.name,
				isFixed: major.majorId === lockedMajorId,
			}));
	} else {
		throw new Error('majors is not an array');
	}
}

export default function MajorInput<Values>(props: MajorInputProps<Values>): JSX.Element {
	const { name, lockedMajorId, addedMajorsName } = props;
	const isModify = typeof addedMajorsName === 'string';

	const [majorsOptions, setMajorsOptions] = useState<FixableMajorOption[] | undefined>(undefined);
	const { setFieldValue, values } = useFormikContext<Values>();
	const [field, meta, helpers] = useField<number[] | undefined>(name);
	const { value } = field;
	const { initialValue = [] } = meta;
	const { setValue } = helpers;
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	useEffect(() => {
		loadMajors(lockedMajorId)
			.then((r) => setMajorsOptions(r))
			.catch(networkErrorHandler);
	}, [lockedMajorId, networkErrorHandler]);
	// Make sure that fixed values are set
	useEffect(() => {
		if ((value === undefined || value.length === 0) && lockedMajorId && majorsOptions) {
			setValue(majorsOptions?.filter((m) => m.isFixed).map((m) => m.value));
		}
	}, [majorsOptions, lockedMajorId, value, setValue]);

	const styles: StylesConfig<FixableMajorOption | undefined, true> = {
		multiValueLabel: (base, state) => {
			return state.data?.isFixed ? { ...base, fontWeight: 'bold', paddingRight: 6 } : base;
		},
		multiValueRemove: (base, state) => {
			return state.data?.isFixed ? { ...base, display: 'none' } : base;
		},
	};
	return (
		<Select
			className="react-select-container"
			classNamePrefix="react-select"
			isMulti
			options={majorsOptions}
			isLoading={typeof majorsOptions === 'undefined'}
			value={
				majorsOptions && [
					...(value ?? majorsOptions.filter((m) => m.isFixed)).map((v) =>
						majorsOptions.find((m) => m.value === v)
					),
					...(isModify && Array.isArray(values[addedMajorsName])
						? // eslint-disable-next-line @typescript-eslint/no-explicit-any
						  (values[addedMajorsName] as unknown as any[]).filter(
								(v): v is number => typeof v === 'number'
						  )
						: []
					).map((v) => majorsOptions.find((m) => m.value === v)),
				]
			}
			isClearable={value?.some((v) => !majorsOptions?.find((m) => m.value === v)?.isFixed)}
			styles={styles}
			placeholder={t('select_major')}
			noOptionsMessage={({ inputValue }) =>
				inputValue.length ? t('no_majors_found') : t('start_typing_to_find_majors')
			}
			onChange={(newValue, actionMeta) => {
				if (
					(actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') &&
					actionMeta.removedValue?.isFixed
				)
					return;
				const setIds = newValue
					.filter((v): v is NonNullable<typeof v> => typeof v !== 'undefined')
					.map((v) => v.value);
				if (isModify) {
					const newIds = setIds.filter((id) => !initialValue.includes(id));
					const keptIds = initialValue.filter((id) => setIds.includes(id));
					setValue(keptIds);
					setFieldValue(addedMajorsName, newIds);
				} else {
					setValue(setIds);
				}
			}}
			onBlur={() => helpers.setTouched(true)}
		/>
	);
}

export interface MajorInputProps<Values> {
	name: string;
	lockedMajorId?: number;
	addedMajorsName?: keyof Values | undefined;
}
