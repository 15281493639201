import {
	BaseResponse,
	ChangeYearConfigurationCommand,
	ChangeYearConfigurationResponse,
	GetYearConfigurationPhaseResponse,
	YearConfigurationType,
} from '../../models';
import { getRequest, putRequest } from '../base';

export async function getYearConfiguration(): Promise<GetYearConfigurationPhaseResponse> {
	const response = await getRequest<GetYearConfigurationPhaseResponse>(
		'/management/year/configuration'
	);
	return response.data;
}

export async function setYearConfiguration(
	yearConfigurationType: YearConfigurationType
): Promise<BaseResponse> {
	const response = await putRequest<
		ChangeYearConfigurationResponse,
		ChangeYearConfigurationCommand
	>('/management/year/configuration', { yearConfigurationType });
	return response.data;
}
