import React from 'react';
import User from '../utils/localStorageClasses/User';

interface UserContextType {
	user: User | undefined;
	setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const UserContext = React.createContext<UserContextType>({
	user: undefined,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setUser: (): void => {},
});
UserContext.displayName = 'UserContext';

export { UserContext };
