import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import { ErrorCode, PaperStatus } from '../../../services/models';
import {
	acceptStudentForPaper,
	deleteSupervisorPaper,
	rejectStudentFromPaper,
	rollbackToCreated,
	ServerError,
	setPaperToWaiting,
	useErrorHandler,
} from '../../../services/network';
import PhaseRestricted from '../../PhaseRestricted/PhaseRestricted';
import PaperDetails from './PaperDetails';
import PaperDetailsAssignedStudents from './PaperDetailsAssignedStudents';
import { CommonSpecialisedPaperDetailsPropsWithCache } from './PaperDetailsMain';
import { SingleStudent } from './SingleStudent';

export default function PaperDetailsCreated(
	props: CommonSpecialisedPaperDetailsPropsWithCache
): JSX.Element {
	const { paper, paperId, setCacheState } = props;
	const { status = null } = paper;
	const studentPapers = paper.studentPapers ?? [];
	const maxStudents = paper.paperDetails?.maxStudents ?? 0;
	const [isSubmitting, setSubmitting] = useState(false);
	const navigate = useNavigate();
	const { state } = useLocation();
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();
	const smallScreenRule = '(max-width: 540px)';
	const [smallScreen, setSmallScreen] = useState(window.matchMedia(smallScreenRule).matches);

	useEffect(() => {
		const matchMedia = window.matchMedia(smallScreenRule);
		const eventHandler = (e: MediaQueryListEvent) => setSmallScreen(e.matches);
		try {
			matchMedia.addEventListener('change', eventHandler);
		} catch (e) {
			if (e instanceof TypeError) {
				matchMedia.addListener(eventHandler);
			}
		}
		return () => {
			try {
				matchMedia.removeEventListener('change', eventHandler);
			} catch (e) {
				if (e instanceof TypeError) {
					matchMedia.removeListener(eventHandler);
				}
			}
		};
	}, [setSmallScreen]);

	const publishPaper = () => {
		setSubmitting(true);
		setPaperToWaiting(paperId)
			.then(() => {
				toast.success(t('sent_for_review'));
				setCacheState((st) => st + 1);
			})
			.catch(networkErrorHandler)
			.finally(() => setSubmitting(false));
	};
	const acceptStudent = (paperId?: number, studentId?: number) => {
		if (typeof paperId === 'number' && typeof studentId === 'number')
			if (confirm(t('do_you_want_to_accept_student'))) {
				setSubmitting(true);
				acceptStudentForPaper(paperId, studentId)
					.then(() => {
						toast.success(t('accepted'));
						setCacheState((st) => st + 1);
					})
					.catch(networkErrorHandler)
					.catch((e) => {
						if (
							e instanceof ServerError &&
							e.errorCode === ErrorCode.DUPLICATE_ALREADY_EXISTS
						) {
							toast.error(t('student_already_accepted'));
						} else {
							throw e;
						}
					})
					.finally(() => setSubmitting(false));
			}
	};
	const rejectStudent = (paperId?: number, studentId?: number) => {
		if (!(typeof paperId === 'number' && typeof studentId === 'number')) return;
		if (confirm(t('do_you_want_to_reject_student'))) {
			setSubmitting(true);
			rejectStudentFromPaper(paperId, studentId)
				.then(() => {
					toast.success(t('rejected'));
					setCacheState((st) => st + 1);
				})
				.catch(networkErrorHandler)
				.finally(() => setSubmitting(false));
		}
	};
	const acceptedStudentsCount = studentPapers.filter((paper) => paper.isStudentAccepted).length;

	const deletePaper = () => {
		if (confirm(t('do_you_want_to_delete_paper'))) {
			setSubmitting(true);
			deleteSupervisorPaper(paperId)
				.then(() => {
					toast.success(t('deleted'));
					navigate('/papersManager');
				})
				.catch(networkErrorHandler)
				.finally(() => setSubmitting(false));
		}
	};

	const withdrawTopic = () => {
		if (confirm(t('withdraw_confirm'))) {
			setSubmitting(true);
			rollbackToCreated(paperId)
				.then(() => {
					toast.success(t('withdrawn'));
					navigate('/papersManager');
				})
				.catch(networkErrorHandler)
				.finally(() => setSubmitting(false));
		}
	};

	const DeletePaperButton = () => {
		return (
			<div className="c-12 flex flex-center-s">
				<button
					className="button-3 p-1 mt-1 mb-1"
					onClick={deletePaper}
					disabled={isSubmitting}
				>
					{t('delete_paper')}
				</button>
			</div>
		);
	};

	const backStateExists =
		typeof state === 'object' &&
		state !== null &&
		'backNum' in state &&
		typeof (state as Record<string, unknown>)['backNum'] === 'number';

	function statusSwitch(s: PaperStatus | null) {
		switch (s) {
			case PaperStatus.CREATED:
				return (
					<PhaseRestricted
						supervisorRestricted
						altError={
							<span className="col-link">{t('send_for_review_unavailable')}</span>
						}
					>
						<div className="c-12 flex flex-center-s">
							<button
								className="button-1 p-1"
								onClick={() => {
									publishPaper();
								}}
								disabled={isSubmitting}
							>
								{t('send_for_review')}
							</button>
						</div>
						<DeletePaperButton />
					</PhaseRestricted>
				);
			case PaperStatus.WAITING_FOR_APPROVAL:
				return (
					<>
						{studentPapers.some((stp) => stp.isStudentAccepted) && (
							<PaperDetailsAssignedStudents
								studentPapers={studentPapers}
								showEmail
								topic={paper.paperDetails?.polishTitle ?? ''}
							/>
						)}
						<DeletePaperButton />
					</>
				);
			case PaperStatus.APPROVED:
				return (
					<div className="flex-s flex-column flex-middle-s">
						<h4 className="mt-2">
							<b>{t('students_interested_in')}: </b>
						</h4>
						{studentPapers
							.sort((a, b) => (a.isStudentAccepted && !b.isStudentAccepted ? -1 : 0))
							.map((item, index) => (
								<div className="mt-1" key={index}>
									<ul className="flex flex-middle">
										<li className="col-black">
											<SingleStudent
												student={item}
												showEmail
												topic={paper.paperDetails?.polishTitle ?? ''}
											/>
											{acceptedStudentsCount < maxStudents &&
												!item.isStudentAccepted && (
													<>
														<button
															className={`${
																smallScreen
																	? 'button-browser'
																	: 'button-2'
															}  ml-1`}
															onClick={() => {
																acceptStudent(
																	item.paperId,
																	item.studentId
																);
															}}
															disabled={isSubmitting}
														>
															{t('accept_student')}
														</button>
														<button
															className={`${
																smallScreen
																	? 'button-browser-2'
																	: 'button-3'
															}  ml-1`}
															onClick={() => {
																rejectStudent(
																	item.paperId,
																	item.studentId
																);
															}}
															disabled={isSubmitting}
														>
															{t('reject')}
														</button>
													</>
												)}
										</li>
									</ul>
								</div>
							))}
					</div>
				);
			case PaperStatus.ASSIGNED:
				return (
					<PaperDetailsAssignedStudents
						studentPapers={studentPapers}
						showEmail
						topic={paper.paperDetails?.polishTitle ?? ''}
					/>
				);
			default:
				return <div></div>;
		}
	}

	return (
		<div className="scroll-content scroll-content-back bg-white">
			<PaperDetails paper={paper} />
			<div className="mt-3 ml-5 ml-3-m ml-0-s flex flex-center-s flex-wrap p">
				{statusSwitch(status)}
				{(status === PaperStatus.CREATED ||
					status === PaperStatus.WAITING_FOR_APPROVAL) && (
					<div className="c-12 flex flex-center-s">
						<button
							className="button-1 p-1 mt-1 mb-1"
							onClick={() => {
								navigate(`/editPaper/${paperId}`, {
									state: {
										backNum: backStateExists
											? (state as { backNum: number }).backNum
											: 1,
									},
								});
							}}
							disabled={isSubmitting}
						>
							{t('edit_topic')}
						</button>
					</div>
				)}
				{status === PaperStatus.APPROVED && (
					<div className="c-12 flex flex-center-s">
						<button
							className="button-3 p-1 mt-1 mb-1"
							onClick={withdrawTopic}
							disabled={isSubmitting}
						>
							{t('withdraw_topic')}
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
