import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import { reviewPaper, useErrorHandler } from '../../../services/network';
import PaperDetails from './PaperDetails';
import PaperDetailsAssignedStudents from './PaperDetailsAssignedStudents';
import { CommonSpecialisedPaperDetailsProps } from './PaperDetailsMain';

export default function PaperDetailsReview(props: CommonSpecialisedPaperDetailsProps): JSX.Element {
	const { paper, paperId } = props;
	const [buttonsEnabled, setButtonsEnabled] = useState(true);
	const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null);

	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	return (
		<div className="scroll-content scroll-content-back bg-white">
			<PaperDetails paper={paper} />
			{(paper.studentPapers?.length ?? -1) > 0 && (
				<div className="ml-5 ml-3-m ml-1-s p">
					<PaperDetailsAssignedStudents
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						studentPapers={paper.studentPapers!}
						showEmail
						topic={paper.paperDetails?.polishTitle ?? ''}
					/>
					<div className="mt-1 col-danger">{t('created_by_student_final_review')}</div>
				</div>
			)}
			{buttonsEnabled && (
				<div className="mt-3 ml-5 ml-3-m ml-1-s flex flex-center-s">
					<button
						className="button-1 p-1 m-1"
						onClick={() => {
							reviewPaper(paperId, true)
								.then(() => {
									toast.success(t('accepted'));
									setButtonsEnabled(false);
								})
								.catch(networkErrorHandler)
								.catch((e) => {
									if (e instanceof Error) {
										setErrorMessage(e.message);
									} else if (typeof e === 'string') {
										setErrorMessage(e);
									}
								});
						}}
					>
						{t('accept')}
					</button>
					<button
						className="button-1 p-1 m-1"
						onClick={() => {
							reviewPaper(paperId, false)
								.then(() => {
									toast.success(t('rejected'));
									setButtonsEnabled(false);
								})
								.catch(networkErrorHandler)
								.catch((e) => {
									if (e instanceof Error) {
										setErrorMessage(e.message);
									} else if (typeof e === 'string') {
										setErrorMessage(e);
									}
								});
						}}
					>
						{t('reject')}
					</button>
					<div>{errorMessage && <div>{errorMessage}</div>}</div>
				</div>
			)}
		</div>
	);
}
