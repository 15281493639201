import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../services/contexts';
import { PermissionType } from '../../services/models';
import { logout, restoreAdminToken } from '../../services/network';
import { replaceWithRestoredAdmin } from '../../services/userManagement';
import * as Perms from '../../utils/Permissions';
import './Navbar.scss';

export default function Navbar(): JSX.Element {
	const [navVisibility, setNavVisibility] = useState(false);
	const [navbarLocked, setNavbarLocked] = useState(false);
	const { user, setUser } = useContext(UserContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const toggleNavbar = () => {
		setNavVisibility(!navVisibility);
	};
	const closeNavbar = () => {
		setNavVisibility(false);
	};
	const openNavbar = () => {
		setNavVisibility(true);
	};
	const toggleNavbarLock = () => {
		setNavbarLocked((locked) => !locked);
	};
	const navOpenClass = navbarLocked || navVisibility ? 'navbar-open' : 'navbar-close';

	const loginLogoutHandler = () => {
		if (!user) return;
		logout()
			.then(() => {
				localStorage.removeItem('user');
				setUser(undefined);
				navigate('/auth');
			})
			.catch((e) => console.error(e));
	};
	const returnToAdmin = async () => {
		await logout().catch((e) => console.error(e));
		// Logout must complete before the tokens and user are restored
		restoreAdminToken();
		const admin = replaceWithRestoredAdmin();
		setUser(admin);
	};

	const spoofedByAdmin = user?.byAdmin;
	const isStudent = typeof user?.studentId === 'number';
	const isSupervisor = typeof user?.supervisorId === 'number';

	return (
		<div
			className={
				'navbar-box overflow-x-auto' + (navbarLocked ? ' navbar-open navbar-locked' : '')
			}
		>
			<nav
				onMouseEnter={() => openNavbar()}
				onMouseLeave={() => closeNavbar()}
				className={'navbar bg-midnightblue flex flex-column flex-between ' + navOpenClass}
			>
				<i onClick={() => toggleNavbar()} className="burger-icon burger"></i>
				<div>
					<Link
						to="/"
						className="navLink mb-5 pt-1 flex flex-middle"
						aria-label="Main page - papers manager"
					>
						<span className="logo-ps-icon icon icon-logo"></span>
						<i className="logo-ps-text-icon text text-ps"></i>
					</Link>
					<a
						href="#"
						className="navLink flex flex-middle flex-center-m menu-toggle"
						aria-label="Menu"
						onClick={toggleNavbarLock}
					>
						<i className={`icon ${navbarLocked ? 'arrow-left' : 'burger'}-icon`}></i>
						<h1 className="h4-x h3-s text">&nbsp;</h1>
					</a>
					<Link
						onClick={() => closeNavbar()}
						to="/"
						className="navLink flex flex-middle flex-center-m"
					>
						<span className="home-icon icon"></span>
						<h1 className="h4-x h3-s text">{t('home')}</h1>
					</Link>
					{(isStudent ||
						user?.userPermissions.some(
							(perm) =>
								perm.permissionType === PermissionType.REVIEW_PAPERS &&
								perm.departmentsIds?.length
						)) && (
						<Link
							onClick={() => closeNavbar()}
							to="/browser"
							className="navLink flex flex-middle flex-center-m"
						>
							<span className="browser-icon icon"></span>
							<h1 className="h4-x h3-s text">{t('browser')}</h1>
						</Link>
					)}
					{(isStudent || isSupervisor) && (
						<Link
							onClick={() => closeNavbar()}
							to="/papersManager"
							className="navLink flex flex-middle flex-center-m"
						>
							<span className="manager-icon icon"></span>
							<h1 className="h4-x h3-s text">{t('manager')}</h1>
						</Link>
					)}
					<Link
						onClick={() => closeNavbar()}
						to="/profile"
						className="navLink flex flex-middle flex-center-m"
					>
						<span className="profile-icon icon"></span>
						<h1 className="h4-x h3-s text">{t('profile')}</h1>
					</Link>
					{Perms.hasPermissions(user?.userPermissions, Perms.ADMIN_MANAGEMENT) && (
						<Link
							onClick={() => closeNavbar()}
							to="/adminPanel"
							className="navLink flex flex-middle flex-center-m"
						>
							<span className="admin-icon icon"></span>
							<h1 className="h4-x h3-s text">{t('management')}</h1>
						</Link>
					)}
					{Perms.hasPermissions(user?.userPermissions, [
						PermissionType.GENERATE_PERSONAL_REPORTS,
						PermissionType.GENERATE_REPORTS,
					]) && (
						<Link
							onClick={() => closeNavbar()}
							to="/reports"
							className="navLink flex flex-middle flex-center-m"
						>
							<span className="report-icon icon"></span>
							<h1 className="h4-x h3-s text">{t('reports')}</h1>
						</Link>
					)}
					<Link
						onClick={() => closeNavbar()}
						to="/downloadFile"
						className="navLink flex flex-middle flex-center-m"
					>
						<span className="download-file-icon icon"></span>
						<h1 className="h4-x h3-s text">{t('files_to_download')}</h1>
					</Link>
				</div>

				{spoofedByAdmin ? (
					<Link
						to="/adminPanel"
						onClick={returnToAdmin}
						className="navLink flex flex-middle flex-center-m cursor-pointer"
					>
						<span className="icon logout-icon"></span>
						<h1 className="h4-x h3-s text">{t('logout')}</h1>
					</Link>
				) : (
					<Link
						to="/auth"
						onClick={loginLogoutHandler}
						className="navLink flex flex-middle flex-center-m cursor-pointer"
					>
						<span
							className={
								'icon' + (user === undefined ? ' login-icon' : ' logout-icon')
							}
						></span>
						<h1 className="h4-x h3-s text">
							{user === undefined ? t('sign_in') : t('logout')}
						</h1>
					</Link>
				)}
			</nav>
		</div>
	);
}
