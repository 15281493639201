import { getRequest } from '.';
import { GetAcademicDegreeDictionaryDataResponse, GetMajorDictionaryDataResponse } from '../models';

export async function getMajors(): Promise<GetMajorDictionaryDataResponse> {
	const response = await getRequest<GetMajorDictionaryDataResponse>(
		'/core/dictionaryData/majors'
	);
	return response.data;
}

export async function getAcademicDegrees(): Promise<GetAcademicDegreeDictionaryDataResponse> {
	const response = await getRequest<GetAcademicDegreeDictionaryDataResponse>(
		`/core/dictionaryData/academicDegrees`
	);
	return response.data;
}
