import { getRequest } from '..';
import {
	BrowseOtherUsersQuery,
	BrowseOtherUsersResponse,
	BrowsePapersQuery,
	BrowseStudentsQuery,
	BrowseStudentsResponse,
	BrowseSupervisorsQuery,
	BrowseSupervisorsResponse,
	ManagementBrowsePapersResponse,
} from '../../models';

function getPagination(paginationHeader: string | undefined): Pagination {
	return JSON.parse(
		paginationHeader ?? '{"currentPage":1,"pageSize":10,"totalCount":1,"totalPages":1}'
	);
}

export async function getStudents(
	studentBrowserParams: BrowseStudentsQuery,
	departments: number[] | boolean
): Promise<{
	responseData: BrowseStudentsResponse;
	pagination: Pagination;
}> {
	const response = await getRequest<BrowseStudentsResponse>(
		'/management/browser/students',
		studentBrowserParams,
		departments
	);
	const responseData = response.data;
	const pagination = getPagination(response.headers.pagination);
	return { responseData, pagination };
}

export async function getSupervisors(
	supervisorBrowserParams: BrowseSupervisorsQuery,
	allowedDepartments: number[] | boolean
): Promise<{
	responseData: BrowseSupervisorsResponse;
	pagination: Pagination;
}> {
	const departments = Array.isArray(allowedDepartments)
		? allowedDepartments.filter((id) => supervisorBrowserParams.departmentsIds.includes(id))
		: supervisorBrowserParams.departmentsIds;
	const response = await getRequest<BrowseSupervisorsResponse>(
		'/management/browser/supervisors',
		supervisorBrowserParams,
		departments
	);
	const responseData = response.data;
	const pagination = getPagination(response.headers.pagination);
	return { responseData, pagination };
}

export async function getOthers(
	otherUsersBrowserParams: BrowseOtherUsersQuery,
	allowedDepartments: number[] | boolean
): Promise<{
	responseData: BrowseOtherUsersResponse;
	pagination: Pagination;
}> {
	const departments = Array.isArray(allowedDepartments)
		? allowedDepartments.filter((id) => otherUsersBrowserParams.departmentsIds.includes(id))
		: otherUsersBrowserParams.departmentsIds;
	const response = await getRequest<BrowseOtherUsersResponse>(
		'/management/browser/others',
		otherUsersBrowserParams,
		departments
	);
	const responseData = response.data;
	const pagination = getPagination(response.headers.pagination);
	return { responseData, pagination };
}

interface Pagination {
	currentPage: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
}

export async function getPapers(
	papersBrowserParams: BrowsePapersQuery,
	departments: number[] | boolean
): Promise<{
	responseData: ManagementBrowsePapersResponse;
	pagination: Pagination;
}> {
	const response = await getRequest<ManagementBrowsePapersResponse>(
		'/management/browser/papers',
		papersBrowserParams,
		departments
	);
	const responseData = response.data;
	const pagination = getPagination(response.headers.pagination);
	return { responseData, pagination };
}
