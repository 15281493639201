import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminPapersBrowser from '../AdminPanel/Views/AdminBrowser/Browsers/AdminPapersBrowser';
import UnifiedBrowserRoutes from '../Browser/UnifiedBrowser/UnifiedBrowserRoutes';
import DownloadFile from '../DownloadFile/DownloadFile';
import MainPage from '../MainPage/MainPage';
import NotFound from '../NotFound/NotFound';
import CreatePaper from '../Papers/CreatePaper';
import PaperDetailsMain from '../Papers/PaperDetails/PaperDetailsMain';
import UpdatePaper from '../Papers/UpdatePaper';
import PapersManager from '../PapersManager/PapersManager';
import PhaseRestricted from '../PhaseRestricted/PhaseRestricted';
import Profile from '../Profile/Profile';
import Suspended from '../Suspended/Suspended';

const AcademicDegreeManager = React.lazy(
	() => import('../AdminPanel/Views/Manager/AcademicDegreeManager')
);
const AdminPanel = React.lazy(() => import('../AdminPanel/AdminPanel/AdminPanel'));
const AdminBrowser = React.lazy(() => import('../AdminPanel/Views/AdminBrowser/AdminBrowser'));
const Announcement = React.lazy(() => import('../AdminPanel/Views/Announcement/Announcement'));
const ArchiveSystem = React.lazy(() => import('../AdminPanel/Views/ArchiveSystem/ArchiveSystem'));
const UserDetails = React.lazy(() => import('../AdminPanel/Views/Details/UserDetails'));
const DepartmentManager = React.lazy(() => import('../AdminPanel/Views/Manager/DepartmentManager'));
const MajorManager = React.lazy(() => import('../AdminPanel/Views/Manager/MajorManager'));
const TagManager = React.lazy(() => import('../AdminPanel/Views/Manager/TagManager'));
const ManualMail = React.lazy(() => import('../AdminPanel/Views/ManualMail/ManualMail'));
const PublicFile = React.lazy(() => import('../AdminPanel/Views/PublicFile/PublicFile'));
const ReportsView = React.lazy(() => import('../AdminPanel/Views/Reports/ReportsView'));
const YearConfiguration = React.lazy(
	() => import('../AdminPanel/Views/YearConfiguration/YearConfiguration')
);

export default function MainContent(): JSX.Element {
	return (
		<div>
			<Routes>
				<Route
					path="/browser/*"
					element={
						<PhaseRestricted>
							<UnifiedBrowserRoutes />
						</PhaseRestricted>
					}
				/>
				<Route path="downloadFile" element={<DownloadFile />} />
				<Route path="/" element={<MainPage />} />
				<Route
					path="/createPaper"
					element={
						<PhaseRestricted>
							<CreatePaper />
						</PhaseRestricted>
					}
				/>
				<Route
					path="/paper/:paperId"
					element={
						<PhaseRestricted>
							<PaperDetailsMain />
						</PhaseRestricted>
					}
				/>
				<Route
					path="/editPaper/:paperId"
					element={
						<PhaseRestricted>
							<UpdatePaper />
						</PhaseRestricted>
					}
				/>
				<Route path="/papersManager" element={<PapersManager />} />
				<Route path="/profile" element={<Profile />} />
				{/* Admin section */}
				<Route
					path="/academicDegreeManager"
					element={
						<Suspended>
							<AcademicDegreeManager />
						</Suspended>
					}
				/>
				<Route
					path="/adminPanel"
					element={
						<Suspended>
							<AdminPanel />
						</Suspended>
					}
				/>
				<Route
					path="/adminBrowser"
					element={
						<Suspended>
							<AdminBrowser />
						</Suspended>
					}
				/>
				<Route
					path="/adminPapersBrowser"
					element={
						<Suspended>
							<AdminPapersBrowser />
						</Suspended>
					}
				/>
				<Route
					path="/quickMessage"
					element={
						<Suspended>
							<Announcement />
						</Suspended>
					}
				/>
				<Route
					path="/archiveSystem"
					element={
						<Suspended>
							<ArchiveSystem />
						</Suspended>
					}
				/>
				<Route
					path="/user/:userId"
					element={
						<Suspended>
							<UserDetails />
						</Suspended>
					}
				/>
				<Route
					path="/departmentManager"
					element={
						<Suspended>
							<DepartmentManager />
						</Suspended>
					}
				/>
				<Route
					path="/majorManager"
					element={
						<Suspended>
							<MajorManager />
						</Suspended>
					}
				/>
				<Route
					path="/tagManager"
					element={
						<Suspended>
							<TagManager />
						</Suspended>
					}
				/>
				<Route
					path="/manualMail"
					element={
						<Suspended>
							<ManualMail />
						</Suspended>
					}
				/>
				<Route
					path="/publicFile"
					element={
						<Suspended>
							<PublicFile />
						</Suspended>
					}
				/>
				<Route
					path="/reports"
					element={
						<Suspended>
							<ReportsView />
						</Suspended>
					}
				/>
				<Route
					path="/manageYear"
					element={
						<Suspended>
							<YearConfiguration />
						</Suspended>
					}
				/>
				{/* Not found */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</div>
	);
}
