import { TFunction } from 'i18next';
import { ErrorCode } from 'react-dropzone';

/**
 * Converts file size in bytes to x bytes/KB/MB
 * @param fileSize File size **in bytes**
 * @param t t property returned by `useTranslation()`
 */
export function sizeToString(fileSize: number, t: TFunction<'translation', undefined>): string {
	if (fileSize < 1000) return `${fileSize} ${t('bytes')}`;
	if (fileSize < 1_000_000) return `${(fileSize / 1000).toFixed(2)} KB`;
	return `${(fileSize / 1_000_000).toFixed(2)} MB`;
}

/**
 * Escapes RegExp problematic characters from strings.\
 * Escapes `-`, `/`, `\\`, `^`, `$`, `*`, `+`, `?`, `.`, `|`, parenthesis, brackets and braces.
 * @param s user provided string
 * @returns a RegExp safe string
 * @see https://stackoverflow.com/a/3561711
 */
function escapeRegExp(s: string): string {
	return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

/**
 * Converts react-dropzone's error code to a user-readable message
 * @param code error code
 * @param maxSize maximum file size
 * @param maxFiles maximum number of files
 * @param t t property returned by `useTranslation()`
 * @returns User readable message with error description and limits
 */
export function errorToMessage(
	code: ErrorCode | string,
	maxSize: number,
	maxFiles: number,
	t: TFunction<'translation', undefined>
): string {
	const errorMap: { [key: string]: string } = {
		[ErrorCode.FileInvalidType]: t('invalid_file_type'),
		[ErrorCode.FileTooLarge]: `${t('too_large')} (${t('limit')} ${sizeToString(maxSize, t)})`,
		[ErrorCode.FileTooSmall]: t('too_small'),
		[ErrorCode.TooManyFiles]: `${t('too_many_files')} (${t('limit')} ${maxFiles})`,
	};
	return errorMap[code] || code;
}

/**
 * Renames files in `newFiles` which already are in `oldFiles`, so that file names can be used as React keys.
 * @param oldFiles already accepted files
 * @param newFiles newly selected files
 */
export function renameDuplicates(oldFiles: File[], newFiles: File[]): File[] {
	return newFiles.map((file) => {
		// Check if a file with the same name already exits
		if (oldFiles.some((prevFile) => prevFile.name === file.name)) {
			const { name, type, lastModified } = file;
			const lastDot = name.lastIndexOf('.');
			const pureName = name.substring(0, lastDot);
			const ext = name.substring(lastDot + 1);
			// Check if there is a file matching "<filename> (<any number>).<extension>"
			const expr = new RegExp(`^${escapeRegExp(pureName)} \\((\\d+)\\)\\.${ext}$`);
			// If such file(s) exist get their <any number>
			const usedNumbers = oldFiles
				.map((prevFile) => prevFile.name.match(expr))
				.filter((match) => match !== null)
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				.map((match) => parseInt(match![1]));
			let maxNum = Math.max(...usedNumbers); // Find the maximum number, -Infinity if the array is empty
			if (!Number.isInteger(maxNum)) maxNum = 0; // Get rid of possible -Infinity (or NaN which should not be possible)
			const newNumber = maxNum + 1;
			const newName = `${pureName} (${newNumber}).${ext}`;
			// Generate a new file with the old content, type and date, but new non-conflicting name
			const renamedFile = new File([file], newName, {
				type,
				lastModified,
			});
			return renamedFile;
		}
		return file; // If there is no conflict, leave as is
	});
}
