import { FormikHelpers } from 'formik';

// Workaround https://github.com/microsoft/TypeScript/issues/15300 (`never` can't be used)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleServerValidation<T extends Record<string, any>>(
	keys: string[],
	helpers: FormikHelpers<T>,
	validations: Record<string, string[]> | undefined | null
) {
	if (!validations) return;
	for (const key in validations) {
		if (Object.prototype.hasOwnProperty.call(validations, key)) {
			const validationErrors = validations[key];
			const formikKey = keys.find(
				(fKey) => fKey.toString().toLowerCase() === key.toLowerCase()
			);
			if (typeof formikKey !== 'string') continue;
			helpers.setFieldError(formikKey, validationErrors.join('; '));
		}
	}
}
