import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Select, { GroupBase, Options, OptionsOrGroups } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toast';
import { ErrorCode } from '../../services/models';
import { ServerError, createTag, getAllTags, useErrorHandler } from '../../services/network';
import './TagInput.scss';

// https://github.com/i18next/react-i18next/issues/1483#issuecomment-1268455602
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TI = any;

Modal.setAppElement('#root');

interface TagOption {
	value: string;
	label: string;
}

async function getTagOptions(): Promise<TagOption[]> {
	const tags = await getAllTags();
	return tags.map(({ id, content }) => ({ value: id, label: content }));
}

export default function TagInput(props: TagInputProps): JSX.Element {
	const { creatable = false, name } = props;

	const [cacheState, setCacheState] = useState(1);
	const [tagsOptions, setTagsOptions] = useState<TagOption[] | undefined>(undefined);
	const [popupTag, setPopupTag] = useState<string | null>(null);
	const [createTagInput, setCreateTagInput] = useState('');

	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
	const [field, _meta, helpers] = useField<string[]>(name);
	const { value } = field;
	const { setValue, setTouched } = helpers;

	useEffect(() => {
		getTagOptions()
			.then((r) => setTagsOptions(r))
			.catch(networkErrorHandler);
	}, [cacheState, networkErrorHandler]);

	const SelectComponent = creatable ? CreatableSelect : Select;
	const onCreateRequest = (val: string) => {
		setPopupTag(val.toUpperCase());
	};
	const handleCreate = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>
	) => {
		e.preventDefault();
		if (popupTag === null) return;
		createTag(popupTag)
			.then((tag) => {
				console.log('Tag created', tag);
				setValue([...value, tag.id]);
				setCacheState((v) => v + 1);
				setPopupTag(null);
				setCreateTagInput('');
			})
			.catch(networkErrorHandler)
			.catch((e) => {
				if (
					e instanceof ServerError &&
					e.errorCode === ErrorCode.VALIDATION_FAILED &&
					e.validations
				) {
					const matchingKeys = Object.keys(e.validations).filter(
						(k) => k.toLowerCase() === 'content'
					);
					if (matchingKeys.length > 0) {
						const key = matchingKeys[0];
						const validationError = e.validations[key];
						toast.error(t('couldnt_create_tag') + validationError.join('; '));
						return;
					}
				}
				console.error(e);
				toast.error(t('error_occurred'));
			});
	};
	const closeCreate = () => {
		setPopupTag(null);
	};
	const isValidNewOption = (
		inputValue: string,
		_value: Options<TagOption>,
		options: OptionsOrGroups<TagOption, GroupBase<TagOption>>
	) => {
		if (inputValue.length === 0 || inputValue.length > 30) return false;
		return !options.some((option) => {
			return option.label === inputValue.toUpperCase();
		});
	};
	const additonalSelectProps = creatable
		? {
				allowCreateWhileLoading: false,
				onCreateOption: onCreateRequest,
				isValidNewOption,
		  }
		: undefined;

	return (
		<>
			<SelectComponent
				className="react-select-container"
				classNamePrefix="react-select"
				isMulti
				options={tagsOptions}
				isLoading={tagsOptions === undefined}
				value={
					tagsOptions &&
					value.map(
						(v) =>
							tagsOptions.find((t) => t.value === v) || {
								label: 'non-existent option',
								value: '',
							}
					)
				}
				placeholder={t('select_tags')}
				noOptionsMessage={({ inputValue }) =>
					inputValue.length ? t('no_tags_found') : t('start_typing_to_find_tags')
				}
				onChange={(newValue) => {
					setValue(newValue.map((v) => v.value));
				}}
				onBlur={() => setTouched(true)}
				{...additonalSelectProps}
			/>
			{creatable && (
				// <dialog> isn't supported in Firefox release and ESR yet
				<Modal
					isOpen={popupTag !== null}
					contentLabel={t('create_tag')}
					onRequestClose={closeCreate}
					className="Modal"
					overlayClassName="Overlay"
				>
					<div className="c-12 flex flex-center flex-wrap">
						<h1 className="c-12 flex flex-center">{t('create_tag')}</h1>
						<a
							className="close-icon button-close cursor-pointer"
							onClick={closeCreate}
						></a>
						<div className="tag-modal-content mt-5">
							<label className="text-center" htmlFor="createTagInput">
								<h3>{t('do_you_want_to_create_tag')}</h3>
							</label>
							<h4 className="text-center mt-1">
								<Trans i18nKey="type_yes_to_create_tag">
									To create a{' '}
									<span className="tag-example">{{ popupTag } as TI}</span>
									tag type &quot;yes&quot; below:
								</Trans>
							</h4>
							<div className="c-12 flex flex-column flex-middle mt-1">
								<input
									className="input-1"
									type="text"
									id="createTagInput"
									value={createTagInput}
									onChange={(e) => {
										setCreateTagInput(e.target.value);
									}}
									onKeyUp={(e) => {
										if (
											e.key === 'Enter' &&
											createTagInput.toLowerCase() === 'yes'
										) {
											handleCreate(e);
										}
									}}
								/>
								<div className="flex flex-center mt-1">
									<button className="button-2 mr-1" onClick={closeCreate}>
										{t('cancel')}
									</button>
									<button
										className="button-1 ml-1"
										disabled={createTagInput.toLowerCase() !== 'yes'}
										onClick={handleCreate}
									>
										{t('create')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
}

export interface TagInputProps {
	name: string;
	creatable?: boolean;
}
