import { ErrorMessage, Field, Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import * as Yup from 'yup';
import { UserContext } from '../../../../services/contexts';
import { useUser } from '../../../../services/hooks';
import { MajorDto, PermissionType } from '../../../../services/models';
import { saveSpoofedToken, useErrorHandler } from '../../../../services/network';
import { loginAs } from '../../../../services/network/AdminPanel/userDetails';
import { backupAdmin, saveUserAndLogin } from '../../../../services/userManagement';
import { hasPermissions } from '../../../../utils/Permissions';
import User from '../../../../utils/localStorageClasses/User';

export default function LoginAsPrompt(): JSX.Element {
	const [showPrompt, setShowPrompt] = useState(false);
	const user = useUser();
	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();
	const allowedDepartmentsIds = hasPermissions(
		user?.userPermissions,
		PermissionType.LOGIN_AS_USER
	);
	const canLoginAs = !!allowedDepartmentsIds;

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		if (!canLoginAs) return () => {};
		const listener = (e: KeyboardEvent) => {
			if (e.ctrlKey && e.altKey && e.key === ';' && !e.repeat) setShowPrompt(true);
		};
		document.addEventListener('keydown', listener);
		return () => document.removeEventListener('keydown', listener);
	}, [canLoginAs]);

	if (!canLoginAs) return <></>;

	const onSubmit = ({ userId }: { userId: number }) => {
		loginAsLogic(userId, t, navigate, setUser, allowedDepartmentsIds)
			// On success redirect will happen anyway, don't update state after unmount
			// .then(() => setShowPrompt(false))
			.catch(networkErrorHandler)
			.catch((e) => toast.error(e));
	};
	const closeModal = () => setShowPrompt(false);

	const validationSchema = Yup.object().shape({
		userId: Yup.number()
			.required(t('required'))
			.integer(t('id_integer'))
			.min(0, t('number_nonnegative'))
			.notOneOf([user?.userId], t('this_is_your_id')),
	});

	return (
		<Modal
			isOpen={showPrompt}
			onRequestClose={closeModal}
			contentLabel={t('login_as')}
			className="Modal-editMajor"
			overlayClassName="Overlay"
		>
			<a className="close-icon button-close cursor-pointer" onClick={closeModal}></a>
			<div className="flex flex-center">
				<Formik
					initialValues={{ userId: -1 }}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					<Form className="flex flex-center flex-wrap">
						<div className="c-11 flex flex-column mt-3">
							<label className="p" htmlFor="userId">
								{t('user_id')}
							</label>
							<Field name="userId" id="userId" type="number" className="input-2" />
							<div className="col-danger error-message">
								<ErrorMessage name="userId" />
							</div>
						</div>
						<div className="c-12 flex flex-column flex-middle mt-5">
							<button type="submit" className="button-1">
								{t('login_as')}
							</button>
						</div>
					</Form>
				</Formik>
			</div>
		</Modal>
	);
}

export async function loginAsLogic(
	userId: number,
	t: TFunction<'translation', undefined>,
	navigate: NavigateFunction,
	setUser: React.Dispatch<React.SetStateAction<User | undefined>>,
	allowedDepartmentsIds: number[] | boolean
): Promise<void> {
	const response = await loginAs(userId, allowedDepartmentsIds);
	if (
		!(
			response &&
			typeof response.jwtToken === 'string' &&
			typeof response.refreshAuthToken === 'string'
		)
	)
		throw new Error(t('error_could_not_log_in'));

	// A user with multiple majors
	if (response.user?.supervisor === null && (response.user.students?.length ?? 0) > 1) {
		backupAdmin();
		saveSpoofedToken(response.jwtToken, response.refreshAuthToken);
		return navigate('/chooseMajor', {
			state: { signInResponse: response, byAdmin: true },
		});
	}

	// A student with a single major or another user
	const { user, hasStudentPaperAssigned } = response;
	if (!user) throw new Error(t('error_could_not_log_in'));
	const student = response.user?.students?.length === 1 ? response.user.students[0] : undefined;
	if (
		student &&
		(typeof student.major?.majorId !== 'number' || typeof student.major.name !== 'string')
	) {
		throw new Error(t('error_could_not_log_in'));
	}
	backupAdmin();
	saveSpoofedToken(response.jwtToken, response.refreshAuthToken);
	saveUserAndLogin(
		setUser,
		navigate,
		new User(
			user,
			hasStudentPaperAssigned,
			student?.studentId,
			student?.major as Required<MajorDto>,
			true
		)
	);
}
