import { useField } from 'formik';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toast';
import { DepartmentDto, SupervisorAcademicDegreeDto, SupervisorDto } from '../../services/models';
import { ErrorHandler, getSupervisors, useErrorHandler } from '../../services/network';
import { renderSupervisor } from '../../utils/nameFormatting';

class BasicSupervisor implements SupervisorDto {
	supervisorId: number;
	isLeader?: boolean;
	firstName: string;
	lastName: string;
	emailAddress?: string | null;
	department?: DepartmentDto;
	supervisorAcademicDegrees?: Array<SupervisorAcademicDegreeDto> | null;

	constructor(sup: unknown) {
		if (typeof sup !== 'object' || sup === null)
			throw new Error('Supervisor must be an object');
		if (
			!('supervisorId' in sup) ||
			typeof (sup as { [index: string]: unknown })['supervisorId'] !== 'number' ||
			!('firstName' in sup) ||
			typeof (sup as { [index: string]: unknown })['firstName'] !== 'string' ||
			!('lastName' in sup) ||
			typeof (sup as { [index: string]: unknown })['lastName'] !== 'string'
		)
			throw new Error(
				'Supervisor must have id, first name and last name defined and of correct type'
			);
		type SupervisorWithReqT = {
			[index: string]: unknown;
			supervisorId: number;
			firstName: string;
			lastName: string;
		};
		this.supervisorId = (sup as SupervisorWithReqT)['supervisorId'];
		this.firstName = (sup as SupervisorWithReqT)['firstName'];
		this.lastName = (sup as SupervisorWithReqT)['lastName'];
		this.isLeader =
			'isLeader' in sup && typeof (sup as SupervisorWithReqT)['isLeader'] === 'boolean'
				? (sup as SupervisorWithReqT & { isLeader: boolean })['isLeader']
				: undefined;
		this.emailAddress =
			'emailAddress' in sup && typeof (sup as SupervisorWithReqT)['emailAddress'] === 'string'
				? (sup as SupervisorWithReqT & { emailAddress: string })['emailAddress']
				: undefined;
		this.department =
			'department' in sup &&
			typeof (sup as SupervisorWithReqT)['department'] === 'object' &&
			'departmentId' in
				(sup as SupervisorWithReqT & { department: { [index: string]: unknown } })[
					'department'
				] &&
			typeof (sup as SupervisorWithReqT & { department: { [index: string]: unknown } })[
				'department'
			]['departmentId'] === 'number'
				? {
						departmentId: (
							sup as SupervisorWithReqT & {
								department: { [index: string]: unknown } & { departmentId: number };
							}
						)['department']['departmentId'],
						name:
							'name' in
								(
									sup as SupervisorWithReqT & {
										department: { [index: string]: unknown };
									}
								)['department'] &&
							['string', 'null'].includes(
								typeof (
									sup as SupervisorWithReqT & {
										department: { [index: string]: unknown };
									}
								)['department']['name']
							)
								? (
										sup as SupervisorWithReqT & {
											department: { [index: string]: unknown } & {
												name: string | null;
											};
										}
								  )['department']['name']
								: undefined,
				  }
				: undefined;
		if ('supervisorAcademicDegrees' in sup) {
			const academicDegrees: Array<SupervisorAcademicDegreeDto> | null =
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(sup as SupervisorWithReqT & { supervisorAcademicDegrees: any })[
					'supervisorAcademicDegrees'
				];
			if (academicDegrees === null) this.supervisorAcademicDegrees = academicDegrees;
			else if (Array.isArray(academicDegrees))
				this.supervisorAcademicDegrees = academicDegrees
					.filter(
						(
							deg
						): deg is {
							supervisorId: NonNullable<(typeof deg)['supervisorId']>;
							academicDegree: NonNullable<(typeof deg)['academicDegree']>;
						} =>
							typeof deg.supervisorId === 'number' &&
							typeof deg.academicDegree !== 'undefined'
					)
					.filter(
						(deg) =>
							typeof deg.academicDegree.academicDegreeId === 'number' &&
							typeof deg.academicDegree.name === 'string'
					);
		}
	}
}

interface SupervisorOption {
	value: number;
	label: string;
}

async function loadSupervisors(
	networkErrorHandler: ErrorHandler,
	t: TFunction<'translation', undefined>
): Promise<SupervisorOption[]> {
	try {
		const supervisors = (await getSupervisors().catch(networkErrorHandler)) ?? [];
		if (Array.isArray(supervisors)) {
			return supervisors
				.map((supervisor) => new BasicSupervisor(supervisor))
				.map((supervisor) => ({
					value: supervisor.supervisorId,
					label: renderSupervisor(supervisor),
				}));
		} else {
			throw new Error('supervisors is not an array');
		}
	} catch (e) {
		toast.error(t('couldnt_load_sv_input'));
		return [];
	}
}

export default function SupervisorInput(props: SupervisorInputProps): JSX.Element {
	const { name } = props;

	const [supervisorsOptions, setSupervisorsOptions] = useState<SupervisorOption[] | undefined>(
		undefined
	);
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();
	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
	const [field, _meta, helpers] = useField<number | undefined>(name);
	const { value } = field;
	const { setValue, setTouched } = helpers;

	useEffect(() => {
		loadSupervisors(networkErrorHandler, t).then((r) => setSupervisorsOptions(r));
	}, [networkErrorHandler, t]);

	return (
		<Select
			className="react-select-container"
			classNamePrefix="react-select"
			isClearable
			options={supervisorsOptions}
			isLoading={typeof supervisorsOptions === 'undefined'}
			value={supervisorsOptions && supervisorsOptions.find((v) => v.value === value)}
			placeholder={t('select_supervisor')}
			noOptionsMessage={({ inputValue }) =>
				inputValue.length ? t('no_supervisors_found') : t('start_typing_to_find_sv')
			}
			onChange={(newValue) => {
				setValue(newValue?.value);
			}}
			onBlur={() => setTouched(true)}
		/>
	);
}

export interface SupervisorInputProps {
	name: string;
}
