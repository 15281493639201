import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toast';
import './App.scss';
import LoginAsPrompt from './components/AdminPanel/Views/LoginAs/LoginAsPrompt';
import CAS from './components/Auth/CAS/CAS';
import Login from './components/Auth/Login/Login';
import LoggedContent from './components/LoggedContent/LoggedContent';
import NotFound from './components/NotFound/NotFound';
import Suspended from './components/Suspended/Suspended';
import { UserContext } from './services/contexts';
import { PermissionType } from './services/models';
import './Styles/Styles.scss';
import User from './utils/localStorageClasses/User';
import { getUserTheme, setupThemeChangeHandler } from './utils/userTheme';

const ChooseMajor = React.lazy(() => import('./components/Auth/ChooseMajor/ChooseMajor'));
const SendResetPassword = React.lazy(
	() => import('./components/Auth/ResetPassword/SendResetPassword/SendResetPassword')
);
const VerifyResetPassword = React.lazy(
	() => import('./components/Auth/ResetPassword/VerifyResetPassword/VerifyResetPassword')
);

export default function App(): JSX.Element {
	const [user, setUser] = useState<User | undefined>(User.fromLocalStorage());
	const [theme, setTheme] = useState(getUserTheme);
	useEffect(() => setupThemeChangeHandler(setTheme), []);

	const doc = document.documentElement.style;
	if (theme === 'dark') {
		doc.setProperty('--whitesmoke-dark', '#252525');
		doc.setProperty('--white-dark', '#313131');
		doc.setProperty('--orange-dark', '#fbb800');
		doc.setProperty('--midnightblue-dark', '#252525');
		doc.setProperty('--black-dark', '#cccccc');
		doc.setProperty('--danger-dark', '#d82323');
		doc.setProperty('--gray-dark', '#505050');
		doc.setProperty('--gray-2-dark', '#313131');
		doc.setProperty('--midnightblue-2-dark', '#135faf');
		doc.setProperty('--midnightblue-3-dark', '#135faf');
		doc.setProperty('--white-2-dark', '#ffffff');
		doc.setProperty('--white-3-dark', '#5a5a5a');
		doc.setProperty('--white-4-dark', '#ffffff');
		doc.setProperty('--black-2-dark', '#cccccc');
		doc.setProperty('--dark-gray-dark', '#cccccc');
		doc.setProperty('--shadow-gray-dark', 'transparent');
		doc.setProperty('--link-dark', '#689aff');
	} else if (theme === 'contrast') {
		doc.setProperty('--whitesmoke-dark', '#e7e7e7');
		doc.setProperty('--white-dark', '#000000');
		doc.setProperty('--orange-dark', '#006ce0');
		doc.setProperty('--midnightblue-dark', '#000000');
		doc.setProperty('--black-dark', '#ddd902');
		doc.setProperty('--danger-dark', '#ddd902');
		doc.setProperty('--gray-dark', '#ddd902');
		doc.setProperty('--gray-2-dark', '#000000');
		doc.setProperty('--midnightblue-2-dark', '#ddd902');
		doc.setProperty('--midnightblue-3-dark', '#000000');
		doc.setProperty('--white-2-dark', '#000000');
		doc.setProperty('--white-3-dark', '#ddd902');
		doc.setProperty('--white-4-dark', '#ddd902');
		doc.setProperty('--black-2-dark', '#000000');
		doc.setProperty('--dark-gray-dark', '#ddd902');
		doc.setProperty('--shadow-gray-dark', 'transparent');
		doc.setProperty('--link-dark', '#ddd902');
	} else {
		doc.setProperty('--whitesmoke-dark', '#f2f2f2');
		doc.setProperty('--white-dark', '#ffffff');
		doc.setProperty('--orange-dark', '#fbb800');
		doc.setProperty('--midnightblue-dark', '#164980');
		doc.setProperty('--black-dark', '#000000');
		doc.setProperty('--danger-dark', '#9c0f0f');
		doc.setProperty('--gray-dark', '#e2e2e2');
		doc.setProperty('--gray-2-dark', '#e2e2e2');
		doc.setProperty('--midnightblue-2-dark', '#164980');
		doc.setProperty('--midnightblue-3-dark', '#164980');
		doc.setProperty('--white-2-dark', '#ffffff');
		doc.setProperty('--white-3-dark', '#ffffff');
		doc.setProperty('--white-4-dark', '#ffffff');
		doc.setProperty('--black-2-dark', '#000000');
		doc.setProperty('--dark-gray-dark', '#464646');
		doc.setProperty('--shadow-gray-dark', '#c1c1ca');
		doc.setProperty('--link-dark', '#0044cc');
	}
	const canLoginAs = user?.userPermissions.some(
		(perm) => perm.permissionType === PermissionType.LOGIN_AS_USER
	);

	useEffect(() => {
		if (user?.byAdmin) {
			doc.setProperty('--login-as-border-width', '10px');
		} else {
			doc.setProperty('--login-as-border-width', '0px');
		}
	}, [doc, user]);

	return (
		<>
			<div className={`mainDiv normalColors ${user?.byAdmin && 'LoginAsBorder'}`}>
				<UserContext.Provider value={{ user, setUser }}>
					<Routes>
						<Route path="/auth" element={<Login />} />
						<Route path="/cas" element={<CAS />} />
						<Route
							path="/chooseMajor"
							element={
								<Suspended>
									<ChooseMajor />
								</Suspended>
							}
						/>
						<Route
							path="/resetPassword"
							element={
								<Suspended>
									<SendResetPassword />
								</Suspended>
							}
						/>
						<Route
							path="/resetPassword/verify"
							element={
								<Suspended>
									<VerifyResetPassword />
								</Suspended>
							}
						/>
						<Route path="/404" element={<NotFound />} />
						<Route
							path="/*"
							element={<LoggedContent theme={theme} setTheme={setTheme} />}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
					{canLoginAs && <LoginAsPrompt />}
				</UserContext.Provider>
			</div>
			<ToastContainer position="bottom-right" delay={3000} />
		</>
	);
}
