import { getRequest, putRequest, UnexpectedResponse } from '.';
import { PutTagCommand, PutTagResponse, SearchTagsByContentResponse, TagDto } from '../models';

export async function getAllTags(): Promise<Tag[]> {
	const response = await getRequest<SearchTagsByContentResponse>('/core/tag/searchByContent');
	const tags = response.data.tags;
	if (Array.isArray(tags)) {
		return tags.map((tag) => new Tag(tag));
	} else {
		throw new UnexpectedResponse('tags is not an array');
	}
}

export async function createTag(content: string): Promise<Tag> {
	const response = await putRequest<PutTagResponse, PutTagCommand>('/core/tag', { content });
	return new Tag(response.data.tag);
}

export interface TagBase {
	id: string;
	content: string;
}

class Tag implements TagBase {
	id: string;
	content: string;

	constructor(tag?: TagDto) {
		if (Tag.checkTag(tag)) {
			this.id = tag.id;
			this.content = tag.content;
		} else {
			throw new UnexpectedResponse('this should be unreachable');
		}
	}

	static checkTag(tag?: TagDto): tag is TagBase {
		if (typeof tag !== 'object') throw new UnexpectedResponse('Tag should be an object');
		if (typeof tag.id !== 'string' || tag.id.length !== 24)
			throw new UnexpectedResponse('Tag id is incorrect');
		if (typeof tag.content !== 'string') throw new UnexpectedResponse('Tag has no content');
		return true;
	}
}
