import { getRequest, putRequest } from '.';
import {
	GetUserProfileResponse,
	PutAcademicDegreeCommand,
	PutAcademicDegreeResponse,
} from '../models';

export async function getUserProfile(): Promise<GetUserProfileResponse> {
	const response = await getRequest<GetUserProfileResponse>(`/core/profile`);
	return response.data;
}

export async function setSupervisorTitle(
	academicDegreeId: number
): Promise<PutAcademicDegreeResponse> {
	const response = await putRequest<PutAcademicDegreeResponse, PutAcademicDegreeCommand>(
		'/core/profile/supervisor/academicDegree',
		{ academicDegreeId }
	);
	return response.data;
}
