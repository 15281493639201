import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import Loading from '../../components/Loading/Loading';
import { useUser } from '../../services/hooks';
import { AcademicDegreeDto, EmailOptOutType, UserProfileDto } from '../../services/models';
import {
	UnexpectedResponse,
	generateThesisAcceptationDocument,
	getAcademicDegrees,
	getUserPreferences,
	getUserProfile,
	saveEmailPreferences,
	setSupervisorTitle,
	useErrorHandler,
} from '../../services/network';
import { renderSupervisor } from '../../utils/nameFormatting';
import { notEmpty } from '../../utils/typeGuards';

export default function Profile(): JSX.Element {
	const [user, setUser] = useState<UserProfileDto | null>(null);
	const [emailStatus, setEmailStatus] = useState(EmailOptOutType.NONE);
	const [disableOptOutBtn, setDisableOptOutBtn] = useState(true);
	const [possibleAcademicDegrees, setPossibleAcademicDegrees] = useState<
		AcademicDegreeDto[] | null
	>(null);
	const [displayedTitle, setDisplayedTitle] = useState<{ name: string; id: number } | null>(null);
	const [tempTitle, setTempTitle] = useState<{ name: string; id: number } | null>(null);
	const [editMode, setEditMode] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [generatingDoc, setGeneratingDoc] = useState(false);

	const savedUser = useUser();
	const isSupervisor = typeof savedUser?.supervisorId === 'number';
	const [openDropdown, setOpenDropdown] = useState(false);

	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	useEffect(() => {
		getUserProfile()
			.then((response) => {
				if (response?.userProfile) {
					setUser(response.userProfile);
				}
			})
			.catch(networkErrorHandler);

		getUserPreferences()
			.then((response) => {
				setEmailStatus(
					response.userPreferencesModel?.emailOptOutType ?? EmailOptOutType.NONE
				);
				setDisableOptOutBtn(false);
			})
			.catch(networkErrorHandler);

		if (isSupervisor) {
			getAcademicDegrees()
				.then((response) => {
					if (response.academicDegrees) {
						setPossibleAcademicDegrees(response.academicDegrees);
					}
				})
				.catch(networkErrorHandler);
		}
	}, [setUser, setPossibleAcademicDegrees, isSupervisor, networkErrorHandler]);
	useEffect(() => {
		const degree = user?.supervisor?.supervisorAcademicDegrees?.find(
			(degree) => degree.supervisorId === user.supervisor?.supervisorId
		)?.academicDegree;
		if (typeof degree?.academicDegreeId === 'number' && typeof degree.name === 'string') {
			setDisplayedTitle({ name: degree.name, id: degree.academicDegreeId });
		}
	}, [user?.supervisor?.supervisorAcademicDegrees, user?.supervisor?.supervisorId]);

	if (!savedUser) return <></>;
	const userMajor = savedUser.major?.name ?? t('none') ?? 'none';
	const getThesisAcceptationDoc = () => {
		setGeneratingDoc(true);
		generateThesisAcceptationDocument()
			.then((response) => {
				if (response.data instanceof Blob) {
					toast.success(t('file_generated'));
					const retUrl = window.URL.createObjectURL(response.data);
					const link = document.createElement('a');
					link.href = retUrl;
					link.setAttribute('download', 'Z1-PU12.docx');
					document.body.appendChild(link);
					link.click();
					setTimeout(() => {
						document.body.removeChild(link);
						URL.revokeObjectURL(retUrl);
					}, 5000);
				} else {
					throw new UnexpectedResponse();
				}
			})
			.catch(networkErrorHandler)
			.catch(() => {
				toast.error(t('error_occurred'));
			})
			.finally(() => setGeneratingDoc(false));
	};
	const tab = (
		first: string,
		second: string | React.ReactNode,
		editable = false,
		arrayElements?: Array<{ name: string; id: number }>,
		value?: string,
		onChange?: (newVal: { name: string; id: number }) => void
	) => {
		const edit = editable && editMode;
		return (
			<span className="c-12 mt-1 flex">
				<h3 className="h4-s c-4 c-6-s fw-bold mr-2 mr-0-s text-wrap">{first}</h3>
				{!edit && <h3 className="h4-s c-5">{second}</h3>}
				{edit && arrayElements && (
					<div
						className="c-5 dropdown"
						onMouseEnter={() => {
							setOpenDropdown(true);
						}}
						onMouseLeave={() => {
							setOpenDropdown(false);
						}}
					>
						<button
							className="h4-s h3 button-without-styles"
							onClick={() => setOpenDropdown(!openDropdown)}
						>
							{value} ⤵
						</button>
						<div className={`dropdown-content ${openDropdown && 'block'}`}>
							{arrayElements.map((el) => {
								return (
									<button
										tabIndex={0}
										className="cursor-pointer"
										key={el.name}
										onClick={
											onChange
												? () => onChange({ name: el.name, id: el.id })
												: undefined
										}
									>
										{el.name}
									</button>
								);
							})}
						</div>
					</div>
				)}
			</span>
		);
	};
	const saveTitle = async () => {
		const currentDegrees = user?.supervisor?.supervisorAcademicDegrees ?? [];
		if (
			tempTitle &&
			!(
				currentDegrees.length === 1 &&
				currentDegrees[0].academicDegree?.academicDegreeId === tempTitle.id
			)
		) {
			setSubmitting(true);
			const degreeIDsToToggle = currentDegrees
				.map((deg) => deg.academicDegree?.academicDegreeId)
				.filter(notEmpty);
			// Add current degree to the array if it isn't already there and remove it if it exists (toggle)
			const pos = degreeIDsToToggle.findIndex((id) => id === tempTitle.id);
			if (pos >= 0) {
				degreeIDsToToggle.splice(pos, 1);
			} else {
				degreeIDsToToggle.push(tempTitle.id);
			}
			const degreeChangePromises = degreeIDsToToggle.map((id) =>
				setSupervisorTitle(id).catch(networkErrorHandler)
			);
			await Promise.all(degreeChangePromises);
			setDisplayedTitle(tempTitle);
			toast.success(t('title_changed_successfully'));
			// Refresh profile. Otherwise subsequent changes may cause problems because of the way the title is changed
			await getUserProfile()
				.then((response) => {
					if (response?.userProfile) {
						setUser(response.userProfile);
					}
				})
				.catch(networkErrorHandler);
			setSubmitting(false);
		}
		setEditMode(false);
	};

	const changeEmailPreferences = async (e: React.ChangeEvent<HTMLInputElement>) => {
		try {
			setDisableOptOutBtn(true);
			const emailPrefUpdate = await saveEmailPreferences(
				e.target.checked ? EmailOptOutType.ALL : EmailOptOutType.NONE
			);
			const responseOption = emailPrefUpdate.userPreferencesModel?.emailOptOutType;
			if (typeof responseOption === 'number') {
				setEmailStatus(responseOption);
				toast.success(
					t(
						responseOption === EmailOptOutType.NONE
							? 'opt-out_opted-in'
							: 'opt-out_success'
					)
				);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			networkErrorHandler(e);
		} finally {
			setDisableOptOutBtn(false);
		}
	};

	return (
		<div className="content-wrapper">
			<div className="scroll-content main-shadow bg-white">
				<div className="content-header bg-white flex flex-middle flex-center-s pl-8 pl-0-s">
					<h1 className="h2-s text-center">{t('profile')}</h1>
				</div>
				<div className="c-12 flex flex-center">
					<div className="c-11 mt-3 flex flex-center flex-wrap text-wrap">
						{!user && <Loading />}
						{user && (
							<>
								<div className="c-6 c-12-m c-12-s">
									{tab(`${t('name')}:`, user?.firstName ?? t('none'))}
									{tab(`${t('surname')}:`, user?.lastName ?? t('none'))}
									{!isSupervisor && tab(`${t('programme')}:`, userMajor)}
									{isSupervisor &&
										tab(
											`${t('academic_degree')}:`,
											displayedTitle?.name ?? t('none'),
											true,
											possibleAcademicDegrees
												?.map(({ academicDegreeId, name }) => ({
													name,
													id: academicDegreeId,
												}))
												.filter(
													(v): v is { name: string; id: number } =>
														typeof v.name === 'string' &&
														typeof v.id === 'number'
												) ?? [],
											tempTitle?.name ?? t('select_title_degree'),
											(newTitle) => setTempTitle(newTitle)
										)}
								</div>
								<div className="c-6 c-12-m c-12-s">
									{tab(`${t('email')}:`, user?.emailAddress ?? t('none'))}
									{!isSupervisor &&
										tab(
											`${t('approved_paper')}:`,
											<>
												{
													user?.studentAcceptedPaper?.paperDetails
														?.polishTitle
												}
												<br />
												{
													user?.studentAcceptedPaper?.paperDetails
														?.englishTitle
												}
											</>
										)}
									{!isSupervisor &&
										tab(
											`${t('supervisor')}:`,
											user?.studentAcceptedPaper?.supervisor
												? renderSupervisor(
														user.studentAcceptedPaper.supervisor
												  )
												: t('none')
										)}
									{isSupervisor &&
										tab(
											`${t('department')}:`,
											user.supervisor?.department?.name ?? t('none')
										)}
								</div>
							</>
						)}
					</div>
				</div>
				{user && (
					<div className="c-12 flex flex-center">
						<div className="c-11">
							{isSupervisor && (
								<div className="mt-1 flex flex-wrap">
									<label className="p flex flex-middle">
										<input
											className="checkbox-1"
											type="checkbox"
											checked={emailStatus === EmailOptOutType.ALL}
											disabled={disableOptOutBtn}
											onChange={changeEmailPreferences}
										/>
										<p className="ml-1">{t('opt-out_emails')}</p>
									</label>
								</div>
							)}
							<div className="mb-1">
								{isSupervisor && (
									<button
										className="button-1 mt-2 mr-1"
										onClick={() => {
											setEditMode((mode) => !mode);
											setTempTitle(null);
										}}
										disabled={submitting}
									>
										{!editMode ? t('edit') : t('cancel')}
									</button>
								)}
								{isSupervisor && editMode && (
									<button
										className="button-1 mt-2"
										onClick={saveTitle}
										disabled={submitting}
									>
										{t('save')}
									</button>
								)}
								{savedUser.hasStudentPaperAssigned && user.studentAcceptedPaper && (
									<button
										className="button-1 mt-2"
										onClick={getThesisAcceptationDoc}
										disabled={generatingDoc}
									>
										{t('generate_acceptation')}
									</button>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
