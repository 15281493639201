import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toast';
import { UserContext } from '../../../services/contexts';
import { ErrorCode, MajorDto } from '../../../services/models';
import { loginCAS, ServerError, useErrorHandler } from '../../../services/network';
import { saveUserAndLogin } from '../../../services/userManagement';
import User from '../../../utils/localStorageClasses/User';
import Loading from '../../Loading/Loading';

export default function CAS() {
	// const [userData, setUserData] = useState<string | null>(null);
	const { setUser } = useContext(UserContext);
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const ticket = params.get('ticket');

	useEffect(() => {
		ticket &&
			// fetch(
			// 	`https://login.polsl.pl/p3/serviceValidate?service=https%3A%2F%2Fcyt.aei.polsl.pl%2Fcas&ticket=${encodeURIComponent(
			// 		ticket
			// 	)}&format=JSON`
			// )
			// 	.then((r) => r.json())
			// 	.then((j) => {
			// 		console.log(j);
			// 		setUserData(JSON.stringify(j));
			// 	})
			// 	.catch((e) => console.error(e));
			loginCAS(ticket)
				.then((response) => {
					if (response) {
						if (
							response.user?.supervisor === null &&
							(response.user.students?.length ?? 0) > 1
						) {
							return navigate('/chooseMajor', {
								state: { signInResponse: response },
							});
						}
						const { user, hasStudentPaperAssigned } = response;
						if (user) {
							const student =
								response.user?.students?.length === 1
									? response.user.students[0]
									: undefined;
							if (
								student &&
								(typeof student.major?.majorId !== 'number' ||
									typeof student.major.name !== 'string')
							) {
								throw new Error(t('error_could_not_log_in'));
							}
							saveUserAndLogin(
								setUser,
								navigate,
								new User(
									user,
									hasStudentPaperAssigned,
									student?.studentId,
									student?.major as Required<MajorDto>
								)
							);
						} else {
							throw new Error(t('error_could_not_log_in'));
						}
					}
				})
				.catch((e) => {
					networkErrorHandler(e);
					navigate('/auth');
				})
				.catch((error) => {
					if (
						error instanceof ServerError &&
						error.errorCode === ErrorCode.INVALID_CREDENTIALS
					) {
						toast.error(t('CAS_invalid_token'));
					} else {
						toast.error(error instanceof Error ? error.message : t('unknown_error'));
					}
					navigate('/auth');
				});
	}, [ticket, setUser, networkErrorHandler, navigate, t]);

	if (!ticket) {
		toast.error('Invalid ticket');
		return <Navigate to="/login" />;
	}

	// return userData ? <p>{userData}</p> : <p>Loading user data</p>;
	return <Loading />;
}
