import { useUser } from '../../services/hooks';
import EditPaper from './EditPaper/EditPaper';

export default function CreatePaper({ showHeader }: CreatePaperProps) {
	const user = useUser();
	if (!user) {
		return <></>;
	}
	return (
		<div className="content-wrapper">
			<EditPaper
				create={true}
				user={user}
				isStudent={user.studentId !== null}
				paper={null}
				paperId={null}
				showHeader={showHeader}
			/>
		</div>
	);
}

export interface CreatePaperProps {
	showHeader?: boolean;
}
