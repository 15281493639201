import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toast';
import { useErrorHandler } from '../../services/network';
import { GetLastAnnouncement } from '../../services/network/AdminPanel/announcement';
import './Message.scss';

export default function Message() {
	const networkErrorHandler = useErrorHandler();
	const [polishMessage, setPolishMessage] = useState<string | null>(null);
	const [englishMessage, setEnglishMessage] = useState<string | null>(null);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		GetLastAnnouncement()
			.then((response) => {
				setPolishMessage(response.lastAnnouncement?.polishText ?? null);
				setEnglishMessage(response.lastAnnouncement?.englishText ?? null);
			})
			.catch(networkErrorHandler)
			.catch(() => {
				toast.error(t('error_occurred'));
			});
	}, [networkErrorHandler, t]);

	const notEmptyString = (original: string | null): original is string =>
		!!original?.replace(/<[^>]+>/g, '').trim().length;

	return (
		<>
			{notEmptyString(polishMessage) && notEmptyString(englishMessage) ? (
				<div className="content-wrapper" style={{ backgroundColor: 'white' }}>
					<div
						className="scroll-content main-shadow"
						style={{ backgroundColor: 'white' }}
					>
						<h1 className="c-12 text-center mt-1 mb-1" style={{ color: 'black' }}>
							{t('news')}:
						</h1>
						<div className="m-2 wrapper col-black">
							{i18n.resolvedLanguage.toLowerCase().startsWith('pl') ? (
								<ReactQuill
									readOnly={true}
									theme={'bubble'}
									value={polishMessage}
								/>
							) : (
								<ReactQuill
									readOnly={true}
									theme={'bubble'}
									value={englishMessage}
								/>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-center pt-10 full-height bg-gray-2">
					<h3 className="text-center col-black">{t('no_news')}</h3>
				</div>
			)}
		</>
	);
}
