import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toast';
import config from '../../../config';
import { useUser } from '../../../services/hooks';
import { PaperDto, PaperStatus, PermissionType } from '../../../services/models';
import { getPaperDetails } from '../../../services/network';
import { useErrorHandler } from '../../../services/network/errorHandler';
import User from '../../../utils/localStorageClasses/User';
import { hasPermissions } from '../../../utils/Permissions';
import { assertUnreachable } from '../../../utils/typeGuards';
import GoBackButton from '../../GoBackButton/GoBackButton';
import Loading from '../../Loading/Loading';
import Suspended from '../../Suspended/Suspended';
import { PaperDetailsProps } from './PaperDetails';
import PaperDetailsAssigned from './PaperDetailsAssigned';
import PaperDetailsCreated from './PaperDetailsCreated';
import PaperDetailsCreatedStudent from './PaperDetailsCreatedStudent';
import PaperDetailsReview from './PaperDetailsReview';
import PaperDetailsStudent from './PaperDetailsStudent';
import PaperDetailsSupervisor from './PaperDetailsSupervisor';
const PaperDetailsAdmin = React.lazy(() => import('./PaperDetailsAdmin'));

export enum PaperDetailsType {
	STUDENT = 'student',
	STUDENT_CREATED = 'student_created',
	SUPERVISOR = 'supervisor',
	ASSIGNED = 'assigned',
	CREATED = 'created',
	REVIEW = 'review',
	ADMIN = 'admin',
}

export default function PaperDetailsMain(): JSX.Element {
	const { paperId: paperIdStr } = useParams();
	const [paper, setPaper] = useState<PaperDto | undefined>();
	const [cacheState, setCacheState] = useState(0);
	const user = useUser();
	const [searchParams] = useSearchParams();

	const navigate = useNavigate();
	const networkErrorHandler = useErrorHandler();
	if (paperIdStr === undefined) navigate('/');
	const paperId = Number.parseInt(paperIdStr ?? '-1');
	const setCacheDelayed = (funcOrState: React.SetStateAction<number>) =>
		setTimeout(
			() =>
				setCacheState((st) =>
					typeof funcOrState === 'function' ? funcOrState(st) : funcOrState
				),
			config.cacheRefreshDelay
		);

	useEffect(() => {
		const paperDetails = () => {
			getPaperDetails(paperId)
				.then((response) => {
					if (response.paper) setPaper(response.paper);
				})
				.catch(networkErrorHandler)
				.catch((e) => {
					if (e instanceof Error) {
						toast.error(e.message);
					} else if (typeof e === 'string') {
						toast.error(e);
					} else {
						console.error(e);
					}
				});
		};
		paperDetails();
	}, [paperId, cacheState, networkErrorHandler]);

	if (!user) return <></>;
	if (!paper)
		return (
			<div className="c-12 flex flex-center">
				<Loading disableStyles={true} />
			</div>
		);
	const managePapersDepartments = hasPermissions(
		user.userPermissions,
		PermissionType.MANAGE_PAPERS
	);

	let detailsType: PaperDetailsType | undefined;
	if (typeof user.studentId === 'number') {
		if (paper.studentId === user.studentId) {
			// created by student
			detailsType = PaperDetailsType.STUDENT_CREATED;
		} else {
			// other paper opened by student
			detailsType = PaperDetailsType.STUDENT;
		}
	} else if (searchParams.get('admin') && managePapersDepartments) {
		detailsType = PaperDetailsType.ADMIN;
	} else if (
		paper.status === PaperStatus.WAITING_FOR_APPROVAL &&
		paper.isAssignedToSupervisor &&
		user.userPermissions.some(
			(perm) =>
				perm.permissionType === PermissionType.REVIEW_PAPERS &&
				(perm.departmentsIds?.some(
					(dept) => dept === paper.supervisor?.department?.departmentId
				) ||
					perm.isGeneral)
		) &&
		(searchParams.get('review') === 'true' ||
			user.supervisorId === null ||
			paper.supervisorId !== user.supervisorId)
	) {
		// review
		detailsType = PaperDetailsType.REVIEW;
	} else if (typeof user.supervisorId === 'number') {
		if (paper.supervisorId === user.supervisorId) {
			if (paper.studentId === null) {
				// created by supervisor (or created by student for multiple students and assigned to this supervisor,
				// in which case it should be treated as created by supervisor)
				detailsType = PaperDetailsType.CREATED;
			} else if (paper.status ?? -1 > PaperStatus.CREATED) {
				// assigned to supervisor
				detailsType = PaperDetailsType.ASSIGNED;
			}
		} else if (paper.supervisorId === null) {
			detailsType = PaperDetailsType.SUPERVISOR;
		}
	} else if (managePapersDepartments) {
		detailsType = PaperDetailsType.ADMIN;
	}

	const childProps: CommonSpecialisedPaperDetailsProps = {
		paper,
		paperId,
		user,
	};

	const paperDetailsSelect = () => {
		switch (detailsType) {
			case PaperDetailsType.STUDENT:
				return <PaperDetailsStudent {...childProps} />;

			case PaperDetailsType.STUDENT_CREATED:
				return (
					<PaperDetailsCreatedStudent {...childProps} setCacheState={setCacheDelayed} />
				);

			case PaperDetailsType.SUPERVISOR:
				return <PaperDetailsSupervisor {...childProps} />;

			case PaperDetailsType.REVIEW:
				return <PaperDetailsReview {...childProps} />;

			case PaperDetailsType.CREATED:
				return <PaperDetailsCreated {...childProps} setCacheState={setCacheDelayed} />;

			case PaperDetailsType.ASSIGNED:
				return <PaperDetailsAssigned {...childProps} />;

			case PaperDetailsType.ADMIN:
				return (
					<Suspended>
						<PaperDetailsAdmin {...childProps} setCacheState={setCacheDelayed} />
					</Suspended>
				);

			case undefined:
				toast.warn('Unknown state');
				return <Navigate to="/" />;

			default:
				assertUnreachable(detailsType);
		}
	};

	return (
		<>
			<div className="content-wrapper">
				<GoBackButton customHistory />
				{paperDetailsSelect()}
			</div>
		</>
	);
}

export interface CommonSpecialisedPaperDetailsProps extends PaperDetailsProps {
	paperId: number;
	user: User;
}

export interface CommonSpecialisedPaperDetailsPropsWithCache
	extends CommonSpecialisedPaperDetailsProps {
	setCacheState: React.Dispatch<React.SetStateAction<number>>;
}
