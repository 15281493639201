import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import * as yup from 'yup';
import config from '../../../config';
import { UserContext } from '../../../services/contexts';
import {
	ErrorCode,
	PaperDto,
	PaperFileDto,
	StudentPaperBody,
	StudentPaperBody1,
	SupervisorPaperBody,
	SupervisorPaperBody1,
} from '../../../services/models';
import { ServerError, createPaper, updatePaper, useErrorHandler } from '../../../services/network';
import { updateUser } from '../../../services/userManagement';
import User from '../../../utils/localStorageClasses/User';
import { getFileName } from '../../../utils/nameFormatting';
import { handleServerValidation } from '../../../utils/serverValidationToFormik';
import { notEmpty } from '../../../utils/typeGuards';
import { FormikFileInput } from '../../FileInput/FormikFileInput';
import MajorInput from '../../MajorInput/MajorInput';
import SupervisorInput from '../../SupervisorInput/SupervisorInput';
import TagInput from '../../Tag/TagInput';

function getSchema<C extends boolean, S extends boolean>(
	isStudent: S,
	create: C,
	t: TFunction<'translation', undefined>,
	originalFiles?: PaperDto['paperFiles'],
	originalMajorsIds?: PaperDto['majorPapers']
) {
	/**
	 * Base verification schema missing majorsIds
	 * and in case of student the optional supervisorId
	 */
	const baseSchema = {
		polishTitle: yup
			.string()
			.trim()
			.max(config.maxTitleLength, t('max_title_length_is', { count: config.maxTitleLength }))
			.required(t('required')),
		englishTitle: yup
			.string()
			.trim()
			.max(config.maxTitleLength, t('max_title_length_is', { count: config.maxTitleLength }))
			.required(t('required')),
		description: yup
			.string()
			.max(
				config.maxDescriptionLength,
				t('max_description_length_is', { count: config.maxDescriptionLength })
			)
			.required(t('required')),
		maxStudents: yup
			.number()
			.default(config.defaultMaxStudents)
			.integer(t('num_of_students_must_be_int'))
			.min(1, t('at_least_one_student_is_required'))
			.max(
				config.maxLimitStudents,
				t('max_n_students_allowed', { count: config.maxLimitStudents })
			),
		tagsIds: yup.array(
			yup.string().matches(
				/^[0-9A-Fa-f]{24}$/,
				// This should be impossible (debugging only) and does not have to be translated
				'Tags must be passed as a hex-encoded 12-byte value (their ids)'
			)
		),
	};

	const yupFile = yup
		.mixed<File>()
		// I don't think it can be anything else, so let's leave it not translated
		.test('isAFile', 'the input must be a file', (value) => value instanceof File)
		.test(
			'fileSize',
			t('file_too_large'),
			(file?: File) => (file?.size ?? Infinity) < config.maxFileSizeInBytes
		)
		.required();

	const createSchemaExtension = {
		files: yup
			.array(yupFile)
			.max(
				config.maxFilesCount,
				t('too_many_files_limit', { maxFilesCount: config.maxFilesCount })
			),
		majorsIds: yup
			.array(yup.number().integer().positive())
			.min(1, t('at_least_one_major_required'))
			.required(),
	};
	const schemaExtensionSupervisorId = {
		supervisorId: yup.number().integer().positive().optional(),
	};
	const addKeepSchemaExtension = {
		filesIdsToKeep: yup.array(
			yup
				.number()
				.oneOf(
					Array.isArray(originalFiles)
						? originalFiles.map((file) => file.paperFileId)
						: []
				)
		),
		filesToAdd: yup.array(yupFile).when('filesIdsToKeep', ([filesIdsToKeep], schema) =>
			schema.test({
				test: (filesToAdd) =>
					(filesToAdd?.length ?? 0) + filesIdsToKeep.length <= config.maxFilesCount,
				message: t('too_many_files_limit', { maxFilesCount: config.maxFilesCount }),
			})
		),
		majorsIdsToKeep: yup
			.array(
				yup
					.number()
					.oneOf(
						Array.isArray(originalMajorsIds)
							? originalMajorsIds.map((majorPapers) => majorPapers.majorId)
							: []
					)
			)
			.required()
			.when('majorsIdsToAdd', ([majorsIdsToAdd], schema) =>
				schema.test({
					test: (majorsIdsToKeep) => majorsIdsToAdd.length + majorsIdsToKeep.length > 0,
					message: t('at_least_one_major_required'),
				})
			),
		majorsIdsToAdd: yup.array(yup.number()),
	};
	const createUpdateExtension = create ? createSchemaExtension : addKeepSchemaExtension;
	const schemaExtension = isStudent
		? Object.assign(createUpdateExtension, schemaExtensionSupervisorId)
		: createUpdateExtension;
	return yup.object().shape(Object.assign(schemaExtension, baseSchema));
}

type PaperEditValuesType<S extends boolean> = S extends true
	? StudentPaperBody
	: SupervisorPaperBody;
type PaperCreateValuesType<S extends boolean> = S extends true
	? StudentPaperBody1
	: SupervisorPaperBody1;
type PaperInitialValuesType<C extends boolean, S extends boolean> = C extends true
	? PaperCreateValuesType<S>
	: PaperEditValuesType<S>;

function isStudentFormikProps<C extends boolean, S extends boolean>(
	isStudent: S,
	_props: FormikProps<PaperInitialValuesType<C, S>>
): _props is FormikProps<PaperInitialValuesType<C, true>> {
	return isStudent;
}

function isUpdateFormikProps<C extends boolean, S extends boolean>(
	create: C,
	_props: FormikProps<PaperInitialValuesType<C, S>>
): _props is FormikProps<PaperInitialValuesType<false, S>> {
	return !create;
}

function paperToInitialValues<S extends boolean>(
	paper: PaperDto,
	isStudent: S
): PaperEditValuesType<S> {
	const { paperDetails } = paper;
	if (paperDetails === undefined) throw new Error('Details are undefined');
	const values: SupervisorPaperBody = {
		isReserved: paper.isReserved,
		paperId: paper.paperId,
		polishTitle: paperDetails.polishTitle ?? '',
		englishTitle: paperDetails.englishTitle ?? '',
		description: paperDetails.description ?? '',
		maxStudents: paperDetails.maxStudents ?? 1,
		filesToAdd: [] as File[],
		filesIdsToKeep: (paper.paperFiles ?? []).map((file) => file.paperFileId).filter(notEmpty),
		majorsIdsToAdd: [] as number[],
		majorsIdsToKeep: (paper.majorPapers ?? [])
			.map((majorPaper) => majorPaper.majorId)
			.filter(notEmpty),
		tagsIds: (paper.tags ?? []).map((tag) => tag.id).filter(notEmpty),
	};
	if (isStudent) return Object.assign(values, { supervisorId: paper.supervisorId ?? undefined });
	return values;
}

function getInitialValues<C extends boolean, S extends boolean>(
	create: C,
	paper: C extends true ? undefined | null : PaperDto,
	isStudent: S,
	user?: User
): PaperInitialValuesType<C, S> {
	const majorId = isStudent ? user?.major?.majorId : null;
	const majorsIds = majorId ? [majorId] : [];
	if (create !== true) return paperToInitialValues<S>(paper as PaperDto, isStudent);
	const values: SupervisorPaperBody1 = {
		isReserved: false,
		polishTitle: '',
		englishTitle: '',
		description: '',
		maxStudents: 1,
		files: [] as File[],
		tagsIds: [] as string[],
		majorsIds: majorsIds,
	};
	if (!isStudent) values;
	return Object.assign(values, { supervisorId: undefined });
}

export default function EditPaper<C extends boolean, S extends boolean>(
	props: EditPaperProps<C, S>
): JSX.Element {
	const { user, isStudent, paper, create, paperId, showHeader = true } = props;

	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const { state } = useLocation();
	const networkErrorHandler = useErrorHandler();
	const { t } = useTranslation();

	const submitPaper = create ? createPaper : updatePaper;
	const validationSchema = getSchema(isStudent, create, t, paper?.paperFiles, paper?.majorPapers);
	const initialValues: PaperInitialValuesType<C, S> = getInitialValues(
		create,
		paper,
		isStudent,
		user
	);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, actions) => {
				const fd = new FormData();
				for (const key in values) {
					if (Object.prototype.hasOwnProperty.call(values, key)) {
						const val = values[key];
						// Files are handled as array
						if (Array.isArray(val)) {
							for (const elem of val) {
								fd.append(key, elem);
							}
						} else if (
							typeof val === 'number' ||
							typeof val === 'string' ||
							typeof val === 'boolean'
						) {
							fd.set(key, String(val));
						} else if (typeof val !== 'undefined') {
							throw new Error('Oops, I forgot about something');
						}
					}
				}
				if (!(isStudent && create) || confirm(t('do_you_want_to_create_paper'))) {
					submitPaper(isStudent, fd, paperId)
						.then((paperId) => {
							actions.setSubmitting(false);
							const backStateExists =
								typeof state === 'object' &&
								state !== null &&
								'backNum' in state &&
								typeof (state as Record<string, unknown>)['backNum'] === 'number';
							if (isStudent) {
								user.hasStudentPaperAssigned = true;
								updateUser(setUser, user);
							}
							const navigateOptions: NavigateOptions = create
								? {}
								: {
										replace: true,
										state: {
											backNum: backStateExists
												? (state as { backNum: number }).backNum + 1
												: 1,
										},
								  };
							navigate(`/paper/${paperId}`, navigateOptions);
						})
						.catch(networkErrorHandler)
						.catch((error) => {
							if (
								error instanceof ServerError &&
								error.errorCode === ErrorCode.VALIDATION_FAILED
							) {
								handleServerValidation(
									Object.keys(values),
									actions,
									error.validations
								);
							} else {
								throw error;
							}
						})
						.catch((err) => {
							console.error(err);
							toast.error(t('error_occurred'));
						})
						.finally(() => actions.setSubmitting(false));
				} else {
					actions.setSubmitting(false);
				}
			}}
		>
			{(props) => {
				const isStudentCached = isStudentFormikProps(isStudent, props);
				const isUpdateCached = isUpdateFormikProps(create, props);

				// Remove file function - click handler for "DELETE" button
				const removeFileId = (file?: PaperFileDto) => {
					const fileId = file?.paperFileId;
					if (isUpdateCached && typeof fileId === 'number') {
						// For some reason type guard doesn't work, this is a workaround
						const formikProps = props as FormikProps<PaperEditValuesType<S>>;
						const newFiles = [...(formikProps.values.filesIdsToKeep ?? [])];
						newFiles.splice(newFiles.indexOf(fileId), 1);
						formikProps.setFieldValue('filesIdsToKeep', newFiles);
					}
				};
				return (
					<div
						className={`scroll-content bg-white ${create ? '' : 'scroll-content-back'}`}
					>
						{showHeader && (
							<div className="content-header bg-white flex flex-middle flex-center-s pl-8 pl-0-s">
								<h1 className="h2-s text-center">
									{isUpdateCached ? t('edit_topic') : t('create_topic')}
								</h1>
							</div>
						)}
						<Form autoComplete="off" className="c-12">
							<div className="c-12 m-1 flex flex-wrap">
								<div className="c-6 c-12-m">
									<input
										placeholder={t('title')}
										className="input-2 c-6 c-10-m mt-1"
										type="text"
										name="polishTitle"
										id="polishTitle"
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										value={props.values.polishTitle}
									/>
									<div className="ml-1 col-danger error-message">
										<ErrorMessage name="polishTitle" />
									</div>
								</div>

								<div className="c-6 c-12-m">
									<input
										placeholder={t('title_en')}
										className="input-2 c-6 c-10-m mt-1"
										type="text"
										name="englishTitle"
										id="englishTitle"
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										value={props.values.englishTitle}
									/>
									<div className="ml-1 col-danger error-message">
										<ErrorMessage name="englishTitle" />
									</div>
								</div>

								{isStudentCached && (
									<div className="c-6 c-10-m mt-1">
										<div className="c-6 c-12-m">
											<label>
												<SupervisorInput name="supervisorId" />
											</label>
											<div className="col-danger error-message">
												<ErrorMessage name="supervisorId" />
											</div>
										</div>
									</div>
								)}
								<div className="c-6 c-10-m mt-1">
									<div className="c-6 c-12-m">
										<TagInput name="tagsIds" creatable={!isStudent} />
									</div>
								</div>
								{!isStudent && (
									<>
										<div className="c-6 c-10-m mt-1 mt-2-m">
											<div className="c-6 c-12-m">
												<MajorInput
													name={create ? 'majorsIds' : 'majorsIdsToKeep'}
													lockedMajorId={
														(isStudent &&
															user.major &&
															user.major.majorId) ??
														undefined
													}
													addedMajorsName={
														create ? undefined : 'majorsIdsToAdd'
													}
												/>
												<div className="ml-1 col-danger error-message">
													<ErrorMessage
														name={
															create ? 'majorsIds' : 'majorsIdsToKeep'
														}
													/>
												</div>
											</div>
										</div>
										<div className="c-6 c-10-m mt-1 flex flex-column flex-center">
											<label htmlFor="maxStudents" className="col-black">
												{t('students_number')}
											</label>
											<input
												className="c-6 c-12-m input-2"
												type="number"
												name="maxStudents"
												id="maxStudents"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.maxStudents}
												min="1"
												max="3"
											/>
											<div className="col-danger error-message">
												<ErrorMessage name="maxStudents" />
											</div>
										</div>
										<div className="c-6 c-10-m mt-1 flex flex-wrap">
											<label className="p flex flex-middle">
												<Field
													type="checkbox"
													className="checkbox-1"
													name="isReserved"
												/>
												<p className="ml-1">{t('reserved_topic')}</p>
											</label>
										</div>
									</>
								)}

								{isUpdateCached && (
									<section className="mt-1">
										<h4>{t('uploaded_files')}</h4>
										<ul>
											{(
												props as FormikProps<PaperEditValuesType<S>>
											).values.filesIdsToKeep?.map((fileId) => {
												const file = paper?.paperFiles?.find(
													(fileDto) => fileDto.paperFileId === fileId
												);
												return (
													<li key={fileId}>
														{getFileName(file?.filePath ?? '')}
														<button
															type="button"
															className="delete-button ml-1 button-2"
															onClick={() => removeFileId(file)}
														>
															{t('remove')}
														</button>
													</li>
												);
											})}
										</ul>
									</section>
								)}
							</div>
							<div className="c-12 flex flex-wrap-m flex-center mt-2 ml-1 mr-1 ml-0-m mr-0-m mt-3-m">
								<div className="c-6 c-12-m c-12-s flex-s flex-column-s flex-middle-s">
									{/* <label htmlFor="Description" className="h4">
											{t('description')}
										</label> */}
									<textarea
										placeholder={t('description')}
										className="c-5 c-12-m ml-1-m ml-0-s textarea bg-white-3"
										style={{
											maxWidth: '80%',
											minWidth: '80%',
											minHeight: '35rem',
										}}
										name="description"
										id="description"
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										value={props.values.description}
									/>
									<div className="c-12-m ml-2-m ml-5-s ml-1 col-danger error-message">
										<ErrorMessage name="description" />
									</div>
								</div>
								<div className="c-6 c-12-m flex flex-column flex-center flex-middle">
									<Field name={create ? 'files' : 'filesToAdd'}>
										{({ field, form }: FieldProps<File[]>) => (
											<FormikFileInput
												maxFiles={config.maxFilesCount}
												maxSize={config.maxFileSizeInBytes}
												hideRemove={false}
												field={field}
												form={form}
											/>
										)}
									</Field>
									<div className="c-12 flex flex-column flex-center flex-middle mt-3">
										{isStudent && create && (
											<p className="mb-1 col-danger text-center">
												{t('create_paper_warning')}
											</p>
										)}
										<button
											className="button-1 c-2 c-4-m c-5-s mb-2-m"
											type="submit"
											disabled={!props.isValid || props.isSubmitting}
										>
											{isUpdateCached ? t('save') : t('create')}
										</button>
									</div>
								</div>
							</div>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
}

export interface EditPaperProps<C extends boolean, S extends boolean> {
	user: User;
	isStudent: S;
	create: C;
	paper: C extends true ? undefined | null : PaperDto;
	paperId: C extends true ? undefined | null : number;
	showHeader?: boolean;
}
