import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import { PublicFilesDto } from '../../services/models';
import { getPublicFileList, useErrorHandler } from '../../services/network';
import { getFileName } from '../../utils/nameFormatting';
import './DownloadFile.scss';

export default function DownloadFile() {
	const { t } = useTranslation();
	const networkErrorHandler = useErrorHandler();
	const [files, setFiles] = useState<PublicFilesDto>();

	useEffect(() => {
		getPublicFileList()
			.then((response) => {
				setFiles(response.publicFiles);
			})
			.catch(networkErrorHandler)
			.catch((e) => {
				if (e instanceof Error) {
					toast.error(e.message);
				} else if (typeof e === 'string') {
					toast.error(e);
				}
			});
	}, [networkErrorHandler]);

	return (
		<div className="content-wrapper">
			<div
				className="small-scroll-content main-shadow bg-white"
				style={{ minHeight: '45rem' }}
			>
				<div className="content-header bg-white flex flex-middle flex-center-s pl-8 pl-0-s">
					<h1 className="h2-s text-center">{t('files_to_download')}</h1>
				</div>
				{files?.publicFiles?.length !== 0 ? (
					<div className="c-10 mb-5 flex flex-column flex-center mt-3 ml-8 ml-3-m">
						{files?.publicFiles?.map((el) => {
							return (
								<div className="mt-1" key={el.publicFileId}>
									<a
										className="link"
										download=""
										href={'/' + el?.filePath}
										rel="noreferrer"
										target="_blank"
									>
										{getFileName(el.filePath ?? '')}
									</a>
								</div>
							);
						})}
					</div>
				) : (
					<p className="text-center mt-3">{t('currently_no_files_to_download')}</p>
				)}
			</div>
		</div>
	);
}
