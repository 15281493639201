import { StudentPaperDto } from '../../../services/models';

export interface SingleStudentBasicProps {
	student: StudentPaperDto;
	topic?: undefined | string;
	showEmail?: undefined | false;
}

export interface SingleStudentEmailProps {
	student: StudentPaperDto;
	topic: string;
	showEmail: true;
}

export type SingleStudentProps = SingleStudentBasicProps | SingleStudentEmailProps;
export function SingleStudent(props: SingleStudentProps) {
	const { student, showEmail, topic } = props;
	return (
		<>
			{student.firstName}&nbsp;{student.lastName}
			{showEmail && (
				<>
					{' - '}
					<a
						href={`mailto:"${encodeURIComponent(
							`${student.firstName} ${student.lastName}`
						)}"<${student.emailAddress}>?subject=${encodeURIComponent(topic)}`}
					>
						{student.emailAddress}
					</a>
				</>
			)}
		</>
	);
}
