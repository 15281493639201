import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from '../../../services/hooks';
import { PermissionType } from '../../../services/models';
import DepartmentSelect from '../../DepartmentSelect/DepartmentSelect';
import UnifiedBrowser, { BrowserType } from './UnifiedBrowser';

export default function UnifiedBrowserRoutes() {
	const user = useUser();
	if (!user) return <></>;
	/**
	 * Default browser type for each kind of user:
	 * - student => student paper browser
	 * - someone with permission to review papers => review browser
	 * - supervisor => supervisor paper browser
	 */
	const userBrowserType =
		typeof user.studentId === 'number'
			? BrowserType.STUDENT
			: user.userPermissions.some((el) => el.permissionType === PermissionType.REVIEW_PAPERS)
			? BrowserType.REVIEW
			: BrowserType.SUPERVISOR;
	return (
		<Routes>
			<Route path="/review" element={<DepartmentSelect />} />
			<Route
				path="/review/:departmentId"
				element={<UnifiedBrowser browserType={BrowserType.REVIEW} />}
			/>
			<Route path="/student" element={<UnifiedBrowser browserType={BrowserType.STUDENT} />} />
			<Route
				path="/supervisor"
				element={<UnifiedBrowser browserType={BrowserType.SUPERVISOR} />}
			/>
			<Route
				path="/"
				element={
					userBrowserType === BrowserType.REVIEW ? (
						<Navigate to="review" replace />
					) : (
						<UnifiedBrowser browserType={userBrowserType} />
					)
				}
			/>
		</Routes>
	);
}
