import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import User from '../utils/localStorageClasses/User';
import { UserContext } from './contexts';

/**
 * Returns a user or redirects to '/' if not logged in.
 *
 * @example
 * const user = useUser();
 *
 * if (!user) return <></>;
 *
 * return your_JSX;
 *
 * @returns null if user is not logged in
 */
export function useUser(): User | undefined {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const { t } = useTranslation();
	useEffect(() => {
		if (!user) {
			navigate('/auth');
			toast.warn(t('please_log_in'));
		}
	});

	return user;
}

/**
 * Hook that executes a callback on clicks outside of the passed ref
 *
 * @see https://stackoverflow.com/a/42234988
 */
export function useOutsideListener<T extends HTMLElement | null>(
	ref: React.MutableRefObject<T>,
	handler: (e: MouseEvent) => void
) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			if (event.target && ref.current && !ref.current.contains(event.target as Element)) {
				handler(event);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, handler]);
}

/**
 * A hook which returns the previous `value` parameter.
 * The initial "previous" value is `undefined`.
 *
 * @param value current value
 * @returns previous value
 */
export function usePrevious<T>(value: T): T | undefined {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}
