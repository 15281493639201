import { deleteRequest, getRequest, postRequest, putRequest } from '..';
import {
	AddDepartmentCommand,
	AddDepartmentResponse,
	DeleteDepartmentResponse,
	GetDepartmentsResponse,
	UpdateDepartmentCommand,
	UpdateDepartmentResponse,
} from '../../models';

export async function getDepartmentList(): Promise<GetDepartmentsResponse> {
	const response = await getRequest<GetDepartmentsResponse>(`/management/department/list`);
	return response.data;
}

export async function addDepartment(
	name: string,
	shortName: string,
	departments: number[] | boolean
): Promise<AddDepartmentResponse> {
	const response = await postRequest<AddDepartmentResponse, AddDepartmentCommand>(
		`/management/department`,
		{
			name,
			shortName,
		},
		departments
	);
	return response.data;
}

export async function updateDepartment(
	departmentId: number,
	name: string,
	shortName: string,
	departments: number[] | boolean
): Promise<UpdateDepartmentResponse> {
	const response = await putRequest<UpdateDepartmentResponse, UpdateDepartmentCommand>(
		`/management/department`,
		{ departmentId, name, shortName },
		departments
	);
	return response.data;
}

export async function deleteDepartment(
	departmentId: number,
	departments: number[] | boolean
): Promise<DeleteDepartmentResponse> {
	const response = await deleteRequest<DeleteDepartmentResponse>(
		`/management/department`,
		{
			departmentId,
		},
		departments
	);
	return response.data;
}
