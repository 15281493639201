import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import { UserContext } from '../../../services/contexts';
import { PaperStatus } from '../../../services/models';
import { deleteStudentPaper, setPaperToWaiting, useErrorHandler } from '../../../services/network';
import { updateUser } from '../../../services/userManagement';
import PaperDetails from './PaperDetails';
import { CommonSpecialisedPaperDetailsPropsWithCache } from './PaperDetailsMain';

export default function PaperDetailsCreatedStudent(
	props: CommonSpecialisedPaperDetailsPropsWithCache
): JSX.Element {
	const { paper, paperId, setCacheState } = props;
	const { status } = paper;
	const networkErrorHandler = useErrorHandler();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { t } = useTranslation();
	const { user, setUser } = useContext(UserContext);

	const publishPaper = () => {
		setPaperToWaiting(paperId)
			.then(() => {
				toast.success(t('sent_for_review'));
				setCacheState((st) => st + 1);
			})
			.catch(networkErrorHandler);
	};

	const deletePaper = () => {
		if (confirm(t('do_you_want_to_delete_paper')))
			deleteStudentPaper(paperId)
				.then(() => {
					toast.success(t('deleted'));
					if (user) {
						user.hasStudentPaperAssigned = false;
						updateUser(setUser, user);
					}
					navigate('/papersManager');
				})
				.catch(networkErrorHandler);
	};

	const backStateExists =
		typeof state === 'object' &&
		state !== null &&
		'backNum' in state &&
		typeof (state as Record<string, unknown>)['backNum'] === 'number';

	return (
		<div className="scroll-content scroll-content-back bg-white">
			<PaperDetails paper={paper} />
			<div className="mt-3 ml-5 ml-3-m ml-1-s flex flex-center-s flex-wrap">
				{status === PaperStatus.CREATED && (
					<div className="c-12 flex flex-center-s">
						<button
							className="button-1 p-1"
							onClick={() => {
								publishPaper();
							}}
						>
							{t(
								typeof paper.supervisorId === 'number'
									? 'send_to_supervisor'
									: 'send_to_supervisors'
							)}
						</button>
					</div>
				)}
				{(status === PaperStatus.CREATED ||
					status === PaperStatus.WAITING_FOR_APPROVAL) && (
					<>
						<div className="c-12 flex flex-center-s">
							<button
								className="button-1 p-1 mt-1 mb-1"
								onClick={() => {
									navigate(`/editPaper/${paperId}`, {
										state: {
											backNum: backStateExists
												? (state as { backNum: number }).backNum
												: 1,
										},
									});
								}}
							>
								{t('edit_topic')}
							</button>
						</div>
						<div className="c-12 flex flex-center-s">
							<button className="button-3 p-1 mt-1 mb-1" onClick={deletePaper}>
								{t('delete_paper')}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
