import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import config from '../../../../../config';
import { useUser } from '../../../../../services/hooks';
import { ManagementPaperDto, PermissionType } from '../../../../../services/models';
import { renderSupervisor } from '../../../../../utils/nameFormatting';
import { hasPermissions } from '../../../../../utils/Permissions';
import Loading from '../../../../Loading/Loading';
import PaperDetailsAssignedStudents from '../../../../Papers/PaperDetails/PaperDetailsAssignedStudents';
import PaperStatusDisplay from '../../../../PaperStatusDisplay/PaperStatusDisplay';
import '../../../AdminPanel/AdminPanel.scss';
import PaperBrowserFilters from '../../../Components/BrowserFilters/PapersBrowserFilters';
import Table from '../../../Components/Table/Table';

export default function AdminPapersBrowser() {
	const [pageCount, setPageCount] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [papers, setPapers] = useState<ManagementPaperDto[] | null>();
	const pageSize = config.defaultPageSize;
	const [searchParams, setSearchParams] = useSearchParams();
	const user = useUser();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const allowedDepartmentsIds = hasPermissions(user?.userPermissions, [
		PermissionType.MANAGE_PAPERS,
	]);

	useEffect(() => {
		const pageNumberStr = searchParams.get('pageNumber');
		const pageNumber = pageNumberStr ? Number.parseInt(pageNumberStr) : 1;
		setPageNumber(pageNumber);
	}, [searchParams]);

	if (!user) return <></>;

	const onClick = (id: number) => {
		navigate(`/paper/${id}?admin=true`);
	};

	const returnPapers = () =>
		papers?.map((el) => {
			const acceptedStudentsMajors =
				(el.studentPapers
					?.filter((sp) => sp.isStudentAccepted && typeof sp.majorId === 'number')
					.map((sp) => sp.majorId) as number[]) ?? [];
			return {
				names: [
					el.paperDetails?.polishTitle,
					el.paperDetails?.englishTitle,
					el.supervisor && renderSupervisor(el.supervisor),
					<PaperStatusDisplay paper={el} key={el.paperId} />,
					el.majorPapers
						?.filter((mp) => acceptedStudentsMajors.includes(mp.majorId as number))
						.map((major) => major.majorName)
						.join(', '),
					<PaperDetailsAssignedStudents
						studentPapers={el.studentPapers ?? []}
						tableMode
						key={el.paperId}
					/>,
				],
				actionHandler: () => onClick(el.paperId ? el.paperId : 0),
			};
		}) ?? [];

	const changePage = ({ selected }: { selected: number }) => {
		const selectedPage = selected + 1;
		setSearchParams({
			...Object.fromEntries(searchParams.entries()),
			pageNumber: selectedPage.toString(),
		});
	};

	return (
		<div className="content-wrapper">
			<div id="turn" className="c-12 text-center mt-5">
				<h1>{t('please_rotate_your_device')}</h1>
			</div>
			<div className="scroll-content main-shadow bg-white" id="container">
				<div className="content-header bg-white flex flex-middle flex-center-s pl-8 pl-0-s">
					<h1 className="h2-s text-center">{t('list_of_all_topics')}</h1>
				</div>
				<div className="mt-1">
					<PaperBrowserFilters
						pageNumber={pageNumber}
						pageSize={pageSize}
						setPapers={setPapers}
						setPageCount={setPageCount}
						allowedDepartmentsIds={allowedDepartmentsIds}
					/>
				</div>
				{!papers && <Loading />}
				{papers && papers?.length !== 0 ? (
					<>
						<Table
							records={returnPapers()}
							header={[
								t('title'),
								t('title_en'),
								t('supervisor'),
								t('paper_status'),
								t('programme'),
								t('students'),
								'',
							]}
							className="admin-manager-table mt-2"
							buttonText={t('open')}
						/>
						<ReactPaginate
							previousLabel={t('pagination_previous')}
							nextLabel={t('pagination_next')}
							breakLabel={'...'}
							pageCount={pageCount}
							forcePage={pageNumber - 1}
							onPageChange={changePage}
							marginPagesDisplayed={1}
							pageRangeDisplayed={2}
							containerClassName={'paginationBttns'}
							previousLinkClassName={'previousBttn'}
							nextLinkClassName={'nextBttn'}
							disabledClassName={'PaginationDisabled'}
							activeClassName={'paginationActive'}
						/>
					</>
				) : (
					<div className="flex flex-center ">
						<h2 className="mt-5">{t('no_papers_to_display')}</h2>
					</div>
				)}
			</div>
		</div>
	);
}
